import axios from 'axios';
import { type FormInputInterface, type EditFormInputInterface } from '../modals';
import { ApiUrl } from '../utils/config';

const loginUser = (body: FormInputInterface): any => {
	return  axios.post(ApiUrl + 'auth/login', body);
};

const editUsersProfile = (body: EditFormInputInterface): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return  axios.put(ApiUrl + 'auth/user-profile', body, axiosConfig);
};

const getCurrentUser = (id: number): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return  axios.get(ApiUrl + `auth/admin-user/${id}`, axiosConfig);
};

export const loginService = { loginUser, editUsersProfile, getCurrentUser };