import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: 100px;
    }
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 200px;
    height: 100vh;
`;

