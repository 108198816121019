import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
// import { type SongsState } from '../../modals';

const initialState: any = {
    playlistFolder: [],
    playlistChildFolder: [],
    selectedPlaylist: {},
    playlist: null,
    isPlaylistFilterEmpty: false,
};

export const playlistSlice = createSlice({
    name: 'playlist',
    initialState,
    reducers: {
        setPlaylistFolder: (state, action: PayloadAction<any>) => {
            state.playlistFolder = action.payload;
        },
        setPlaylistChildFolder: (state, action: PayloadAction<any>) => {
            state.playlistChildFolder = action.payload;
        },
        setSelectedPlaylistState: (state, action: PayloadAction<any>) => {
            state.selectedPlaylist = action.payload;
        },
        setPlayList: (state, action: PayloadAction<any>) => {
            state.playlist = action.payload;
        },
        setPlaylistFilterEmpty: (state, action: PayloadAction<any>) => {
            state.isPlaylistFilterEmpty = action.payload;
        },
    },
});

export const { setPlaylistFolder, setPlaylistChildFolder, setSelectedPlaylistState, setPlayList, setPlaylistFilterEmpty } = playlistSlice.actions;

export const playlistFolder = (state: RootState): any => state.playlist.folder;

export default playlistSlice.reducer;
