/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState, type FC } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import ButtonComponent from './ButtonComponent';
import { type crossFadeInterface } from '../modals/crossFade';

// --------------------------------------------

const CrossFadeComponent: FC<crossFadeInterface> = ({ crossfadeStartsTime, crossfadeDuration, onUpdateButtonHandler }) => {
  const [startCrossfade, setStartCrossfade] = useState<number>(0);
  const [endCrossfade, setEndCrossfade] = useState<number>(0);

  const handleReset = () => {
    setStartCrossfade(crossfadeStartsTime);
    setEndCrossfade(crossfadeDuration);
  };

  useEffect(() => {
    setStartCrossfade(crossfadeStartsTime);
    setEndCrossfade(crossfadeDuration);
  }, [crossfadeStartsTime, crossfadeDuration]);

  return (
    <React.Fragment>
      <Box sx={{ width: '100%' }} >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <Typography sx={{ mr: 2 }} >Time crossfade starts:</Typography>
          <Slider
            size="medium"
            value={startCrossfade}
            aria-label="medium"
            onChange={(e, value) => { setStartCrossfade(Number(value)); }}
            valueLabelDisplay="auto"
            sx={{ width: '50%' }}
            max={60}
          />
          <Typography sx={{ ml: 2 }} >{startCrossfade} s</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }} >
          <Typography sx={{ mr: 4 }} >Crossfade duration:</Typography>
          <Slider
            size="medium"
            value={endCrossfade}
            aria-label="medium"
            onChange={(e, value) => { setEndCrossfade(Number(value)); }}
            valueLabelDisplay="auto"
            sx={{ width: '50%' }}
            max={60}
          />
          <Typography sx={{ ml: 2 }} >{endCrossfade} s</Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }} >
        <ButtonComponent
          type="button"
          variant="contained"
          btnName="Reset"
          onClick={handleReset}
          sx={{ mr: 2 }}
        />
        <ButtonComponent
          type="button"
          variant="contained"
          btnName="Update"
          onClick={() => { onUpdateButtonHandler(startCrossfade, endCrossfade); }}
        />
      </Box>
    </React.Fragment>
  );
};

export default CrossFadeComponent;
