import React, { memo, type FC } from 'react';

interface TimeDurationProps {
	totalSongs: number;
	totalDuration: any;
	totalSize: number;
}

const TimeDuration: FC<TimeDurationProps> = ({
	totalSongs,
	totalDuration,
	totalSize
}) => {
	return (
		<p className='time-duration'>
			<b>{totalDuration}</b>, <span>({totalSongs}) Songs</span>, {totalSize.toFixed(2)} MB
		</p>
	);
};

export default memo(TimeDuration);
