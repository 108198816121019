import styled from 'styled-components';
import '../../style/main.scss';

export const StreamingContainer = styled.div`
  width: 100%;
  padding: 24px;
  .streaming-data {
    display: flex;
    flex-wrap: wrap;
    .streaming-image {
      width: 300px;
      height: 300px;
      img.client-logo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .streaming-info {
      padding-left: 20px;
      width: calc(100% - 300px);
      h2 {
        font-size: 30px;
        font-weight: bold;
        font-family: "medium-fonts";
        margin: 0 0 5px 0;
      }
      .streaming-input {
        background: transparent;
        width: 400px;
        input {
          padding: 0 10px 0 0;
          -webkit-text-fill-color: #000;
          width: 100%;
        }
      }
      .copy_feild {
        display: flex;
        align-items: center;
        padding:10px 0;
        h6 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          line-height: 1.2;
        }
        button.popup-close-btn {
          padding: 0;
          svg {
            fill: #000;
            margin-left: 10px;
          }
        }
      }
    }
    .server-status-wrapper {
      margin: 20px 0 0 0;
      .status {
        width: auto;
        height: 40px;
        border-radius: 10px;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        -moz-box-pack: center;
        justify-content: center;
        color: rgb(255, 255, 255);
        margin-right: 20px;
        font-size: 18px;
        font-family: "medium-fonts";
        padding: 10px 15px;
      }
      .copy-button {
      font-size: 18px;
      margin-left: 0px;
      border-radius: 10px;
      color: #000;
      background: transparent;
      height: 40px;
      border: 1px solid;
      width: 120px;
      font-family: "medium-fonts";
      &:hover {
          text-decoration: none;
          background-color: rgb(28, 28, 30);
          box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
          color: #fff;
          border-color: #000;
      }
    }
  }
  .restaurant-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    .client-logo {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 10px;
    }
    &.restaurant p {
      font-size: 30px;
      line-height: 35.8px;
      font-weight: 700;
    }
    &.Server-status-wrapper button {
      width: 120px;
      height: 40px;
      padding: 10.5px 30px;
      border-radius: 10px;
      box-shadow: none;
      margin-right: 20px;
      background: transparent;
      color: #000;
    }
    &.Server-status-wrapper .status {
      height: 40px;
      padding: 10.5px 30px;
      border-radius: 10px;
      box-shadow: none;
      margin-right: 20px;
      color: #FFFFFF;
      text-align: center;
      margin-left: 8px;
    }
  }
  
    span.MuiButton-startIcon {
      margin-right: 4px;
    }
    svg {
      fill: #fff;
      width: 19px;
      height: 19px;
    }
  }
  button[disabled]{
    opacity: 0.6;
    color: white;
  }
  p {
    font-size: 16px;
    color: #000;
    font-family: "medium-fonts";
  }
  .stream-details-wrapper{
    display: flex;
    align-items: center;
    margin: 20px 0 0 0;
    h3 {
      font-size: 18px;
      margin: 0px 0px 5px;
      font-family: "medium-fonts";
    }
    p {
      font-size: 16px;
      margin: 0 0 5px 0;
      font-family: "regular-fonts";
    }
    .info {
      padding-left: 10px;
      h5 {
        font-size: 18px;
        font-weight: 700;
        font-family: "medium-fonts";
      }
    }
    .pause-play-icon {
      background: var(--swichSuccess);
      width: 50px;
      height: 50px;
      svg {
        fill: #fff;
        width: 30px;
        height: 30px;
      }
    }
  }
`;