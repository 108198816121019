/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import { PauseCircle, PlayCircleFilled } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { isPlayed, setPlay } from '../../redux/slice/selectedSong';
// import RewindIcon from '../../assets/images/icons/rewind.svg';
// import ForwardIcon from '../../assets/images/icons/forward.svg';

// icons

interface Control {
	audioRef?: any;
	progressBarRef?: any;
	duration?: any;
	setTimeProgress?: any;
	currentTrack?: any;
}
const Controls = ({
	audioRef,
	progressBarRef,
	duration,
	setTimeProgress,
	currentTrack
}: Control) => {
	const isPlay = useAppSelector(isPlayed);
	const dispatch = useAppDispatch();
	const [isPlaying, setIsPlaying] = useState(isPlay);

	useEffect(() => {
		setIsPlaying(isPlay);
	}, [isPlay]);

	const togglePlayPause = () => {
		setIsPlaying((prev: boolean) => !prev);
	};

	const playAnimationRef = useRef<any>();

	const repeat = useCallback(() => {
		if (audioRef !== null) {
			const currentTime = audioRef?.current?.currentTime;		
			setTimeProgress(currentTime);
			if (progressBarRef.current) {
				progressBarRef.current.value = currentTime;
			}
			progressBarRef.current?.style.setProperty(
				'--range-progress',
				`${(progressBarRef.current?.value / duration) * 100}%`
			);
			progressBarRef.current?.style.setProperty(
				'background',
				`linear-gradient(to right, #8E2734 0%, #8E2734 ${
					(progressBarRef.current?.value / duration) * 100
				}%, #F2A892
				${(progressBarRef.current?.value / duration) * 100}%, #F2A892 100%)`
			);
			playAnimationRef.current = requestAnimationFrame(repeat);
		}
	}, [audioRef, duration, progressBarRef, setTimeProgress, currentTrack]);

	useEffect(() => {
		if (isPlaying) {
			audioRef?.current?.play();
		} else {
			audioRef?.current?.pause();
		}
		dispatch(setPlay(true));
		playAnimationRef.current = requestAnimationFrame(repeat);
	}, [audioRef]);

	useEffect(() => {
		if (isPlaying) {
			audioRef?.current?.play();
			dispatch(setPlay(true));
		} else {
			dispatch(setPlay(false));
			audioRef?.current?.pause();
		}
		playAnimationRef.current = requestAnimationFrame(repeat);
	}, [isPlaying, repeat]);

	// const skipForward = () => {
	// 	audioRef.current.currentTime = Number(audioRef.current.currentTime) + 15;
	// };

	// const skipBackward = () => {
	// 	audioRef.current.currentTime = Number(audioRef.current.currentTime) - 15;
	// };

	return (
		<div className='controls_wrapper'>
			<div className='controls'>
				{/* <IconButton onClick={skipBackward}>
					<img src={RewindIcon} alt='' />
				</IconButton> */}
				<IconButton className='pause-play-icon' onClick={togglePlayPause}>
					{isPlaying ? <PauseCircle /> : <PlayCircleFilled />}
				</IconButton>
				{/* <IconButton onClick={skipForward}>
					<img src={ForwardIcon} alt='' />
				</IconButton> */}
			</div>
		</div>
	);
};

export default Controls;
