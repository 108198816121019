import React, { type FC } from 'react';
// import { useAppSelector } from '../redux/hooks';
import { Navigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import FileUploadComponentV2 from '../components/FileUploadComponentV2';

interface PropType {
	component: React.FC;
	needDocUpload?: boolean;
}

const PrivateRoute: FC<PropType> = ({
	component: Component,
	needDocUpload
}) => {
	// const { isLoggedIn } = useAppSelector((state) => state.auth);
	// console.log('isLoggedIn', isLoggedIn);

	const location = useLocation();
	const isLoggedIn: any = localStorage.getItem('isLoggedIn');

	if (JSON.parse(isLoggedIn) !== true) {
		return <Navigate to='/login' state={{ from: location }} />;
	}

	if (needDocUpload === true) {
		return (
			<>
				<Header />
				<FileUploadComponentV2 component={Component} />
			</>
		);
	}

	return (
		<>
			<Header />
			<Component />
		</>
	);
};

export default PrivateRoute;
