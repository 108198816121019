/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState } from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ButtonComponent from './ButtonComponent';
import { useAppDispatch } from '../redux/hooks';
import { copyPlaylist } from '../redux/actions/playlistAction';
import {
	ConfirmationModal,
	ConfirmationModalBody,
	ConfirmationModalHeader
} from '../pages/playlist/style';

const CopyPlaylistComponent = ({
	playlist,
	onClose,
	title
}: {
	playlist: any;
	onClose: any;
	title: any;
}) => {
	const dispatch = useAppDispatch();
	const [input, setInput] = useState<string>('');
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	useEffect(() => {
		setInput(`${playlist?.name} copy`);
	}, [playlist]);

	const handleCopyPlaylist = (): void => {
		setIsDisabled(true);
		const data = {
			playListId: playlist.playlistId,
			name: input.trim(),
			parentFolderId: playlist.parentFolderId
		};
		void dispatch(copyPlaylist({ data, handleClose }));
	};

	const handleClose = (data: any) => {
		setIsDisabled(false);
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (data?.success) {
			onClose();
		}
	};

	return (
		<ConfirmationModal>
			<ConfirmationModalHeader>
				<Typography>{title}</Typography>
				<IconButton
					aria-label='close'
					onClick={onClose}
					className='popup-close-btn'
				>
					<CloseIcon />
				</IconButton>
			</ConfirmationModalHeader>
			<ConfirmationModalBody>
				<div className='form-field'>
					<TextField
						value={input}
						variant='outlined'
						onChange={(e: any) => {
							setInput(e.target.value);
						}}
					/>
				</div>
				<Box sx={{ textAlign: 'right' }}>
					<ButtonComponent
						type='button'
						btnName='Cancel'
						variant='outlined'
						onClick={onClose}
						sx={{ m: 1 }}
					/>
					<ButtonComponent
						type='button'
						btnName='Ok'
						variant='contained'
						onClick={handleCopyPlaylist}
						sx={{ m: 1 }}
						disabled={isDisabled}
					/>
				</Box>
			</ConfirmationModalBody>
		</ConfirmationModal>
	);
};

export default CopyPlaylistComponent;
