import { v4 as uuidv4 } from 'uuid';

export default function UpdateData(data: any): any {
    const rootFolders = data.filter((el: any) => (el.type === 'folder' && ((el.parentFolderId === 0) || (el.parentFolderId === null)))).map((elem:any) => ({ ...elem, parent: Number(elem.parentFolderId === null ? 0 : elem.parentFolderId), text: elem.name, id: elem.folderId }));
    const parentFolder = data.filter((el: any) => (el.type === 'folder' && el.parentFolderId)).map((elem:any) => ({ ...elem, parent: Number(elem.parentFolderId === null ? 0 : elem.parentFolderId), text: elem.name, id: elem.folderId }));
    const childPlaylist = data.filter((el: any) => (el.type === 'playlist' && el.playlistId)).map((elem:any) => ({ ...elem, parent: Number(elem.parentFolderId === null ? 0 : elem.parentFolderId), text: elem.name, id: uuidv4() }));
    const updatedData = [...rootFolders, ...parentFolder, ...childPlaylist];
    return updatedData;
}

