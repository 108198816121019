import React, { type FC } from 'react';
import { Stack, Pagination, Select, MenuItem, Box } from '@mui/material';
import { type PaginationCompoInterface } from '../modals';

const PaginationComponent: FC<PaginationCompoInterface> = ({
	count,
	page,
	onChange,
	pageSizeOptions,
	paginationValue,
	handlePageSizeChange
}) => {
	return (
		<Stack spacing={2} margin='auto' paddingTop={2}>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Pagination
					showFirstButton
					showLastButton
					size='large'
					count={count}
					page={page}
					onChange={onChange}
					shape='rounded'
					variant='outlined'
				/>
				<Select
					className='table-pagination'
					id='demo-simple-select'
					value={paginationValue}
					onChange={handlePageSizeChange}
					sx={{ backgroundColor: 'var(--darkerBgColor)', marginLeft: 5 }}
				>
					{pageSizeOptions?.map((e: number, index: number) => {
						return (
							<MenuItem key={index} value={e}>
								{e}
							</MenuItem>
						);
					})}
				</Select>
			</Box>
		</Stack>
	);
};
export default PaginationComponent;
