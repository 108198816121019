import * as Yup from 'yup';

export const AddClientFormSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    email: Yup.string()
        .email()
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required'),
    country: Yup.string()
        .required('Country is required'),
    timezone: Yup.string()
        .required('Timezone is required'),
});

export const EditClientFormSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    email: Yup.string()
        .email()
        .required('Email is required'),
    country: Yup.string()
        .required('Country is required'),
    timezone: Yup.string()
        .required('Timezone is required'),
});