import styled from 'styled-components';
import '../../style/main.scss';
import imageIcon from '../../assets/images/favicon-icon.svg';

export const ClientWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction:column;
  min-height: 700px; 
  padding: 20px 30px;

  .search-wrapper{
    display: flex;
  }
  .MuiButton-sizeMedium{
    padding:8px 20px;
    text-transform: capitalize;
  }
  .search-field{
    background-color: var(--primary);
    border-radius:10px;
    border: none;
    overflow: hidden;
    margin-right: 20px;
    width: 100%;

    input {
      color:var(--white);
      
      &:placeholder {
      color:var(--white);
      opacity:.5;
    }   
  }
`;

export const ClientModal = styled.div`
  width: 100%;
  height : auto;
  padding:0px;

  .MuiDialogContent-root{
    padding:0px;
  }
  .MuiSwitch-sizeMedium{
    transform: scale(1.3) !important;
    transform-origin: top left !important;
  }
  .MuiSwitch-sizeMedium .MuiSwitch-switchBase + .MuiSwitch-track{
    background: var(--darkerBgColor) !important;
  }
  .MuiSwitch-sizeMedium .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background: var(--swichSuccess) !important;
  }
`;

export const StyledClientDeleteModal = styled.div`
  padding: 20px;
  background-color : var(--bgColor);

  .MuiDialogContent-root{
    padding:0px;
  }
  .MuiSwitch-sizeMedium{
    transform: scale(1.3) !important;
    transform-origin: top left !important;
  }
  .MuiSwitch-sizeMedium .MuiSwitch-switchBase + .MuiSwitch-track{
    background: var(--darkerBgColor) !important;
  }
  .MuiSwitch-sizeMedium .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background: var(--swichSuccess) !important;
  }
  .modal-buttons{
    margin-top:30px;
  }
  .MuiButton-outlined{
    padding: 5px 20px;
    margin-right:20px;
    width:105px;
    font-size: 16px;
    text-transform: capitalize;
  }
  .MuiButton-contained{
    padding: 5px 20px;
    width:105px;
    font-size: 16px;
    text-transform: capitalize;
  }
`;

export const ClientModalHeader = styled.div`
  padding:10px 20px;
  background-color : var(--darkerBgColor);

  h5{
    margin-bottom: 0px;
    font-size:18px;
    font-family: "title-fonts" !important;
  }
  .popup-close-btn{
    position: absolute;
    right: 8px;
    top: 3px;
    color: var(--primary);
  }
`;

export const ClientModalContent = styled.div`
  padding: 20px 20px 0 20px;
  background-color: var(--bgColor);
`;

export const ClientModalForm = styled.div`
  padding: 20px;
  background-color : var(--bgColor);
  max-height: calc(90vh - 64px);
  overflow: auto;

  .form-field{
    display: grid;
    grid-template-columns:1fr 5fr;
    grid-gap: 20px;
    margin-bottom:20px;

    .MuiInputBase-colorPrimary{
      border-radius:10px;
      border:none !important;
      box-shadow:none !important;
      outline: none !important;
      padding-right: 0;
    }
    .css-113qkos-MuiSwitch-root .MuiSwitch-switchBase+.MuiSwitch-track{
      background-color: var(--darkerBgColor);
    }
    .css-113qkos-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
      background-color: var(--swichSuccess);
    }
    input{
      padding: 8px 20px;
      font-size: 14px;
      border:none;
      border-radius:10px;
      background-color: var(--darkerBgColor);
      &#password {
        padding-right: 40px;
      }
    }
    .eye-icon {
      height: 24px;
      width: 24px;
      max-height: unset;
      margin: 0px;
      display: block;
      position: absolute;
      right: 10px;
      button {
          padding: 0;
      }
    }

    &.upload-field {
      position:relative;

      input{
        display: none;
      }
     .image-wrapper {
        position: absolute;
        width: 557px;
        height: 125px;
        align-items: center;
        text-align:center;
        display: flex;
        background: var(--darkerBgColor);
        z-index: 1;
        right: 0;
        border-radius: 10px;
        overflow: hidden;
        button{
          position:absolute;
          right:5px;
          top:5px;
        }
        .logo-image-item .image {
          height: 125px;
          padding: 15px;
        }
        .logo-image-item img {
          height: 100%;
          object-fit: contain;
          border-radius: 0;
          object-position: left;
          margin: 0;
          display: flex;
          align-items: center;
        }
      }

      &.upload-field  + p{
        position: absolute;
        width: calc(100% - 170px);
        right: 0px;
        transform: translateY(-37px) translateX(-16px);
      }

      .upload-file-icon{
        width: 100%;
        height:100%;
        background-color: var(--darkerBgColor);
        border-radius:10px;
        padding:20px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;

        img{
          margin-bottom:10px;
        }
      }
    }
    .country_select_wrapper {
      p {
        margin-left: 0px;
        margin-top: 2px;
      }
      .country_select {
        z-index: 3;
      }
      .timezone_select {
        z-index: 2;
      }
    }
  }
  .modal-buttons{
    margin-top:30px;
  }
  .MuiButton-outlined{
    padding: 5px 20px;
    margin-right:20px;
    width:105px;
    font-size: 16px;
    text-transform: capitalize;
  }
  .MuiButton-contained{
    padding: 5px 20px;
    width:105px;
    font-size: 16px;
    text-transform: capitalize;
  }
  h6{
    font-size:14px;
    margin-bottom:0px;
    margin-top:8px;
  }
`;

export const ClientDialogBox = styled.div`
  .client-dialog-box{
    display:flex;
    .MuiModal-backdrop{
      background-color:var(--white);
      .MuiDialog-paperScrollPaper{
        border-radius:10px !important;
      }
    } 
  }
`;

export const ClientList = styled.div`
  width: 100%;
  max-width:1070px;
  padding:20px 0px;
  margin:0 auto;
  display: flex;
  grid-gap: 30px;
  flex-wrap: wrap;

  .no-data-found{
    position:absolute;
  }
`;

export const ClientItem = styled.div`
  width:calc(25% - 30px);
  position: relative;

  h4 {
    font-size: 16px;
    font-family: "regular-fonts";
  }  

  .album-image{
    overflow: hidden;
    // background: url(${imageIcon});
    background-position: center;
    background-size: 110px;
    background-repeat: no-repeat;
    margin: 0 0 10px 0;
    position: relative;
    height:237.5px;
    display: flex;
    align-items: center;
    > img{
      width:100%;
      object-fit:cover;
      object-position:center;
      height:237.5px;
    }
    &.placeholder {
      border: 2px solid;
      img {
        height: 150px;
        object-fit: contain;
      }
    }
    button{
      position: absolute;
      // left:calc(100% - 90px);
      right:auto;
      left:0px;
      margin: 10px;
      box-shadow: none;
      padding: 5px 10px 4px;
      font-size: 11px;
      line-height: normal;
      width:auto;
      color: var(--primary);
      text-transform: uppercase;
      transition-duration:.3s;
      font-family: "regular-fonts";
      transition-property: initial;
      border-radius: 5px;
      &:hover {
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
      }
      &.active {
        background: #22A164;
        color: #fff;
      }
      &.activeButton {
          top: 0;
      }
      &.editBtn {
        right: 0;
        left: unset;
        background: #F2A892;
        color: #000;
        top: 0;
      }
      &.streamBtn {
        bottom: 0;
        background: #22A164;
        color: #fff;
        &.active {
          background: #22A164;
          color: #fff;
        }
        &.start {
          background: #FFAB00;
          color: #000;
        }
        &.stop {
          background: #8E2734;
          color: #fff;
        }
      }
      &.deleteBtn {
        right: 0;
        bottom: 0;
        left: unset;
        background: #8E2734;
        color: #fff;
      }
    }
    button.in-active{
      background-color: var(--inactiveBtn);
      color:var(--white);
    }
    &:hover {
      // button{
      //   right:auto;
      //   left:0px;
      // }
      .deleteIcon {
        right: 0;
        left: unset;
      }
    }
    .deleteIcon {
      padding: 5px;
      border-radius: 10px;
      width: 24px;
      height: 24px;
      bottom: 40px;
      right: 0;
      left: unset;
      &:hover {
        background: var(--swichSuccess);
        img {
          filter: brightness(0) invert(1);
        }
      }
      &.infoIcon {
        // top: unset;
        left: 0;
        img {
          height: 22px;
          width: 22px;
        }
      }
    }
    .tableIcon {
      opacity:1;
      // visiblity:hidden;
      padding:5px;
      border-radius:10px;
      width:24px;
      height:24px;
      right:0px;
      left:auto;

      img{
        border-radius:0px;
        height: 100% !important;
      }
    }
    &:hover .tableIcon{
      // opacity:1;
      // visibility:visible;
      // right:0;
      // left:auto;

      &:hover{
        background-color:var(--swichSuccess);
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
  }
`;