import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  count: 0,
  progress: 0,
  individualProgress: 0,
  totalCount: 0
};

export const progressBarSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<any>) => {
      state.count = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<any>) => {
      state.totalCount = action.payload;
    },
    setProgress: (state, action: PayloadAction<any>) => {
      state.progress = action.payload;
    },
    setIndividualProgress: (state, action: PayloadAction<any>) => {
      state.individualProgress = action.payload;
    }
  },
});

export const { setCount, setTotalCount, setProgress, setIndividualProgress } = progressBarSlice.actions;

export default progressBarSlice.reducer;
