import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AppDispatch } from '../store';
import { handleLogout } from '../../utils/handleLogout';
import { setSnackbarState, setSnackbarMessage } from '../slice/snackbarSlice';
import { setCrossFadeData } from '../slice/crossFade';
import { crossFadeService } from '../../services/crossFadeService';

// ---------------------------------------------------------

export const handleAddCrossFade = createAsyncThunk<
  object,
  any,
  {
    dispatch: AppDispatch
  }
>
  (
    'crossFade',
    async (params, { dispatch }) => {
      try {
        const response: any = await crossFadeService.addCrossFade(params);
        dispatch(setSnackbarState(true));
        dispatch(setSnackbarMessage(response.data.message));
      } catch (error: any) {
        dispatch(setSnackbarState(true));
        dispatch(setSnackbarMessage(error.response.data.message));
        if (error.response.status === 401) {
          handleLogout();
        }
      }
    }
  );

export const handleGetCrossFade = createAsyncThunk<
  object,
  any,
  {
    dispatch: AppDispatch
  }
>
  (
    'song/crossFade',
    async (params, { dispatch }) => {
      try {
        const response: any = await crossFadeService.getCrossFade();
        dispatch(setCrossFadeData(response.data.data));
      } catch (error: any) {
        if (error.response.status === 401) {
          handleLogout();
        }
      }
    }
  );