import React, { type FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ButtonComponent from '../../components/ButtonComponent';
import {
	ClientModal,
	ClientModalContent,
	ClientModalForm,
	ClientModalHeader
} from './style';
import CloseIcon from '@mui/icons-material/Close';

interface ClientDeleteModalProps {
	handleClose: () => void;
	handleDeleteClient: () => void;
	isLoading: boolean;
}

const ClientDeleteModal: FC<ClientDeleteModalProps> = ({
	handleClose,
	handleDeleteClient,
	isLoading
}) => {
	return (
		<ClientModal>
			<ClientModalHeader>
				<Typography variant='h5'>Confirmation</Typography>
				<IconButton
					aria-label='close'
					onClick={handleClose}
					className='popup-close-btn'
				>
					<CloseIcon />
				</IconButton>
			</ClientModalHeader>
			<ClientModalContent>
				<Typography>Are you sure you want to delete client?</Typography>
			</ClientModalContent>
			<ClientModalForm>
				<Box sx={{ textAlign: 'right' }} className='modal-buttons'>
					<ButtonComponent
						variant='outlined'
						btnName='No'
						onClick={handleClose}
					/>
					<ButtonComponent
						variant='contained'
						btnName='Yes'
						loading={isLoading}
						onClick={handleDeleteClient}
					/>
				</Box>
			</ClientModalForm>
		</ClientModal>
	);
};

export default ClientDeleteModal;
