/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, {
	type FC,
	useState,
	useEffect,
	useRef,
	useCallback,
	useLayoutEffect
} from 'react';
// import { useNavigate } from 'react-router-dom';
import {
	Grid,
	Typography,
	Box,
	TextField,
	InputAdornment,
	IconButton,
	Dialog,
	DialogContent,
	ToggleButton,
	ToggleButtonGroup,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	type SelectChangeEvent,
	CircularProgress
} from '@mui/material'; // Rating,
import ButtonComponent from '../../components/ButtonComponent';
import SongForm from './SongForm';
import AssignTagForm from './AssignTagForm';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
	handleGetSongs,
	handleDeleteSong,
	handleGetRecentSongs,
	handleDeleteMultipleSong
} from '../../redux/actions/songsAction';
import type {
	CreateAndRenameModalInput,
	SelectedPlaylist,
	SongTableDataType
} from '../../modals';
import musicIcon from '../../assets/images/icons/music-item.svg';
import folderIcon from '../../assets/images/icons/folder-icon.svg';
import SearchIcon from '../../assets/images/icons/search.svg';
// import ImportFileIcon from '../../assets/images/icons/import-icon.svg';
import { SongWrapper, SongHeader, SongModal, ToggleButtons } from './style';
import Confirmation from '../../components/ConfirmationModalComponent';
import PaginationComponent from '../../components/PaginationComponent';
import { isEmpty } from '../../utils/validators';
import DataTableForSong from './DataTableForSong';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import {
	setPlay,
	setSelectedSong,
	isPlayed,
	isSelectedSong
} from '../../redux/slice/selectedSong';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
	handleCreatePlaylistFolderV2,
	handleCreatePlaylistV2,
	handleGetPlaylistFolderV2
} from '../../redux/actions/playlistAction';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import * as Yup from 'yup';
import InputComponent from '../../components/InputComponent';
import { Form, Formik } from 'formik';
import { setLoadingState } from '../../redux/slice/loadingSlice';
import {
	handleAddPlaylistSongs,
	handleUpdateSongsRating
} from '../../redux/actions/playlistSongAction';
import CreateNewPlaylistComponent from './CreateNewPlaylistComponent';
import CreateNewFolderComponent from './CreateNewFolderComponent';
import CreateNewPlayListForm from './CreateNewPlayListForm';
import PlayListTitle from './PlayListTitle';
import {
	type MRT_SortingState,
	type MRT_TableInstance
} from 'material-react-table';
import TimeDuration from '../playlist/TimeDuration';
import { totalTime } from '../../utils/getTotalTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDebounce } from '../../utils/useDebounce';

const Songs: FC = () => {
	const dispatch = useAppDispatch();
	// const navigate = useNavigate();
	const isPlay = useAppSelector(isPlayed);
	const { songs, totalCount } = useAppSelector((state) => state.songs);
	const { playlistFolder } = useAppSelector((state) => state.playlist);
	const { isLoading } = useAppSelector((state) => state.loading);
	const [isOpenSongForm, setSongForm] = useState<boolean>(false);
	const [isTagAssign, setIsTagAssign] = useState(false);
	const [preFillData, setPreFillData] = useState<SongTableDataType>({id: 0});
	const [activeButton, setActiveButton] = useState('');
	// Search
	const [searchInput, setSearchInput] = useState<string>('');
	// Pagination
	const [page, setPage] = useState<number>(1);
	const [alignment, setAlignment] = React.useState('recent');
	const [songItem, setSongItem] = useState<any>();
	const [orderBy, setOrderBy] = useState<string>('createdAt');
	const [rowId, setRowId] = useState(1);
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
	const [id, setId] = useState(0);
	const [error, setError] = useState<boolean>(false);
	const [isActiveFolderId, setActiveFolderId] = useState();
	const [isCreateNew, setIsCreateNew] = useState<boolean>(false);
	const [isPlaylist, setIsPlayList] = useState<boolean>(false);
	const [active, setActive] = useState(false);
	// Row selection
	const [rowSelection, setRowSelection] = useState({});
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 100
	});
	const [selectedRows, setSelectedRows] = useState<any[]>([]);

	const getOffset = () => {
		console.log('inner', pagination);
		const { pageIndex, pageSize } = pagination;
		return (pageIndex + 1) * pageSize - pageSize;
	};
	const getLimit = () => {
		return pagination?.pageSize || 100;
	};

	useEffect(() => {
		setIsPlaying(isPlay);
		return () => {
			setPlay(false);
		};
	}, [isPlay]);

	const handleGetSong = (alignment: any = 'all', search?: string): void => {
		if (alignment === 'recent') {
			dispatch(setLoadingState(true));
			void dispatch(
				handleGetRecentSongs({
					sort: sortDirection.toUpperCase(),
					field: orderBy,
					search: search ?? searchInput?.trim()
				})
			);
			return;
		}
		dispatch(setLoadingState(true));
		void dispatch(
			handleGetSongs({
				sort: sortDirection.toUpperCase(),
				field: orderBy,
				search: search ?? searchInput?.trim(),
				offset: getOffset(),
				limit: getLimit()
			})
		);
	};

	const handleClear = () => {
		setRowSelection({});
		setSelectedRows([]);
		setSearchInput('');
		setId(0);
		setSorting([]);
		setSortDirection('desc');
		setOrderBy('createdAt');
	};

	const handleSelectionClear = () => {
		setRowSelection({});
		setSelectedRows([]);
		setId(0);
	};

	const handleSearch = (e: any, alignment: any = 'all'): void => {
		setRowSelection({});
		setSelectedRows([]);
		setId(0);
		setPage(1);
		setPagination((state: any) => {
			return {
				...state,
				pageIndex: 0
			};
		});
		if (alignment === 'recent') {
			dispatch(setLoadingState(true));
			void dispatch(
				handleGetRecentSongs({
					search: e.target.value?.trim(),
					sort: sortDirection.toUpperCase(),
					field: orderBy
				})
			);
			return;
		}
		dispatch(setLoadingState(true));
		void dispatch(
			handleGetSongs({
				search: e.target.value?.trim(),
				sort: sortDirection.toUpperCase(),
				field: orderBy,
				limit: getLimit()
			})
		);
	};

	const refreshPage = (): void => {
		handleClear();
		setPage(1);
		setPagination({ pageIndex: 0, pageSize: 100 });
		if (alignment === 'recent') {
			dispatch(setLoadingState(true));
			void dispatch(
				handleGetRecentSongs({
					sort: sortDirection.toUpperCase(),
					field: orderBy,
					search: ''
				})
			);
			return;
		}
		dispatch(setLoadingState(true));
		void dispatch(
			handleGetSongs({
				sort: sortDirection.toUpperCase(),
				field: orderBy,
				search: '',
				offset: 0,
				limit: 100
			})
		);
	};

	useEffect(() => {
		handleGetSong(alignment);
	}, [sortDirection, orderBy, pagination]);

	const [focused, setFocus] = useState(-1);
	const handleClose = (): void => {
		setSongForm(false);
		setError(false);
		setSelectedPlaylist({});
		setIsCreatePlayList(false);
		setActive(false);
	};

	const songFormModalOpen = (data: SongTableDataType, id: number): void => {
		setPreFillData(data);
		setSongForm(true);
		setFocus(id);
		setActiveButton('open');
	};
	const [isOpenConfirmation, setConfirmation] = useState<boolean>(false);
	const [songId, setSongId] = useState<number>(0);
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [isCreatePlayList, setIsCreatePlayList] = useState(false);
	const [isMultiDelete, setIsMultiDelete] = useState<boolean>(false);
	const [selectedPlaylist, setSelectedPlaylist] = useState<SelectedPlaylist>({});
	// const [playListId, setPlayListId] = useState();
	const [currentSelectedSongId, setCurrentSelectedSongId] = useState([]);
	const [parentFolder, setParentFolder] = useState<any[]>([]);
	const handleCloseConfirmation = (): void => {
		setConfirmation(false);
	};

	const handleCloseConfirmationForMultiDelete = (): void => {
		setIsMultiDelete(false);
		handleSelectionClear();
	};

	const getStatusString = (type: any): any => {
		switch (type) {
			case 'completed':
				return 'Done';
			case 'pending':
				return 'Load';
			case 'inprogress':
				return 'Wait';
			case 'failed':
				return 'Fail';
			default:
				return null;
		}
	};

	const handleSelectSong = (id: number): void => {
		setConfirmation(true);
		setSongId(id);
		setFocus(id);
		setActiveButton('close');
	};

	useEffect(() => {
		if (!isOpenSongForm && !isOpenConfirmation) {
			setFocus(-1);
			setActiveButton('');
		}
	}, [isOpenSongForm, isOpenConfirmation]);

	useEffect(() => {
		void dispatch(handleGetPlaylistFolderV2({}));
	}, []);

	useEffect(() => {
		if (playlistFolder?.length > 0) {
			const data: any = playlistFolder;
			const folderTree = (function (data: any[], root: any) {
				const tempObject: any = {};
				data.forEach((item) => {
					Object.assign((tempObject[item.id] = tempObject[item.id] || {}), item);
					tempObject[item.parentFolderId] = tempObject[item.parentFolderId] || {};
					tempObject[item.parentFolderId].children =
						tempObject[item.parentFolderId].children || [];
					tempObject[item.parentFolderId].children.push(tempObject[item.id]);
				});
				return tempObject[root]?.children;
			})(data, null);
			folderTree?.length > 0 && setParentFolder(folderTree);
		}
	}, [playlistFolder]);

	const handleDeleteSongConfirm = (): void => {
		handleSelectionClear();
		if (songs?.length === 1) {
			setPage(1);
		}
		const resetProps = {
			sort: sortDirection.toUpperCase(),
			field: orderBy,
			offset: songs?.length === 1 ? 0 : getOffset(),
			search: searchInput?.trim() || '',
			limit: alignment === 'recent' ? 1000 : getLimit()
		};
		dispatch(setLoadingState(true));
		void dispatch(
			handleDeleteSong({ songId, resetProps, alignment: alignment === 'recent' })
		);
		setConfirmation(false);
	};

	// Multi-delete
	const handleMultiDelete = (): any => {
		if (songs?.length === currentSelectedSongId?.length) {
			setPage(1);
		}
		const resetProps = {
			sort: sortDirection.toUpperCase(),
			field: orderBy,
			offset: songs?.length === currentSelectedSongId?.length ? 0 : getOffset(),
			search: searchInput?.trim() || '',
			limit: alignment === 'recent' ? 1000 : getLimit()
		};
		const data = {
			ids: currentSelectedSongId.toString(),
			resetProps,
			alignment: alignment === 'recent',
			isSong: true
		};
		dispatch(setLoadingState(true));
		void dispatch(handleDeleteMultipleSong(data));
		handleCloseConfirmationForMultiDelete();
	};
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string
	) => {
		if (newAlignment === null) {
			return;
		}
		handleClear();
		setPage(1);
		setPagination({
			pageIndex: 0,
			pageSize: 100
		});
		setAlignment(newAlignment);
		handleGetSong(newAlignment, '');
	};

	const handleOnPlayPause = (bool: any): any => {
		setIsPlaying(bool);
	};

	const handleAction = (array: any, actionType: string): void => {
		switch (actionType) {
			case 'selectedSongs':
				setIsCreatePlayList(true);
				break;
			case 'tagAssign':
				setIsTagAssign(true);
				break;
			case 'multiDelete':
				setIsMultiDelete(true);
				break;
			default:
				break;
		}

		const latestSelectedSongIds = array.map((item: any): any => {
			return item.id;
		});

		setCurrentSelectedSongId(latestSelectedSongIds);
	};

	const handleRowId = (id: any): any => {
		setRowId(id);
	};

	const togglePlayPause = (prev: any, id: any, row: any) => {
		setSongItem(row);
		if (rowId === id) {
			setIsPlaying((prev) => !prev);
			dispatch(setPlay(!isPlay));
		} else {
			setIsPlaying(true);
			dispatch(setPlay(true));
		}
		setRowId(id);
		dispatch(setSelectedSong(row));
	};

	const minSize = 40;
	const maxSize = 10000;

	useEffect(() => {
		return () => {
			dispatch(setSelectedSong(undefined));
		};
	}, []);

	const _songController = () => {
		return (
			<Box alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
				<AudioPlayer />{' '}
			</Box>
		);
	};

	const _renderSongController = () => {
		if (songItem !== undefined) {
			return _songController();
		}
	};

	const useKeyPress = (
		keys: any,
		callback: (event: any) => void,
		node = null
	) => {
		const callbackRef = useRef(callback);
		useLayoutEffect(() => {
			callbackRef.current = callback;
		});

		const handleKeyPress = useCallback(
			(event: { key: any }) => {
				if (keys.some((key: any) => event.key === key)) {
					callbackRef.current(event);
				}
			},
			[keys]
		);

		useEffect(() => {
			const targetNode = node ?? document;
			targetNode && targetNode.addEventListener('keydown', handleKeyPress);
			return () => {
				targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
			};
		}, [handleKeyPress, node]);
	};

	const onKeyPress = (event: any) => {
		if (event.shiftKey && event.key === ' ') {
			event.preventDefault();
			songs?.length > 0 && togglePlayPause(false, Number(id), songs[id]);
			return;
		}
		// if (event.key === ' ') {
		// 	event.preventDefault();
		// 	return;
		// }
		if (event.key === 's') {
			dispatch(setPlay(false));
		}
	};

	useKeyPress([' ', 's'], onKeyPress);

	const openContextMenu = (
		e: React.MouseEvent<HTMLDivElement>,
		item: any
	): void => {
		e.preventDefault();
		e.stopPropagation();
	};

	const childFolder = (item: any): any => {
		return item.map((child: any) => (
			<Accordion key={child.id}>
				<AccordionSummary
					expandIcon={<ArrowForwardIosIcon />}
					id='panel1a-header'
					onContextMenu={(e) => {
						openContextMenu(e, child);
					}}
				>
					<Typography>
						<img src={folderIcon} alt='' />
						{child.name}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{child.children !== undefined && childFolder(child.children)}
					{child.playlist?.length > 0 && (
						<>
							{child.playlist.map((playlist: any) => (
								<Box
									key={playlist.id}
									onClick={() => {
										setActive(playlist.id);
										selectPlaylist(playlist);
									}}
									onContextMenu={(e) => {
										openContextMenu(e, item);
										setSelectedPlaylist(playlist);
									}}
								>
									<Typography
										style={{
											paddingTop: 10,
											paddingBottom: 10,
											paddingLeft: 16,
											paddingRight: 16,
											cursor: 'pointer'
										}}
										key={playlist.id}
										className={`playlist-title ${
											active === playlist.id ? 'selected' : ''
										}`}
									>
										<img src={musicIcon} alt='' />
										{playlist.name}
									</Typography>
								</Box>
							))}
						</>
					)}
					{isPlaylist && isActiveFolderId === child.id ? (
						<div>
							<Formik
								initialValues={initialValuePlaylist}
								validationSchema={validationSchemas}
								onSubmit={handleCreatePlaylist}
							>
								{({ values, errors, touched, handleChange, handleBlur }) => (
									<Form>
										<div className='form-field'>
											<InputComponent
												id='name'
												name='name'
												value={values.name}
												placeholder='Name'
												type='text'
												variant='outlined'
												onBlur={handleBlur}
												onChange={handleChange}
												error={!!((touched.name ?? false) && errors.name != null)}
												helperText={(touched.name ?? false) && errors.name}
												required
											/>
										</div>
										<Typography>
											Are you sure you want to create new playlist ?
										</Typography>
										<Box sx={{ textAlign: 'right' }}>
											<ButtonComponent
												variant='outlined'
												btnName='Cancel'
												onClick={() => {
													setIsPlayList(false);
												}}
											/>
											<ButtonComponent
												type='submit'
												variant='contained'
												btnName='Create'
												loading={isLoading}
											/>
										</Box>
									</Form>
								)}
							</Formik>
						</div>
					) : (
						<Box className='create-new-playlist'>
							<ButtonComponent
								startIcon={<AddCircleOutlineIcon />}
								variant='outlined'
								btnName='New playlist'
								onClick={() => {
									setActiveFolderId(child.id);
									setIsPlayList(true);
								}}
							/>
						</Box>
					)}
				</AccordionDetails>
			</Accordion>
		));
	};

	const initialValues: CreateAndRenameModalInput = {
		name: ''
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Field is required')
	});
	const handleSubmit = (values: CreateAndRenameModalInput): void => {
		dispatch(setLoadingState(true));
		void dispatch(handleCreatePlaylistFolderV2({ name: values }));
		setIsCreateNew(false);
	};
	const initialValuePlaylist: CreateAndRenameModalInput = {
		name: ''
	};
	const validationSchemas = Yup.object().shape({
		name: Yup.string().required('Field is required')
	});
	// const handleSubmitPlaylist = (): void => {
	// 	console.log('called handleSubmitPlaylist');
	// 	setIsCreateNew(false);
	// };

	const selectPlaylist = (item: any): void => {
		setSelectedPlaylist(item);
		// setPlayListId(item.id);
		setError(false);
	};

	const handleCreateNewPlaylist = () => {
		// void dispatch(handleGetSongFromFolderPlayList({folderId: selectedPlaylist.id}));
		if (!isEmpty(selectedPlaylist.id)) {
			handleSelectionClear();
			setIsCreatePlayList(false);
			setActive(false);
			dispatch(setLoadingState(true));
			void dispatch(
				handleAddPlaylistSongs({
					playlistId: selectedPlaylist.id ?? 0,
					songIds: currentSelectedSongId
				})
			);
			setSelectedPlaylist({});
		} else {
			setError(true);
		}
	};

	const handleCreatePlaylist = (values: any): void => {
		setIsPlayList(false);
		dispatch(setLoadingState(true));
		void dispatch(
			handleCreatePlaylistV2({
				name: values.name,
				parentFolderId: isActiveFolderId
			})
		);
	};

	const tableInstanceRef = useRef<MRT_TableInstance<any>>(null);

	let totalSize: number = 0;
	const getTotalDuration = (data: any) => {
		if (data.length > 0) {
			const arr: string[] = data.map((item: { duration: any; size: number }) => {
				totalSize = totalSize + item?.size;
				return item?.duration;
			});
			if (arr && arr.length > 0) {
				return totalTime(arr);
			}
			return [];
		}
		return [];
	};

	const handleRatingChange = (value: number, songId: number) => {
		handleSelectionClear();
		const resetProps = {
			sort: sortDirection.toUpperCase(),
			field: orderBy,
			offset: getOffset(),
			search: searchInput?.trim() || '',
			limit: alignment === 'recent' ? 1000 : getLimit()
		};
		const data = {
			isSong: true,
			data: {
				rating: value
			},
			resetProps,
			songId,
			alignment: alignment === 'recent'
		};
		dispatch(setLoadingState(true));
		void dispatch(handleUpdateSongsRating(data));
	};

	const [sorting, setSorting] = useState<MRT_SortingState>([]);

	const handleSort = (property: string, isDesc: boolean): void => {
		setSortDirection(isDesc ? 'desc' : 'asc');
		setOrderBy(property);
		setRowSelection({});
		setSelectedRows([]);
		setId(0);
	};

	useEffect(() => {
		if (sorting.length > 0) {
			handleSort(sorting[0].id, sorting[0].desc);
		}
	}, [sorting]);

	const selectedSong = useAppSelector(isSelectedSong);

	const handlePageSizeChange = (event: SelectChangeEvent) => {
		setRowSelection({});
		setSelectedRows([]);
		setPage(1);
		setId(0);
		setPagination({ pageIndex: 0, pageSize: Number(event?.target?.value) });
	};

	const pageSizeOptions: number[] = [25, 50, 100, 200];

	const onSearchDebounce = useDebounce((e: any) => {
		handleSearch(e, alignment);
	}, 500);

	return (
		<>
			<SongWrapper>
				<Box>
					<Grid item xs={12}>
						<Box>
							<SongHeader>
								<ToggleButtons>
									<ToggleButtonGroup
										color='primary'
										value={alignment}
										exclusive
										onChange={handleChange}
										aria-label='Platform'
									>
										<ToggleButton className='songbtn' value='all'>
											All
										</ToggleButton>
										<ToggleButton className='songbtn' value='recent'>
											Recently Added
										</ToggleButton>
									</ToggleButtonGroup>
								</ToggleButtons>
								<React.Fragment>
									<Box className='playlist-buttons-time'>
										<Box className='playlist-buttons'>
											<ButtonComponent
												variant='outlined'
												btnName='Create Playlist'
												onClick={() => {
													handleAction(selectedRows, 'selectedSongs');
												}}
												sx={selectedRows?.length === 0 ? { opacity: 0.6 } : {}}
												disabled={selectedRows?.length === 0}
												startIcon={<AddIcon />}
											/>
											<ButtonComponent
												variant='outlined'
												btnName='Assign tags'
												onClick={() => {
													handleAction(selectedRows, 'tagAssign');
												}}
												sx={selectedRows?.length === 0 ? { opacity: 0.6 } : {}}
												disabled={selectedRows?.length === 0}
												startIcon={<AssignmentOutlinedIcon />}
											/>
											<ButtonComponent
												variant='outlined'
												btnName='Delete'
												onClick={() => {
													handleAction(selectedRows, 'multiDelete');
												}}
												sx={selectedRows?.length === 0 ? { opacity: 0.6 } : {}}
												disabled={selectedRows?.length === 0}
												startIcon={<DeleteOutlineOutlinedIcon />}
											/>
										</Box>
										{selectedRows?.length > 0 && (
											<Box className='playlist-time'>
												<TimeDuration
													totalSongs={selectedRows?.length}
													totalDuration={getTotalDuration(selectedRows)}
													totalSize={totalSize}
												/>
											</Box>
										)}
									</Box>
								</React.Fragment>
								<Box className='input-with-icon'>
									<TextField
										type='text'
										placeholder='Search'
										variant='outlined'
										size='small'
										className='search-field'
										value={searchInput}
										// onChange={onSearchDebounce}
										onChange={(e) => {
											onSearchDebounce(e);
											setSearchInput(e.target.value);
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<img src={SearchIcon} alt='' />
												</InputAdornment>
											)
										}}
									/>
									<ButtonComponent
										type='button'
										btnName='Refresh'
										variant='contained'
										startIcon={<SyncIcon style={{ height: '18px', fill: 'white', marginBottom: '2px' }} />}
										onClick={refreshPage}
									/>
								</Box>
								{/* <ButtonComponent
	type="button"
	btnName = "Import"
	variant="contained"
	startIcon={<img src={ImportFileIcon} alt="" />}
	onClick={() => {navigate('/import');}}
	/> */}

								{/* <ButtonComponent
									type='button'
									btnName='Refresh'
									variant='contained'
									startIcon={<img src={ImportFileIcon} alt='' />}
									onClick={() => {
										refreshPage();
									}}
								/> */}
							</SongHeader>
						</Box>
					</Grid>
					<Dialog
						open={isOpenSongForm}
						onClose={handleClose}
						fullWidth={true}
						maxWidth={'sm'}
						className='client-dialog-box'
					>
						<SongModal>
							<DialogContent>
								<SongForm
									setSongForm={setSongForm}
									preFillData={preFillData}
									page={page}
									resetProps={{
										sort: sortDirection.toUpperCase(),
										field: orderBy,
										offset: getOffset(),
										search: searchInput?.trim() || '',
										limit: alignment === 'recent' ? 1000 : getLimit()
									}}
									orderBy={orderBy}
									sortDirection={sortDirection}
									playlistId={0}
									alignment={alignment === 'recent'}
									handleClear={handleSelectionClear}
								/>
							</DialogContent>
						</SongModal>
					</Dialog>
					<Dialog
						open={isTagAssign}
						onClose={() => {
							setIsTagAssign(false);
						}}
						fullWidth={true}
						maxWidth={'sm'}
						className='client-dialog-box'
					>
						<SongModal>
							<DialogContent>
								<AssignTagForm
									setIsTagAssign={setIsTagAssign}
									songIds={currentSelectedSongId}
									page={page}
									resetProps={{
										sort: sortDirection.toUpperCase(),
										field: orderBy,
										offset: getOffset(),
										search: searchInput?.trim() || '',
										limit: alignment === 'recent' ? 1000 : getLimit()
									}}
									orderBy={orderBy}
									sortDirection={sortDirection}
									alignment={alignment === 'recent'}
									handleClear={handleSelectionClear}
									isSong={true}
								/>
							</DialogContent>
						</SongModal>
					</Dialog>
					<Dialog
						open={isCreatePlayList}
						onClose={handleClose}
						fullWidth={true}
						maxWidth={'sm'}
						className='datatable playlist-modal'
					>
						<DialogContent>
							<div className='confirmation-modalV2'>
								<div className='confirmation-title'>
									<Typography variant='h5'>Confirmation</Typography>
									<IconButton
										aria-label='close'
										onClick={handleClose}
										className='popup-close-btn'
									>
										<CloseIcon />
									</IconButton>
								</div>
								<div className='confirmation-body'>
									{!isCreateNew &&
										playlistFolder?.length > 0 &&
										parentFolder.map((item) => (
											<Accordion key={item.id}>
												<AccordionSummary
													expandIcon={<ArrowForwardIosIcon />}
													id='panel1a-header'
													onContextMenu={(e) => {
														openContextMenu(e, item);
													}}
												>
													<Typography>
														<img src={folderIcon} alt='' />
														{item.name}
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													{item.childFolder?.length > 0 && childFolder(item.children)}
													{item.playlist?.length > 0 && (
														<>
															{item.playlist.map((playlist: any) => (
																<PlayListTitle
																	key={playlist.id}
																	playlist={playlist}
																	onClick={() => {
																		setActive(playlist.id);
																		selectPlaylist(playlist);
																	}}
																	onContextMenu={(e: any): void => {
																		openContextMenu(e, item);
																		setSelectedPlaylist(playlist);
																	}}
																	active={active}
																/>
															))}
														</>
													)}
													<CreateNewPlayListForm
														isPlaylist={isPlaylist}
														isActiveFolderId={isActiveFolderId}
														item={item}
														isLoading={isLoading}
														initialValuePlaylist={initialValuePlaylist}
														validationSchemas={validationSchemas}
														handleSubmitPlaylist={handleCreatePlaylist}
														handleCancel={() => {
															setIsPlayList(false);
														}}
														handleCreateNewPlaylist={() => {
															setActiveFolderId(item.id);
															setIsPlayList(true);
														}}
													/>
												</AccordionDetails>
											</Accordion>
										))}
									{isCreateNew ? (
										<CreateNewFolderComponent
											initialValues={initialValues}
											validationSchema={validationSchema}
											handleSubmit={handleSubmit}
											handleCancel={() => {
												setIsCreateNew(false);
												setError(false);
											}}
										/>
									) : (
										<CreateNewPlaylistComponent
											currentSelectedSongId={currentSelectedSongId}
											error={error}
											handleCreateNewFolder={() => {
												setIsCreateNew(true);
											}}
											handleCreateNewPlaylist={handleCreateNewPlaylist}
											handleCancel={() => {
												setIsCreatePlayList(false);
												setError(false);
												setActive(false);
												setIsPlayList(false);
												setSelectedPlaylist({});
											}}
										/>
									)}
								</div>
							</div>
						</DialogContent>
					</Dialog>
					<SongModal>
						<Dialog
							open={isOpenConfirmation}
							onClose={handleCloseConfirmation}
							aria-labelledby='responsive-dialog-title'
						>
							<Confirmation
								setConfirmation={setConfirmation}
								handleConfirmation={handleDeleteSongConfirm}
								message='Song'
							/>
						</Dialog>
					</SongModal>
					<SongModal>
						<Dialog
							open={isMultiDelete}
							onClose={handleCloseConfirmationForMultiDelete}
							aria-labelledby='responsive-dialog-title'
						>
							<Confirmation
								setConfirmation={setIsMultiDelete}
								handleConfirmation={handleMultiDelete}
								message='songs'
							/>
						</Dialog>
					</SongModal>
				</Box>
				{_renderSongController()}
				<Grid item xs={18}>
					<Box width={'100%'}>
						{isLoading ? (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<CircularProgress sx={{ color: 'var(--primary)' }} size={50} />
							</Box>
						) : (
							<DataTableForSong
								isPlaying={isPlaying}
								selectedSong={selectedSong}
								rowId={rowId}
								handleRowId={handleRowId}
								handleOnPlayPause={handleOnPlayPause}
								togglePlayPause={togglePlayPause}
								minSize={minSize}
								maxSize={maxSize}
								getStatusString={getStatusString}
								songFormModalOpen={songFormModalOpen}
								handleSelectSong={handleSelectSong}
								activeButton={activeButton}
								focused={focused}
								songs={songs}
								setRowSelection={setRowSelection}
								rowSelection={rowSelection}
								setId={setId}
								tableInstanceRef={tableInstanceRef}
								setSelectedRows={setSelectedRows}
								alignment={alignment}
								page={page}
								handleRatingChange={handleRatingChange}
								setSorting={setSorting}
								sorting={sorting}
								pagination={pagination}
								sortDirection={sortDirection}
								orderBy={orderBy}
								searchInput={searchInput}
								dataLength={
									alignment === 'all'
										? pagination?.pageIndex * pagination?.pageSize + songs?.length - 1
										: songs?.length - 1
								}
							/>
						)}
						{songs?.length > 0 && alignment === 'all' && (
							<Box>
								<PaginationComponent
									count={Math.ceil(totalCount / pagination?.pageSize)}
									page={page}
									onChange={(event, value) => {
										setPage(value);
										setPagination((state: any) => {
											return { ...state, pageIndex: value - 1 };
										});
									}}
									pageSizeOptions={pageSizeOptions}
									paginationValue={pagination?.pageSize?.toString()}
									handlePageSizeChange={handlePageSizeChange}
								/>
							</Box>
						)}
					</Box>
				</Grid>
			</SongWrapper>
		</>
	);
};

export default Songs;
