import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { type SongsState } from '../../modals';

const initialState: SongsState = {
    songs: [],
    totalCount: 0,
    isLoading: true,
};

export const songsSlice = createSlice({
    name: 'songs',
    initialState,
    reducers: {
        setSongs: (state, action: PayloadAction<any>) => {
            state.songs = action.payload;
            state.isLoading = false;
        },
        setTotalCount: (state, action: PayloadAction<any>) => {
            state.totalCount = action.payload;
        },
        setIsLoading: (state) => {
            state.isLoading = false;
        }
    },
});

export const { setSongs, setTotalCount, setIsLoading } = songsSlice.actions;

export const songs = (state: RootState): any => state.songs.songs;
export const totalCount = (state: RootState): number => state.songs.totalCount;

export default songsSlice.reducer;
