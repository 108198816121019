import React, { useState, type FC, useEffect } from 'react';
import { type CustomRepeatModalProps } from '../../modals';
// import ButtonComponent from '../../components/ButtonComponent';
import { Typography, MenuItem } from '@mui/material';
import { CalendarModal, CalendarModalBody } from './style';

const CustomRepeatModal: FC<CustomRepeatModalProps> = ({ setOpenCustomRepeatModal, customRepeatValue, setCustomRepeatValue, scheduleFromId }) => {

    const dayList = [
        {id: 'monday', name: 'Mon'},
        {id: 'tuesday', name: 'Tue'},
        {id: 'wednesday', name: 'Wed'},
        {id: 'thursday', name: 'Thu'},
        {id: 'friday', name: 'Fri'},
        {id: 'saturday', name: 'Sat'},
        {id: 'sunday', name: 'Sun'},
    ];

    const [dayName, setDayName] = useState<string[]>([]);
    const [isEdited, setIsEdited] = useState<boolean>(false);

    useEffect(() => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(scheduleFromId)) {
            setDayName([]);
        }
    }, [scheduleFromId]);

    useEffect(() => {
        const addRepValue = dayName.filter((item) => typeof item === 'string' && item);
        setCustomRepeatValue(addRepValue);
    }, [dayName.length]);

    const getDayRec = (dayStr: string): boolean => {
        const resp: any = (Boolean(scheduleFromId)) && scheduleFromId.recurrenceRule.split(';');
        const days = [];
        let i;
        if (resp?.length > 0) {
            for (i = 0; i < resp.length; i++) {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (resp[i].includes('BYDAY')) {
                    const containdDay = resp[i].split('=');
                    days.push(containdDay[1].split(','));
                }
            }
            const base: any = {
                'MO': 'monday',
                'TU': 'tuesday',
                'WE': 'wednesday',
                'TH': 'thursday',
                'FR': 'friday',
                'SA': 'saturday',
                'SU': 'sunday',
            };
            if (days[0]?.length > 0) {
                for (i = 0; i < days[0].length; i++) {
                    if(base[days[0][i]] === dayStr) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const geStyleClass = (item: any): any => {
        const response = getDayRec(item);
        if (dayName.includes(item) || (response && typeof item === 'string')) {
            if (response && !isEdited) {
                if (!dayName.includes(item)) {
                    if (dayList.length > 0) {
                        setDayName([...dayName, item]);
                    } else {
                        setDayName([item]);
                    }
                }
            }
            return 'week-days clicked';
        }
        return 'week-days';
    };

    const removeDayFromList = (value: any): any => {
        const tempDName = dayName; 
        const getIndex = tempDName.indexOf(value);
        if (tempDName?.length === 1) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete tempDName[getIndex];
            setDayName([]);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete tempDName[getIndex];
            setDayName(tempDName.filter((a) => a));
        }
        
    };

    const handleChange = (value : any): any => {
        setIsEdited(true);
        if (!dayName.includes(value)) {
            if (dayName?.length > 0) {
                typeof value === 'string' && setDayName([...dayName, value]);
            } else {
                typeof value === 'string' && setDayName([value]);
            }
            return;
        }
        removeDayFromList(value);
    };

    return (
        <>
            <CalendarModal className='week-days-modal'>
                <CalendarModalBody>
                    <div className='weekdays-list'>
                        <Typography variant="h6" >Every week on</Typography>
                        {dayList.map((item, index) => (
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            // <>
                                <MenuItem className={geStyleClass(item.id)}
                                    key={index}
                                    value={customRepeatValue}
                                    onClick={(e) => { handleChange(item.id); e.currentTarget.classList.toggle('clicked');  }} // document.querySelectorAll('.week-days').forEach((item) => {  }); console.log('item', e.currentTarget.classList);  setCustomRepeatValue(item.id);
                                >
                                    {item.name}
                                </MenuItem>
                            // </>
                        ))}
                        {/* <Box sx={{ textAlign: 'right', display: 'flex' }}>
                            <ButtonComponent
                                variant="outlined"
                                btnName="Cancel"
                                onClick={() => { setOpenCustomRepeatModal(false); }}
                            />
                            <ButtonComponent
                                // type="submit"
                                variant="contained"
                                btnName="Save"
                                onClick={() => { setOpenCustomRepeatModal(false); }}
                            />
                        </Box> */}
                    </div>
                </CalendarModalBody>
            </CalendarModal>
        </>
    );
};

export default CustomRepeatModal;