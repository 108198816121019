/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, type FC, useState, useRef } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { PrefixUrl } from '../../utils/config';
import ButtonComponent from '../../components/ButtonComponent';
import { StreamingContainer } from './style';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
	handleGetStreamInfo,
	handleGetStreaming,
	handleRunStreaming
} from '../../redux/actions/streamingActions';
import { isEmpty } from '../../utils/validators';
import {
	setSnackbarMessage,
	setSnackbarState
} from '../../redux/slice/snackbarSlice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useLocation } from 'react-router-dom';
import { setLoadingState, setLoadingStateV2 } from '../../redux/slice/loadingSlice';
import { PauseCircle, PlayCircleFilled } from '@mui/icons-material';
import { setStreaming, setStreamingInfo } from '../../redux/slice/streamingSlice';

const Streaming: FC = () => {
	const dispatch = useAppDispatch();
	const { streaming, streamingInfo, currentStreamUrl } = useAppSelector((state) => state.streaming);
	const [streamingUrl, setStreamingUrl] = useState('');
	const location = useLocation();
	const { state } = location;
	const { activeClient } = useAppSelector((state) => state.client);
	const isLoader = useAppSelector((state) => state.loading.isLoading);
	const isLoading = useAppSelector((state) => state.loading.isLoadingV2);
	const [isPlaying, setIsPlaying] = useState(false);
	const audioRef = useRef<any>(null);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const allowedStatus = ['NOT-STARTED', 'DISABLED', 'STOP'];
	const streamingStatus = streaming?.streamStatus;

	const streamingStatusRef = useRef(streamingStatus);

	const GetStreaming = () => {
		const url = window.location.href;
		const parts = url.split('/');
  		const clientId = parts[parts.length - 2];
		void dispatch(
			handleGetStreaming({ clientId: state?.id || clientId || activeClient[0]?.id })
		);
	};

	const togglePlayPause = () => {
		setIsPlaying((prev: boolean) => !prev);
	};
    
	const startInterval = () => {
		dispatch(setLoadingStateV2(true));
		GetStreaming();
		intervalRef.current = setInterval(() => {
			if (streamingStatusRef.current === 'ACTIVE') {
				GetStreaming();
			}
		}, 5000);
	  };
	
	  const stopInterval = () => {
		if (intervalRef.current !== null) {
		  clearInterval(intervalRef.current);
		  intervalRef.current = null;
		}
	  };
	
	  useEffect(() => {
		streamingStatusRef.current = streamingStatus;
	  }, [streamingStatus]);
	
	useEffect(() => {
		if (isPlaying) {
			audioRef?.current?.play();
		} else {
			audioRef?.current?.pause();
		}
	}, [audioRef, isPlaying]);

	useEffect(() => {
		if (isPlaying) {
			setIsPlaying(false);
		}
	}, [streamingStatus]);

	useEffect(() => {
		startInterval();
	
		return () => {
			dispatch(setStreaming(null));
			dispatch(setStreamingInfo(null));
			stopInterval();
		};
	}, [state?.id, activeClient]);

	useEffect(() => {
		const cleanup = () => {
			dispatch(setStreaming(null));
			dispatch(setStreamingInfo(null));
		  	stopInterval();
		};
	
		window.addEventListener('beforeunload', cleanup);
	
		return () => {
		  window.removeEventListener('beforeunload', cleanup);
		};
	}, [location]);

	useEffect(() => {
		if (!isEmpty(streaming?.url?.stream)) {
			setStreamingUrl(streaming?.url?.stream);
		}
	}, [streaming]);


	useEffect(() => {	
		const modifiedString = streaming?.url?.stream && `${streaming?.url?.stream}/info`;
		if (modifiedString && currentStreamUrl !== modifiedString) {
			void dispatch(handleGetStreamInfo({url: modifiedString}));
		}

	}, [streaming?.url?.stream]);

	const handleAction = (status: string): void => {
		dispatch(setLoadingState(true));
		void dispatch(
			handleRunStreaming({
				clientId: state?.id || activeClient[0]?.id,
				streamingKey: status
			})
		);
	};

	const handleCopyToClipBoard = async (text: string) => {
		try {
		  if (navigator.clipboard) {
			await navigator.clipboard.writeText(text);
		  } else {
			const textArea = document.createElement('textarea');
			textArea.value = text;
			textArea.style.position = 'fixed';
			document.body.appendChild(textArea);
			textArea.select();
			document.execCommand('copy');
			document.body.removeChild(textArea);
		  }
	  
		  dispatch(setSnackbarState(true));
		  dispatch(setSnackbarMessage('Copied Successfully'));
		} catch (error) {
			dispatch(setSnackbarState(true));
		  	dispatch(setSnackbarMessage('Failed to copy text'));
		  	console.log('Failed to copy text:', error);
		}
	  };
	
	return (
		<StreamingContainer>
			{isLoading ? (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<CircularProgress sx={{ color: 'var(--primary)' }} size={50} />
					</Box>
				) :
			(<Box className="streaming-data">
			<Box className='streaming-image restaurant' sx={{ marginBottom: '30px' }}>
				<img
					className='client-logo'
					src={`${PrefixUrl}${String(streaming?.logo)}`}
					alt=''
				/>
			</Box>
			<Box className='streaming-info' sx={{ marginBottom: '30px' }}>
				<Typography variant="h2">{streaming?.name}</Typography>
					<Box className="copy_feild">
						<Typography variant="h6" >MP3: </Typography>
						<Typography variant="h6" sx={{ ml: 1 }}>{streamingUrl}</Typography>
						<IconButton
							onClick={() => {
								void handleCopyToClipBoard(streamingUrl);
							}}
							disabled={isEmpty(streamingUrl)}
							className='popup-close-btn'
						>
							<ContentCopyIcon />	
						</IconButton>
						{/* <ButtonComponent
							className='copy-button'
							variant='outlined'
							onClick={() => {
								void handleCopyToClipBoard();
							}}
							disabled={isEmpty(streamingUrl)}
							startIcon={<img src={copyIcon} alt='' />}
						/> */}
					</Box>
					{
						streamingStatus === 'ACTIVE' &&
						<><Box className="copy_feild">
								<Typography variant="h6">M3U: </Typography>
								<Typography variant="h6" sx={{ ml: 1 }}>{`${streamingUrl}.m3u`}</Typography>
								<IconButton
									onClick={() => {
										void handleCopyToClipBoard(`${streamingUrl}.m3u?name=${streaming?.name}`);
									} }
									disabled={isEmpty(streamingUrl)}
									className='popup-close-btn'
								>
									<ContentCopyIcon />
								</IconButton>
							</Box><Box className="copy_feild">
									<Typography variant="h6">PLS: </Typography>
									<Typography variant="h6" sx={{ ml: 1 }}>{`${streamingUrl}.pls`}</Typography>
									<IconButton
										onClick={() => {
											void handleCopyToClipBoard(`${streamingUrl}.pls?name=${streaming?.name}`);
										} }
										disabled={isEmpty(streamingUrl)}
										className='popup-close-btn'
									>
										<ContentCopyIcon />
									</IconButton>
								</Box></>
					}
					<Box className='server-status-wrapper'>
					<Box sx={{ display: 'flex' }}>
						{streamingStatus === 'ACTIVE' && ( 
							<Box className='status' sx={{ background: '#22A164' }}>
								Active
							</Box>
						)}
						{streamingStatus === 'NOT-STARTED' && (
							<Box className='status' sx={{ background: '#FFAB00' }}>
								Not-Started
							</Box>
						)}
						{streamingStatus === 'DISABLED' && (
							<Box className='status' sx={{ background: '#8E2734' }}>
								Disabled
							</Box>
						)}
						{streamingStatus === 'STOP' && (
							<Box className='status' sx={{ background: '#8E2734' }}>
								Deactivate
							</Box>
						)}
						{allowedStatus?.includes(streamingStatus) && (
							<ButtonComponent
								className='copy-button'
								variant='outlined'
								btnName='Start'
								onClick={() => {
									handleAction('ACTIVE');
								}}
								loading={isLoader}
							/>
						)}
						{streamingStatus === 'ACTIVE' && (
							<>
								<ButtonComponent
									className='copy-button'
									variant='outlined'
									btnName='Deactivate'
									onClick={() => {
										handleAction('STOP');
									}}
									loading={isLoader}
								/>
								{/* <ButtonComponent
									className='copy-button'
									variant='outlined'
									btnName='Disable'
									onClick={() => {
										handleAction('DISABLED');
									}}
									loading={isLoader}
								/> */}
								{/* <ButtonComponent
									className='copy-button'
									variant='outlined'
									btnName='Restart'
									onClick={() => {
										handleAction('ACTIVE');
									}}
									loading={isLoader}
								/> */}
							</>
						)}
					</Box>
				</Box>
				{
					streamingStatus === 'ACTIVE' &&
					<Box className='stream-details-wrapper'>
						<IconButton className='pause-play-icon' onClick={togglePlayPause}>
							<audio ref={audioRef} src={streaming?.url?.stream} />
							{isPlaying ? <PauseCircle /> : <PlayCircleFilled />}
						</IconButton>
						<Box className="info">
							<Typography variant="h5">Now Playing</Typography>
							<Typography>{`${streamingInfo?.artist ? `${streamingInfo?.artist} -` : ''} ${streamingInfo?.title ?? ''}`}</Typography>
						</Box>
					</Box>
				}
			</Box>
		</Box>)}
		</StreamingContainer>
	);
};

export default Streaming;
