import * as Yup from 'yup';

export const ScheduleFormSchema = Yup.object().shape({
    title: Yup.string()
        .required('Title is required'),
    playList: Yup.string()
        .required('Select playList'),
    repeat: Yup.string()
        .required('Select repeat time'),
    startTime: Yup.string()
        .required('Select start time'),
    endTime: Yup.string()
        .required('Select end time')
});