import { createAsyncThunk } from '@reduxjs/toolkit';
import { scheduleService } from '../../services/scheduleService';
import { handleLogout } from '../../utils/handleLogout';
import { setScheduleFromId, setScheduleList } from '../slice/scheduleSlice';
import { setSnackbarMessage, setSnackbarState } from '../slice/snackbarSlice';
import { type AppDispatch } from '../store';


export const handleGetScheduleList = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
(
    'getScheduleList',
    async (params, {dispatch}) => {
        try {
            const response = await scheduleService.getSchedule({clientId: params});
            dispatch(setScheduleList(response.data.data.results));
        } catch (error: any) {
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(error.response.data.message));
            if(error.response.status === 401) {
                handleLogout();
            }
        }
    }
);

export const handleGetScheduleListV2 = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
(
    'getScheduleListV2',
    async (params, {dispatch}) => {
        try {
            dispatch(setScheduleList([]));
            const response = await scheduleService.getScheduleV2(params);
            dispatch(setScheduleList(response.data.data));
        } catch (error: any) {
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(error.response.data.message));
            if(error.response.status === 401) {
                handleLogout();
            }
        }
    }
);

export const handleGetScheduleFromId = createAsyncThunk<
    object,
    any,

    {
        dispatch: AppDispatch
    }
>
(
    'getScheduleFromId',
    async (params, {dispatch}) => {
        try {
            const response = await scheduleService.getScheduleId(params);
            dispatch(setScheduleFromId(response.data.data));
        } catch (error: any) {
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(error.response.data.message));
            if(error.response.status === 401) {
                handleLogout();
            }
        }
    }
);

export const handleCreateSchedule = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
(
    'createSchedule',
    async (params, {dispatch}) => {
        try {
            const response = await scheduleService.createSchedule(params);
            void dispatch(handleGetScheduleListV2({ startDate: params.startDate, clientId: params.clientId, type: params.type }));
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(response.data.message));
        } catch (error: any) {
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(error.response.data.message));
            if(error.response.status === 401) {
                handleLogout();
            }
        }
    }
);

export const handleEditSchedule = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
(
    'createSchedule',
    async (params, {dispatch}) => {
        try {
            const response = await scheduleService.editSchedule(params.scheduleId, params.scheduleFormData);
            // void dispatch(handleGetScheduleList(params.scheduleFormData.clientId));
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(response.data.message));
            params.getScheduleList();
        } catch (error: any) {
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(error.response.data.message));
            if(error.response.status === 401) {
                handleLogout();
            }
        }
    }
);

export const handleDeleteSchedule = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
(
    'deleteSchedule',
    async (params, {dispatch}) => {
        try {
            const response = await scheduleService.deleteSchedule(params.scheduleId);
            // void dispatch(handleGetScheduleList(params.scheduleFormData.clientId));
            params.getScheduleList();
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(response.data.message));
        } catch (error: any) {
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(error.response.data.message));
            if(error.response.status === 401) {
                handleLogout();
            }
        }
    }
);
