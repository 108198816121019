import React, { type FC } from 'react';
import { Formik, Form } from 'formik';
import InputComponent from '../../components/InputComponent';
import { Typography, Box } from '@mui/material';
import ButtonComponent from '../../components/ButtonComponent';
import { useAppSelector } from '../../redux/hooks';
import { type CreateAndRenameModalInput } from '../../modals';

interface CreateNewFolderComponentProps {
	initialValues: CreateAndRenameModalInput;
	validationSchema: any;
	handleSubmit: any;
	handleCancel: () => void;
}

const CreateNewFolderComponent: FC<CreateNewFolderComponentProps> = ({
	initialValues,
	validationSchema,
	handleSubmit,
	handleCancel
}) => {
	const { isLoading } = useAppSelector((state) => state.loading);

	return (
		<div>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ values, errors, touched, handleChange, handleBlur }) => (
					<Form>
						<div className='form-field'>
							<InputComponent
								id='name'
								name='name'
								value={values.name}
								placeholder='Name'
								type='text'
								variant='outlined'
								onBlur={handleBlur}
								onChange={handleChange}
								error={!!((touched.name ?? false) && errors.name != null)}
								helperText={(touched.name ?? false) && errors.name}
								required
							/>
						</div>
						<Typography>Are you sure you want to create new folder ?</Typography>
						<Box sx={{ textAlign: 'right' }}>
							<ButtonComponent
								variant='outlined'
								btnName='Cancel'
								onClick={handleCancel}
							/>
							<ButtonComponent
								type='submit'
								variant='contained'
								btnName='Create'
								loading={isLoading}
							/>
						</Box>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default CreateNewFolderComponent;
