import axios from 'axios';
import { ApiUrl } from '../utils/config';
import { type CreateParamsType } from '../modals';

const createSchedule = (body: CreateParamsType): any => {
    const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
    return axios.post(ApiUrl + 'schedule/addV2', body, axiosConfig);
};

const getSchedule = ({ clientId=0 }): any => {
    const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
    return axios.get(ApiUrl + `schedule/?clientId=${clientId}`, axiosConfig);
};

const getScheduleV2 = (body: any): any => {
    const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
    return axios.post(ApiUrl + 'schedule/V2', body,  axiosConfig);
};

const getScheduleId = (id: any): any => {
    const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
    return axios.get(ApiUrl + `schedule/${Number(id)}`, axiosConfig);
};

const editSchedule = (id: any, body: any): any => {
    const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
    return axios.put(ApiUrl + `schedule/${Number(id)}`, body, axiosConfig);
};

const deleteSchedule = (id: any): any => {
    const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
    return axios.delete(ApiUrl + `schedule/${Number(id)}`, axiosConfig);
};

export const scheduleService = {
    createSchedule,
    getSchedule,
    editSchedule,
	getScheduleId,
	getScheduleV2,
	deleteSchedule,
};
