/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-case-declarations */
import { useEffect, useState, type FC } from 'react';
import { Box } from '@mui/material';
import CrossFadeComponent from '../../components/CrossFadeComponent';
import { handleAddCrossFade, handleGetCrossFade } from '../../redux/actions/crossFadeAction';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { CrossFadePage } from './style';

// ------------------------------------------------

const CrossFade: FC = () => {
	const dispatch = useAppDispatch();
	const { crossFadeData } = useAppSelector((state) => state.crossFade);
	const [defaultCrossFade, setDefaultCrossFade] = useState<any>({ startDuration: 0, endDuration: 0 });

	useEffect(() => {
		void dispatch(handleGetCrossFade({}));
	}, []);

	useEffect(() => {
		setDefaultCrossFade(crossFadeData);
	}, [crossFadeData]);

	const buttonHandler = (start: number, end: number): void => {
		const data = {
			startDuration: start,
			endDuration: end
		};
		void dispatch(handleAddCrossFade(data));
	};

	return (
		<CrossFadePage>
			<Box sx={{ mt: 5, width: '50%' }} >
				<CrossFadeComponent
					crossfadeStartsTime={defaultCrossFade?.startDuration}
					crossfadeDuration={defaultCrossFade?.endDuration}
					onUpdateButtonHandler={buttonHandler}
				/>
			</Box>
		</CrossFadePage>
	);
};

export default CrossFade;