/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable camelcase */

import { createTheme, darken, ThemeProvider, useTheme } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import {
	useMemo,
	type FC,
	useEffect,
	useLayoutEffect,
	useCallback,
	useRef,
	useState,
	memo
} from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
interface TableInterface {
	columns?: any;
	data?: any;
	stateVar?: any;
	rowId?: any;
	onPlayPause?: any;
	getRowId?: any;
	setRowSelection: (e: any) => void;
	rowSelection: any;
	setId?: any;
	tableInstanceRef?: any;
	setSelectedRows?: any;
	alignment?: any;
	page?: any;
	playlistId?: boolean;
	sorting: any;
	setSorting: any;
	pagination?: any;
	sortDirection?: string;
	orderBy?: string;
	searchInput?: string;
	isFromSong?: boolean;
	enableRowNumbers?: boolean;
	dataLength?: number;
}

const DataTable: FC<TableInterface> = ({
	columns,
	data,
	stateVar,
	onPlayPause,
	getRowId,
	rowId,
	setRowSelection,
	rowSelection,
	setId,
	tableInstanceRef,
	setSelectedRows,
	alignment,
	page,
	playlistId,
	sorting,
	setSorting,
	pagination,
	sortDirection,
	orderBy,
	searchInput,
	isFromSong = false,
	enableRowNumbers = false,
	dataLength = 99
}) => {
	useEffect(() => {
		onPlayPause(stateVar);
		getRowId(rowId);
	}, [stateVar, rowId]);
	const columnsTitle = useMemo<MRT_ColumnDef<any>[]>(
		() => columns,
		[
			stateVar,
			alignment,
			page,
			playlistId,
			sortDirection,
			orderBy,
			searchInput,
			rowId
		]
	);
	const globalTheme = useTheme();
	const tableTheme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: globalTheme.palette.mode,
					primary: globalTheme.palette.secondary,
					info: {
						main: 'rgb(0,0,0)'
					},
					background: {
						default: globalTheme.palette.mode === 'light' ? '#FFC5B2' : '#000'
					}
				},
				typography: {
					button: {
						textTransform: 'none',
						fontSize: '14px'
					}
				},
				components: {
					MuiTooltip: {
						styleOverrides: {
							tooltip: {
								fontSize: '14px'
							}
						}
					},
					MuiTableRow: {
						styleOverrides: {
							root: {
								'&.Mui-selected': {
									backgroundColor: 'rgb(192 106 100) !important'
								}
							}
						}
					}
				}
			}),
		[globalTheme]
	);

	const useKeyPress = (
		keys: any,
		callback: (event: any) => void,
		node = null
	) => {
		const callbackRef = useRef(callback);
		useLayoutEffect(() => {
			callbackRef.current = callback;
		});

		const handleKeyPress = useCallback(
			(event: { key: any }) => {
				if (keys.some((key: any) => event.key === key)) {
					callbackRef.current(event);
				}
			},
			[keys]
		);

		useEffect(() => {
			const targetNode = node ?? document;
			targetNode && targetNode.addEventListener('keydown', handleKeyPress);
			return () => {
				targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
			};
		}, [handleKeyPress, node]);
	};

	const shouldRemove = useRef(true);
	const shouldRemoveDown = useRef(true);

	const [currentRowIds, setCurrentRowIds] = useState<number[]>([]);

	const getIndexV2 = (num: number) => {
		if (isFromSong && alignment === 'all') {
			return num - (page - 1) * pagination?.pageSize;
		} else {
			return num;
		}
	};
	const getIndex = () => {
		if (isFromSong && alignment === 'all') {
			return (page - 1) * pagination?.pageSize;
		} else {
			return 0;
		}
	};

	const onKeyPress = (event: any) => {
		if (event.key === 'ArrowUp') {
			const keys = Object.keys(rowSelection);
			const lastKey = keys[keys.length - 1];

			if (event.shiftKey && shouldRemove.current && keys.length > 1) {
				const currentSelectedRows = { ...rowSelection };
				delete currentSelectedRows[lastKey];
				const currentRowIdsV2 = currentRowIds;
				currentRowIdsV2.pop();
				setCurrentRowIds(currentRowIdsV2);
				setRowSelection(currentSelectedRows);
				setSelectedRows(
					(state: any) =>
						state.filter((e: any) => e !== data[getIndexV2(Number(lastKey))])
					// state.filter((e: any) => e !== data[lastKey]?.id)
				);
				return;
			}

			if (event.shiftKey && parseInt(keys[0]) > getIndex()) {
				// if (event.shiftKey && parseInt(keys[0]) > 0) {
				shouldRemove.current = false;
				shouldRemoveDown.current = true;
				const newKey = parseInt(keys[0]) - 1;
				setRowSelection((state: any) => ({ ...state, [newKey]: true }));
				setSelectedRows((state: any) => [...state, data[getIndexV2(newKey)]]);
				// setSelectedRows((state: any) => [...state, data[newKey]?.id]);
				setCurrentRowIds((state: any) => [...state, newKey]);
				return;
			}

			if (!event.shiftKey && parseInt(lastKey) > getIndex()) {
				// if (!event.shiftKey && parseInt(lastKey) > 0) {
				const newKey = parseInt(lastKey) - 1;
				setRowSelection({ [newKey]: true });
				setSelectedRows(() => [data[getIndexV2(newKey)]]);
				// setSelectedRows(() => [data[newKey]?.id]);
				setCurrentRowIds([Number(newKey)]);
			}
		} else if (event.shiftKey && event.key === 'ArrowDown') {
			const keys = Object.keys(rowSelection);
			const firstKey = keys[0];
			const lastKey = keys[keys.length - 1];

			if (shouldRemoveDown.current && keys.length > 1) {
				const currentSelectedRows = { ...rowSelection };
				delete currentSelectedRows[firstKey];
				setRowSelection(currentSelectedRows);
				setSelectedRows((state: any) =>
					state.filter((e: any) => e !== data[getIndexV2(Number(firstKey))])
				);
				const currentRowIdsV2 = currentRowIds;
				currentRowIdsV2.pop();
				setCurrentRowIds(currentRowIdsV2);
				return;
			}

			if (Object.keys(rowSelection).length !== 0) {
				shouldRemoveDown.current = false;
				shouldRemove.current = true;
				if (parseInt(lastKey) < dataLength) {
					const newKey = parseInt(lastKey) + 1;
					setRowSelection((state: any) => ({ ...state, [newKey]: true }));
					setSelectedRows((state: any) => [...state, data[getIndexV2(newKey)]]);
					// setSelectedRows((state: any) => [...state, data[newKey]?.id]);
					setCurrentRowIds((state: any) => [...state, Number(newKey)]);
				}
			}
		} else if (event.key === 'ArrowDown') {
			if (Object.keys(rowSelection).length !== 0) {
				const keys = Object.keys(rowSelection);
				const lastKey = keys[keys.length - 1];
				if (parseInt(lastKey) < dataLength) {
					const newKey = parseInt(lastKey) + 1;
					setRowSelection({ [newKey]: true });
					setSelectedRows(() => [data[getIndexV2(newKey)]]);
					// setSelectedRows(() => [data[(newKey)]?.id]);
					setCurrentRowIds([Number(newKey)]);
				}
			}
		}
	};

	useKeyPress(['ArrowUp', 'ArrowDown'], onKeyPress);

	const [lastClickedIndex, setLastClickedIndex] = useState(null);

	const handleItemToggle = (itemId: any) => {
		if (lastClickedIndex !== null) {
			const minIndex = Math.min(lastClickedIndex, itemId);
			const maxIndex = Math.max(lastClickedIndex, itemId);
			const newSelectedItems = data?.slice(
				isFromSong && alignment === 'all'
					? minIndex - (page - 1) * pagination?.pageSize
					: minIndex,
				isFromSong && alignment === 'all'
					? maxIndex - (page - 1) * pagination?.pageSize + 1
					: maxIndex + 1
			);
			const obj: any = {};
			for (let i = minIndex; i <= maxIndex; i++) {
				obj[i] = true;
			}
			setSelectedRows(newSelectedItems);
			setRowSelection(obj);
			setCurrentRowIds([Number(itemId)]);
		}
	};

	const onRowClickV2 = (event: any, row: any) => {
		const isRowSelected = rowSelection[row.id];
		if (event.shiftKey) {
			if (lastClickedIndex === null) {
				setLastClickedIndex(row.id);
			}
			handleItemToggle(row.id);
		} else {
			if (isRowSelected) {
				setRowSelection({});
				setSelectedRows([]);
				setCurrentRowIds([0]);
				setLastClickedIndex(null);
				return;
			}

			setSelectedRows([row.original]);
			setRowSelection((state: any) => ({ [row.id]: !state[row.id] }));
			setLastClickedIndex(row.id);

			if (!isRowSelected) {
				setCurrentRowIds([Number(row.id)]);
			}
		}
	};

	useEffect(() => {
		setId(() => {
			if (currentRowIds?.length > 0) {
				return currentRowIds[currentRowIds?.length - 1];
			}
			return 0;
		});
	}, [rowSelection]);

	useEffect(() => {
		setLastClickedIndex(null);
	}, [page]);

	const handleChange = (row: any, table: any) => {
		row.toggleSelected();
		setCurrentRowIds((state: any) => {
			if (row.getIsSelected()) {
				return state.filter((e: any) => e !== Number(row?.id));
			} else {
				return [...state, Number(row.id)];
			}
		});
		if (row.getIsSelected()) {
			setSelectedRows((state: any) =>
				state?.filter((e: any) => e !== row.original)
			);
		} else {
			setSelectedRows((state: any) => {
				return [...state, row.original];
			});
		}
	};

	const handleAllRowChange = (table: any) => {
		table.toggleAllPageRowsSelected();
		if (table.getIsAllPageRowsSelected()) {
			setSelectedRows((state: any) =>
				state?.filter((e: any) => !data?.map((e: any) => e.id).includes(e?.id))
			);
		} else {
			setSelectedRows(() => [...data]);
		}
	};

	return (
		<ThemeProvider theme={tableTheme}>
			<MaterialReactTable
				columns={columnsTitle}
				data={data}
				onRowSelectionChange={setRowSelection}
				state={
					isFromSong && alignment === 'all'
						? { rowSelection, sorting, pagination }
						: { rowSelection, sorting }
				}
				manualPagination
				enableRowVirtualization={true}
				rowVirtualizerProps={{ overscan: 40 }}
				enableColumnResizing
				layoutMode='grid'
				enableColumnOrdering={false}
				enableGrouping
				enablePinning={false}
				manualSorting
				onSortingChange={setSorting}
				muiTableHeadCellProps={{
					sx: () => ({
						flex: '0 0 auto',
						fontSize: '16px',
						svg: {
							fill: 'black'
						}
					})
				}}
				muiTableContainerProps={{
					sx: { maxHeight: '700px' }
				}}
				enableTopToolbar={false}
				enableClickToCopy={false}
				enableColumnDragging={false}
				enableEditing={false}
				enablePagination={isFromSong && alignment === 'all'}
				enableGlobalFilter={false}
				enableHiding={false}
				enableColumnActions={false}
				enableFilters={false}
				enableStickyHeader={false}
				enableDensityToggle={false}
				enableFullScreenToggle={false}
				enableColumnFilters={false}
				enableBottomToolbar={false}
				enableMultiRowSelection
				enableRowNumbers={enableRowNumbers}
				enableRowSelection
				muiTableProps={{
					sx: {
						tableLayout: 'fixed'
					}
				}}
				muiTableBodyCellProps={{
					sx: {
						flex: '0 0 auto',
						padding: '5px 12px',
						borderBottom: '1px solid #000',
						fontSize: 14
					}
				}}
				muiTablePaperProps={{
					elevation: 0,
					sx: {
						borderRadius: '10px',
						border: '1px solid black',
						overflow: 'hidden'
					}
				}}
				getRowId={(originalRow, index) => {
					if (isFromSong && alignment === 'all') {
						return String(Number(index) + (page - 1) * pagination?.pageSize);
					} else {
						return String(Number(index));
					}
				}}
				muiSelectAllCheckboxProps={({ table }) => ({
					onChange: () => {
						handleAllRowChange(table);
					},
					icon: <RadioButtonUncheckedIcon />,
					checkedIcon: <CheckCircleOutlineIcon />,
					indeterminateIcon: <RemoveCircleOutlinedIcon />
				})}
				muiSelectCheckboxProps={({ row, table }) => ({
					onChange: () => {
						handleChange(row, table);
					},
					color: 'default',
					icon: <RadioButtonUncheckedIcon />,
					checkedIcon: <CheckCircleOutlineIcon />,
					align: 'center'
				})}
				muiTableBodyProps={{
					sx: () => ({
						'& tr:nth-of-type(odd)': {
							backgroundColor: darken('#FFC5B2', 0.1)
						}
					})
				}}
				muiTableBodyRowProps={({ row }) => ({
					onClick: (event) => {
						onRowClickV2(event, row);
					},
					sx: {
						cursor: 'pointer'
					}
				})}
				tableInstanceRef={tableInstanceRef}
				memoMode='cells'
			/>
		</ThemeProvider>
	);
};

export default memo(DataTable);
