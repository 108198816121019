/* eslint-disable camelcase */
import React, {
	useState,
	type FC,
	type ReactElement,
	memo,
	useEffect,
	useMemo
} from 'react';
import {
	Box,
	Chip,
	Dialog,
	DialogContent,
	IconButton,
	Stack,
	Typography,
	createTheme,
	ThemeProvider,
	useTheme,
	CircularProgress,
	Tooltip
} from '@mui/material';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import {
	SongModal,
	SongModalBody,
	SongModalHeader
} from '../pages/songs/style';
import CloseIcon from '@mui/icons-material/Close';
import {
	handleDeleteBulkSongsFromPlaylist,
	handleGetPlaylistSongs
} from '../redux/actions/playlistSongAction';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import AudioPlayer from './AudioPlayer/AudioPlayer';
import {
	setPlay,
	setSelectedSong,
	isPlayed
} from '../redux/slice/selectedSong';
import { setPlaylistSongs } from '../redux/slice/playlistSongSlice';
import PlayIcon from '../assets/images/icons/play-button-icon.svg';
import PauseIcon from '../assets/images/icons/pause-button-icon.svg';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { setLoadingState } from '../redux/slice/loadingSlice';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Confirmation from './ConfirmationModalComponent';
import TimeDuration from '../pages/playlist/TimeDuration';
import { totalTime } from '../utils/getTotalTime';

interface PreviewButtonProps {
	id: any;
}

const PreviewButton: FC<PreviewButtonProps> = ({ id }) => {
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useAppDispatch();
	const isPlay: boolean = useAppSelector(isPlayed);

	const { playlistSongs } = useAppSelector((state) => state.playlistSong);

	const handleClick = (): void => {
		setOpenModal(true);
		dispatch(setLoadingState(true));
		void dispatch(
			handleGetPlaylistSongs({
				playlistId: id
			})
		);
	};

	useEffect(() => {
		setIsPlaying(isPlay);
		return () => {
			setPlay(false);
		};
	}, [isPlay]);

	const handleCloseModal = (): void => {
		setOpenModal(false);
		setSongItem(null);
		dispatch(setSelectedSong(undefined));
		dispatch(setPlaylistSongs([]));
		dispatch(setPlay(false));
	};

	const [songItem, setSongItem] = useState<any>(null);
	const [rowId, setRowId] = useState(1);
	const [isPlaying, setIsPlaying] = useState<boolean>(false);

	const togglePlayPause = (prev: any, id: any, row: any): void => {
		setSongItem(row);
		dispatch(setSelectedSong(row));
		dispatch(setPlay(true));
		setRowId(id);
		if (rowId === id) {
			setIsPlaying((prev) => !prev);
			dispatch(setPlay(!isPlay));
		} else {
			setIsPlaying(true);
			dispatch(setPlay(true));
		}
	};

	const _songController = (): ReactElement => {
		return (
			<Box
				alignContent={'center'}
				width={'100%'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<AudioPlayer />
			</Box>
		);
	};

	const _renderSongController = (): ReactElement | undefined => {
		if (songItem !== (null ?? undefined)) {
			return _songController();
		}
	};

	const minSize = 20;
	const maxSize = 10000;

	const [isOpenConfirmation, setConfirmation] = useState<boolean>(false);
	const [currentSelectedSongId, setCurrentSelectedSongId] = useState<number[]>(
		[]
	);

	const handleSelectSong = (id: number, songId: number): void => {
		setConfirmation(true);
		setCurrentSelectedSongId([songId]);
	};

	const columns: any = [
		{
			accessorFn: (row: string) => `${row} ${row}`,
			id: 'id',
			header: '#',
			minSize,
			size: 30,
			maxSize,
			enableSorting: false,
			enableResizing: false,
			muiTableHeadCellProps: {
				align: 'center'
			},
			Cell: ({ row }: any) => {
				return (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '0.2rem',
							lineHeight: 'normal'
						}}
					>
						<Box>
							<IconButton
								onClick={(e: any) => {
									e.stopPropagation();
									togglePlayPause(false, Number(row?.id), row?.original?.song);
								}}
								className='playIcon'
								style={{ padding: '0', position: 'relative', top: '-1px', margin: 0 }}
							>
								{rowId === Number(row?.id) && isPlaying ? (
									<img style={{ width: '18px' }} src={PauseIcon} alt='' />
								) : (
									<img src={PlayIcon} alt='' />
								)}
							</IconButton>
						</Box>
					</Box>
				);
			}
		},
		{
			accessorKey: 'song.artist',
			header: 'Artist',
			minSize,
			size: 160,
			maxSize
		},
		{
			accessorKey: 'song.title',
			header: 'Title',
			minSize,
			size: 160,
			maxSize
		},
		{
			accessorFn: (row: string) => `${row} ${row}`,
			id: 'tags',
			header: 'Tags',
			minSize,
			size: 160,
			maxSize,
			enableSorting: false,
			Cell: ({ row }: any) => (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',

						gap: '1rem'
					}}
				>
					<Stack direction='row' spacing={1}>
						{row?.original?.song?.tags?.map(
							(
								itemName:
									| string
									| number
									| boolean
									| React.ReactElement<any, string | React.JSXElementConstructor<any>>
									| React.ReactFragment
									| React.ReactPortal
									| null
									| undefined,
								index: React.Key | null | undefined
							) => (
								<Chip sx={{ background: '#F2A892' }} key={index} label={itemName} />
							)
						)}
					</Stack>
				</Box>
			)
		},
		{
			accessorFn: (row: string) => `${row} ${row}`,
			id: 'actions',
			header: '#',
			minSize,
			size: 30,
			maxSize,
			enableSorting: false,
			Cell: ({ row }: any) => (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '1rem'
					}}
				>
					<div className='btn-wrapper'>
						<Tooltip title='Remove from playlist' arrow>
							<IconButton
								className='remove-btn table-button'
								onClick={(e: any) => {
									e.stopPropagation();
									handleSelectSong(
										row?.original?.song?.id,
										Number(row?.original?.song?.id)
									);
								}}
							>
								<RemoveCircleOutlineIcon />
							</IconButton>
						</Tooltip>
					</div>
				</Box>
			)
		}
	];

	const columnsTitle = useMemo<Array<MRT_ColumnDef<any>>>(
		() => columns,
		[isPlaying, isPlay, rowId]
	);
	const globalTheme = useTheme();
	const tableTheme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: globalTheme.palette.mode,
					primary: globalTheme.palette.secondary,
					info: {
						main: 'rgb(0,0,0)'
					},
					background: {
						default: globalTheme.palette.mode === 'light' ? '#FFC5B2' : '#000'
					}
				},
				typography: {
					button: {
						textTransform: 'none',
						fontSize: '14px'
					}
				},
				components: {
					MuiTooltip: {
						styleOverrides: {
							tooltip: {
								fontSize: '14px'
							}
						}
					},
					MuiTableRow: {
						styleOverrides: {
							root: {
								'&.Mui-selected': {
									backgroundColor: 'rgb(142, 39, 52, 0.3)'
								}
							}
						}
					}
				}
			}),
		[globalTheme]
	);

	const handleCloseConfirmation = (): void => {
		setConfirmation(false);
	};

	const handleDeleteSongConfirm = (): void => {
		const data = {
			ids: currentSelectedSongId.toString()
		};
		dispatch(setLoadingState(true));
		void dispatch(
			handleDeleteBulkSongsFromPlaylist({
				body: data,
				playlistId: id
			})
		);
		setConfirmation(false);
	};

	let totalSize: number = 0;
	const getTotalDuration = (data: any): any => {
		if (data?.length > 0) {
			const arr: string[] = data.map((item: { duration: any; size: number }) => {
				totalSize = totalSize + item?.size;
				return item?.duration;
			});
			if (arr?.length > 0) {
				return totalTime(arr);
			}
			return [];
		}
		return [];
	};

	const { isLoading } = useAppSelector((state) => state.loading);

	return (
		<>
			<IconButton
				aria-label='Preview'
				onClick={handleClick}
				className='preview-botton'
			>
				<ManageSearchIcon />
			</IconButton>
			<Dialog
				open={openModal}
				onClose={handleCloseModal}
				fullWidth={true}
				maxWidth={'sm'}
				className='client-dialog-box previewbuttonmodal'
			>
				<SongModal>
					<DialogContent>
						<SongModal>
							<Box sx={{ display: 'flex' }}>
								<SongModalHeader>
									<Typography variant='h5'>Preview Playlist</Typography>
									<Box sx={{ display: 'flex' }}>
										{playlistSongs?.length > 0 && (
											<Box className='playlist-time'>
												<TimeDuration
													totalSongs={playlistSongs?.length}
													totalDuration={getTotalDuration(
														playlistSongs?.map((e: any) => e?.song)
													)}
													totalSize={totalSize}
												/>
											</Box>
										)}
										<IconButton
											aria-label='close'
											onClick={handleCloseModal}
											className='popup-close-btn'
										>
											<CloseIcon />
										</IconButton>
									</Box>
								</SongModalHeader>
							</Box>
							<SongModalBody
								className={`previewButtonContent ${
									playlistSongs?.length === 0 ? 'empty-playlist' : ''
								}`}
							>
								{_renderSongController()}
								{isLoading ? (
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										<CircularProgress sx={{ color: 'var(--primary)' }} size={50} />
									</Box>
								) : (
									<>
										<ThemeProvider theme={tableTheme}>
											<MaterialReactTable
												columns={columnsTitle}
												data={playlistSongs}
												enableColumnResizing
												enableColumnOrdering={false}
												enableGrouping
												enablePinning={false}
												muiTableHeadCellProps={{
													sx: () => ({
														flex: '0 0 auto',
														fontSize: '16px',
														svg: {
															fill: 'black'
														}
													})
												}}
												muiTableContainerProps={{
													sx: { maxHeight: 'calc(100vh - 150px)' }
												}}
												enableTopToolbar={false}
												enableClickToCopy={false}
												enableColumnDragging={false}
												enableEditing={false}
												enablePagination={false}
												enableGlobalFilter={false}
												enableHiding={false}
												enableColumnActions={false}
												enableFilters={false}
												enableStickyHeader={false}
												enableDensityToggle={false}
												enableFullScreenToggle={false}
												enableColumnFilters={false}
												enableBottomToolbar={false}
												enableRowNumbers={false}
												muiTableProps={{
													sx: {
														tableLayout: 'fixed'
													}
												}}
												muiTableBodyCellProps={{
													sx: {
														flex: '0 0 auto',
														padding: '5px 12px',
														borderBottom: '1px solid #000',
														fontSize: 14
													}
												}}
												muiTablePaperProps={{
													elevation: 0,
													sx: {
														borderRadius: '10px',
														border: '1px solid black',
														overflow: 'hidden'
													}
												}}
											/>
										</ThemeProvider>
									</>
								)}
							</SongModalBody>
						</SongModal>
					</DialogContent>
				</SongModal>
			</Dialog>
			<Dialog
				open={isOpenConfirmation}
				onClose={handleCloseConfirmation}
				aria-labelledby='responsive-dialog-title'
			>
				<Confirmation
					setConfirmation={setConfirmation}
					handleConfirmation={handleDeleteSongConfirm}
					message={'Song'}
				/>
			</Dialog>
		</>
	);
};

export default memo(PreviewButton);
