import styled from 'styled-components';
import '../../style/main.scss';
import TopBottomIcon from '../../assets/images/icons/top-bottom-arrows-icon.svg';

export const CalendarModal = styled.div`
  height : auto;
  padding:0px;
  overflow: hidden;
  max-height: 100%;
  &.calender-modal {
    .bottom-buttons {
      display: flex;
      align-items: center;
      top: 5px;
      .calendar-hours {
        p {
          font-size: 16px;
        }
      }
    }
    .schedule-header {
      display: flex;
      align-items: center;
      .two-col-grid {
        display: flex;
        align-items: center;
        margin-left: 7px;
        margin-top: 1px;
        h6 {
          font-size: 14px;
          line-height: normal;
        }
        button {
          width: 35px;
          height: 35px;
          img {
            transform: scale(1.15, 1.1);
            & + img {
              position: absolute;
              opacity: 0;
            }
          }
        }
        .sync-alt-icon {
          &.active {
            img {
              & + img {
                opacity: 1;
              }
            }
          }
        }
      }
    }    
  }

  .MuiDialogContent-root{
    padding:0px;
  } 
  .MuiSwitch-sizeMedium{
    transform: scale(1.3) !important;
    transform-origin: top left !important;
  }
  .song-list-calender{
    width: calc(100% - 115px);
    padding: 10px 20px 10px 30px;
    overflow: auto;
    margin: -10px 0px 20px 115px;
    background-color: var(--darkerBgColor);
    border-radius: 10px;
    height : 106px;
  }
  .song-list-calender h6 {
    font-size: 14px;
    display: list-item;
  }
  .bottom-buttons {
    padding: 10px 0px 10px 10px;
    position: absolute;
    right: 10px;
    top: 0px;
  }
  .occurrences {
      display: flex;
      align-items: center;
      p {
          margin-top: 5px;
      }
  }
  .bottom-buttons button {
    margin-left: 10px;
    width: 80px;
  }
  .bottom-buttons button.delete-btn{
    min-width: 40px;
    width: 40px;
    padding: 5px 8px !important;
  }
  .flexbox-inline .flex-inner-margin .calendar-hours {
    position: absolute;
    left: 100%;
    top: 7px;
  }
  .flexbox-inline .flex-inner-margin .calendar-hours p {
    font-size: 12px;
    margin-top: 7px;
  }
`;

export const CalendarModalHeader = styled.div`
  padding:15px 10px 15px 20px;
  background-color : var(--darkerBgColor);
  display:flex;
  justify-content:space-between;
  align-items:center;

  h5{
    margin-bottom: 0px;
    font-size:18px;
    font-family: "medium-fonts" !important;
  }
  .popup-close-btn{
    position: absolute;
    right: 8px;
    top: 3px;
    color: var(--primary);
  }
`;

export const CalendarModalBody = styled.div`
  padding: 0px;
  background-color : var(--bgColor);
  
  .search-field{
    background-color: var(--primary);
    border-radius:10px;
    width:100%;
    margin-bottom:10px;
  }
  input.MuiOutlinedInput-input{
    color:var(--white) !important;
    font-size:14px;
  }
  .model-buttons{
    width:100%;
    margin-top: 20px;
    display: flex;
    justify-content:flex-end;
  }
  button{
    width:105px;
    margin-left: 20px;
    text-transform: capitalize !important;
  }
  button {
    &.preview-botton {
      width: auto;
      background-color: #8E2734;
      border-radius: 10px;
      margin-left: 0;
      svg {
        fill: #fff;
      }
    }
  }
  .MuiDivider-fullWidth{
    border-color:var(--primary);
  }
  .MuiCalendarPicker-root .MuiDayPicker-header {
    border-bottom: none !important;
    margin-bottom: 0 !important;
    margin-top: 0px !important;
  } 
  .MuiBox-root label {
    margin-right: 10px;
  }
`;

export const CalendarWrapper = styled.div`
  .fc-toolbar-title {
    display: inline-block;
  }
`;

export const CalendarInnerWrapper = styled.div`
  padding: 20px 100px 0px 20px;
  overflow: auto;
  max-height : 550px;

  h6{
    font-size:16px;
    min-width: 115px;
  }
  
  
  .two-col-grid{
    display: grid !important;
    grid-template-columns: 115px 5fr;
    position: relative;
    margin-bottom:15px;
    .MuiBox-root label + .MuiFormControl-root {
      margin-right: 20px !important;
    }
    &.end-radio-btn {
      label.MuiFormControlLabel-root {
          width: 95px;
      }
    }
    [role="radiogroup"]{
      label.MuiFormControlLabel-root {
        margin-bottom: 0;
      }
      > *{
        margin-bottom:15px;
        
        svg path{
          fill: var(--swichSuccess);
        }
      }

      input{
        width: 80px;
      }
      button{
        width: 40px;
        background-color: var(--swichSuccess);
        border-radius: 0px 10px 10px 0px;
        margin-left: 5px;
        margin-right: -15px;

        &:hover{
          transition-duration:.3s;
          background-color: var(--primary);
        }
      }
    }
    label{
      width: auto;
    }
   
    .MuiFormControl-root + h6 {
      width:max-content;
      margin-left: 100px;
    }

    input.MuiOutlinedInput-input{
      background-color:var(--darkerBgColor) !important;
      color: var(--primary) !important;
      font-size:14px;
      padding:10px 20px;
      border-radius:10px;
    }
    .MuiOutlinedInput-root{
      background-color:var(--darkerBgColor) !important;
      color: var(--primary) !important;
      font-size:14px;

      .MuiSelect-select{
        padding:10px 20px;
        position: relative;

        &:after{
          content: '';
          display: block;
          position:absolute;
          top: 0;
          right: 0;
          width: 23px;
          height: 100%;
          background-image:url(${TopBottomIcon});
          background-color:var(--swichSuccess) !important;
          background-repeat:no-repeat !important;
          background-position:center;
          z-index:11;
          border-radius: 0 10px 10px 0;

          &:hover:after{
            transition-duration:.3s;
            background-color: var(--primary);
          }  
        }
      }
      svg {
        width: 22px;
        path{
          fill: var(--white);
        }
      }
    }
    .sync-alt-icon {
      width: 35px;
      height: 35px;
      margin-left:-7px;
      margin-top:-5px;

      img {
        transform: scale(1.15, 1.1);
      }
      
      img + img {
        position:absolute;
        opacity:0;
      }

      &.active {
        img + img {
          opacity: 1;
        }
      }
    }
    .absolute-selector {
      position: absolute;
      right: -60px;
      width:40px;
      height:40px;
      border-radius: 10px;

      &.sync-alt-icon {
        border: solid 1px var(--swichSuccess);
        width: 20px;
        background-color: var(--darkerBgColor);

        &.active{
          background-color: var(--swichSuccess);
          border: solid 1px var(--swichSuccess);

          img{
            filter: brightness(0) invert(1);
          }
        }
        &:hover{
          transition-duration:.3s;
          // background-color: var(--swichSuccess);
          border: solid 1px var(--swichSuccess);

          // img{
          //   filter: brightness(0) invert(1);
          // }
        }
      } 
    }
   
  }
  .flexbox-inline{
    display: flex !important;
    flex-direaction: row !important;
    margin-bottom:20px;
    
    .flex-inner-margin .btn-inner-custom {
      background: var(--swichSuccess);
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0px;
      border-radius: 0px 10px 10px 0px;
    }
    
    .flex-inner-margin .btn-inner-custom svg {
      fill: #fff;
    }

    .form-inner-width{
      width: 340px;
    }

    .form-inner-width p.MuiTypography-root{
      display: inline-block;
      width:100%;
      margin-left:0px;
      font-size: 12px;
    }

    .edge-width + p.MuiTypography-root{
      margin-left:0px;
      font-size: 12px;
    }
    .mt-top{
      margin-top: 10px;
      display:inline-block;
    }
    .flex-inner-margin{
      margin-left: 15px;
      align-items: strach;
      position : relative;

      button {
        pointer-events: none;
      }

      &.small{
        margin-left: 10px;
      }
    }
    p.MuiTypography-root{
      min-width: max-content;
      margin-left: 15px;
      font-size: 14px;
    }
    .MuiInputBase-formControl{
      background-color: var(--darkerBgColor) !important;

      input{
        padding:10px 0px 10px 10px;
        color: var(--primary) !important;
        -webkit-text-fill-color: var(--primary) !important;
      }
      button{
        width: 40px;
        background-color: var(--swichSuccess);
        border-radius:0px 10px 10px 0px;
        margin-left:0px;
        margin-right:-15px;

        &:hover{
          transition-duration:.3s;
          background-color: var(--primary);
        }

        svg {
          width: 22px;
          path{
            fill: var(--white);
          }
        }
      }
    }

    h6{
      min-width: 115px;
    }
  }
`;    