import React, { useState, type FC, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	TextField,
	Box,
	InputAdornment,
	Dialog,
	DialogContent,
	// IconButton,
	// Typography,
} from '@mui/material';
import ButtonComponent from '../../components/ButtonComponent';
import ClientDeleteModal from './ClientDeleteModal';
import ClientForm from './ClientForm';
import {
	handleGetClientList,
	handleDeleteClient
} from '../../redux/actions/clientAction';
import {
	type ClientFormInputInterface,
	type SearchInputInterface
} from '../../modals';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
	ClientWrapper,
	ClientDialogBox,
	ClientList,
	ClientItem
} from './style';
import { PrefixUrl } from '../../utils/config';
import { setLoadingState } from '../../redux/slice/loadingSlice';
import BtnAddIcon from '../../assets/images/icons/plus-btn-icon.svg';
import searchIcon from '../../assets/images/icons/search.svg';
// import editNoteIcon from '../../assets/images/icons/edit-note-icon.svg';
// import deleteIcon from '../../assets/images/icons/delete-icon.svg';
// import streamIcon from '../../assets/images/icons/icons8-stream.svg';
import imageIcon from '../../assets/images/favicon-icon.svg';
import { useDebounce } from '../../utils/useDebounce';
import { isEmpty } from '../../utils/validators';

const Client: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { clientList } = useAppSelector((state) => state.client);
	const { isLoading } = useAppSelector((state) => state.loading);

	const [isOpenDialog, setOpenMode] = useState<boolean>(false);
	const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
	const [preFillData, setPreFillData] = useState<ClientFormInputInterface>({});
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [searchInput, setSearchInput] = useState<SearchInputInterface>('');
	const currentStatusRef = useRef();

	useEffect(() => {
		void dispatch(
			handleGetClientList({ search: searchInput?.trim(), type: 'all' })
		);
	}, []);

	useEffect(() => {
		setOpenMode(false);
	}, [clientList]);

	const handleClose = (): void => {
		setOpenMode(false);
	};

	const addClientModalOpen = (data: ClientFormInputInterface): void => {
		setPreFillData(data);
		setOpenMode(true);
	};

	const getStreamStatusColor = (value: string): string => {
		switch (value) {
			case 'NOT-STARTED':
				return 'start';
			case 'ACTIVE':
				return 'active';
			case 'STOP':
				return 'stop';
			case 'DISABLED':
				return 'stop';
			default:
				return 'start';
		}
	};

	const getStreamButtonName = (value: string): string => {
		switch (value) {
			case 'NOT-STARTED':
				return 'Streaming not start';
			case 'ACTIVE':
				return 'Streaming active';
			case 'STOP':
				return 'Streaming deactivate';
			case 'DISABLED':
				return 'Streaming deactivate';
			default:
				return 'Streaming not start';
		}
	};

	const handleSearch = (e: any): void => {
		void dispatch(
			handleGetClientList({ search: e.target.value?.trim(), type: 'all' })
		);
	};

	const onSearchDebounce = useDebounce(handleSearch, 500);

	return (
		<>
			<ClientWrapper>
				<Box className='search-wrapper'>
					<TextField
						type='text'
						onChange={(e) => {
							setSearchInput(e.target.value);
							onSearchDebounce(e);
						}}
						placeholder='Search'
						variant='outlined'
						size='small'
						className='search-field'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<img src={searchIcon} alt='' />
								</InputAdornment>
							)
						}}
					/>
					<ButtonComponent
						type='button'
						btnName='Add'
						variant='contained'
						startIcon={<img src={BtnAddIcon} alt='' />}
						onClick={() => {
							addClientModalOpen({});
							setIsEdit(false);
						}}
					/>
				</Box>
				<ClientList>
					{clientList.length > 0 ? (
						clientList?.map((row) => {
							return (
								<ClientItem key={row.id}>
									<div className={`album-image ${ isEmpty(row.logo) ? 'placeholder' : ''}`}>
										<img
											src={ !isEmpty(row.logo) ? `${String(PrefixUrl)}${String(row.logo)}` : imageIcon}
											alt=''
											onClick={() => {
												row.isDisable !== true && navigate('/calendar', { state: row });
											}}
										/>
										<ButtonComponent
											type='button'
											sx={{ background: '#F2A892' }}
											btnName={row.isDisable ?? false ? 'Inactive' : 'Active'}
											variant='contained'
											className={`${row.isDisable ?? false ? 'in-active' : 'active'} activeButton`}
										/>
										<ButtonComponent
											onClick={() => {
												currentStatusRef.current = row.clientstreaming[0].streamStatus;
												addClientModalOpen(row);
												setIsEdit(true);
											}}
											sx={{ background: '#F2A892' }}
											btnName={'Edit'}
											className='editBtn'
											type='button'
											variant='contained'
										/>
										<ButtonComponent
											onClick={() => {
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												row.isDisable !== true && navigate(`/client/${row.id}/stream`, { state: row });
											}}
											className={`streamBtn ${getStreamStatusColor(row.clientstreaming[0].streamStatus)}`}
											btnName={getStreamButtonName(row.clientstreaming[0].streamStatus)}
											type='button'
										/>
										<ButtonComponent
											onClick={() => {
												setPreFillData(row);
												setDeleteModalShow(true);
											}}
											sx={{ background: '#F2A892' }}
											className='deleteBtn'
											btnName={'Delete'}
											type='button'
											variant='contained'
										/>
									</div>
									{/* <ButtonComponent
										type='button'
										btnName='Delete'
										variant='contained'
										onClick={() => {
											setPreFillData(row);
											setDeleteModalShow(true);
										}}
									/> */}
									<h4>{row.name}</h4>
								</ClientItem>
							);
						})
					) : (
						<p className='no-data-found'>No data found</p>
					)}
				</ClientList>
			</ClientWrapper>
			<ClientDialogBox>
				<Dialog
					open={isOpenDialog}
					onClose={handleClose}
					fullWidth={true}
					maxWidth={'sm'}
					className='client-dialog-box'
				>
					<DialogContent>
						<ClientForm
							setOpenMode={setOpenMode}
							preFillData={preFillData}
							isEdit={isEdit}
							currentStatus={currentStatusRef.current}
							bodyParams={{ searchInput: searchInput?.trim() }}
						/>
					</DialogContent>
				</Dialog>
			</ClientDialogBox>
			<ClientDialogBox>
				<Dialog
					open={deleteModalShow}
					onClose={() => {
						setDeleteModalShow(false);
					}}
					fullWidth={true}
					maxWidth={'sm'}
					className='client-dialog-box'
				>
					<DialogContent>
						<ClientDeleteModal
							handleClose={() => {
								setDeleteModalShow(false);
							}}
							handleDeleteClient={() => {
								dispatch(setLoadingState(true));
								void dispatch(
									handleDeleteClient({
										id: preFillData.id,
										searchInput: searchInput?.trim()
									})
								);
								setDeleteModalShow(false);
							}}
							isLoading={isLoading}
						/>
					</DialogContent>
				</Dialog>
			</ClientDialogBox>
		</>
	);
};

export default Client;
