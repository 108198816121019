/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { songsService } from '../../services/songsService';
import type { AppDispatch } from '../store';
import { setLoadingState, setLoadingStateV2 } from '../slice/loadingSlice';
import { handleLogout } from '../../utils/handleLogout';
import { setSnackbarState, setSnackbarMessage } from '../slice/snackbarSlice';
import { setSongs, setTotalCount, setIsLoading } from '../slice/songsSlice';
import { type SongsParamsType, type SerchParams, type SongsInputInterface, type DropBoxSongsParamsType, type assignTagParams, type SongsParamsTypeV2, type SongsParamsTypeV3, type SongsParamsTypeV4 } from '../../modals';
import { strings } from '../../utils/strings';
import { handleGetPlaylistSongs } from './playlistSongAction';
import { playlistService } from '../../services/playlistService';

export const handleGetSongs = createAsyncThunk<
    object,
    SerchParams,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/getSongs',
        async (params, { dispatch }) => {
            try {
                const response: any = await songsService.getSongs(params);
                dispatch(setSongs(response.data.data.results));
                dispatch(setTotalCount(response.data.data.totalCount));
                dispatch(setLoadingState(false));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setIsLoading());
                dispatch(setLoadingState(false));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleGetRecentSongs = createAsyncThunk<
    object,
    SerchParams,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/getRecentSongs',
        async (params, { dispatch }) => {
            try {
                const response: any = await songsService.getRecentSongs(params);
                dispatch(setSongs(response.data.data.results));
                dispatch(setTotalCount(response.data.data.totalCount));
                dispatch(setLoadingState(false));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setLoadingState(false));
                dispatch(setIsLoading());
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleGetSongFromFolderPlayList = createAsyncThunk<
    object,
    SerchParams,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/getSongFromList',
        async (params, { dispatch }) => {
            try {
                const response: any = await songsService.getSongFromFolderPlayList(params);
                dispatch(setSongs(response.data.data.results));
                dispatch(setTotalCount(response.data.data.totalCount));
                dispatch(setLoadingStateV2(false));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setLoadingStateV2(false));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleAddSong = createAsyncThunk<
    object,
    SongsParamsType,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/addSong',
        async (params, { dispatch }) => {
            try {
                dispatch(setLoadingState(true));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(strings.SNACK_BAR_MESSAGE));
                const response: any = await songsService.addSong(params.body);
                void dispatch(handleGetSongs({}));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                params.importSuccess();
                params.handleClear();
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                params.handleClear();
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleAddSongWithProgressBar = createAsyncThunk<
    object,
    SongsParamsTypeV2,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/addSong',
        async (params, { dispatch }) => {
            try {
                dispatch(setLoadingState(true));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(strings.SNACK_BAR_MESSAGE));
                const response: any = await songsService.addSongWithProgressBar(params.body);
                void dispatch(handleGetSongs({}));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                params.importSuccess();
                params.handleClear();
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                params.handleClear();
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleAddSongV2 = createAsyncThunk<
    object,
    SongsParamsTypeV4,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/addSong',
        async (params, { dispatch }) => {
            try {
                await songsService.addSongV2(params.body, params.params);
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setLoadingState(false));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleAddSongWithProgressBarV2 = createAsyncThunk<
    object,
    SongsParamsTypeV3,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/addSong',
        async (params, { dispatch }) => {
            try {
                dispatch(setLoadingState(true));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(strings.SNACK_BAR_MESSAGE));
                const response: any = await playlistService.createPlaylistV2(params.body);
                void dispatch(
                    handleAddSongV2({
                        body: { ...params.body, playlistId: response.data?.data?.id },
                        params
                    })
                );
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                params.handleClear();
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleAddSongFromDropBox = createAsyncThunk<
    object,
    DropBoxSongsParamsType,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/addSongFromDropbox',
        async (params, { dispatch }) => {
            try {
                dispatch(setLoadingState(true));
                const response: any = await songsService.addSongFromDropbox(params.body);
                void dispatch(handleGetSongs({}));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                params.importSuccess();
                params.handleClear();
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                params.handleClear();
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleUpdateSong = createAsyncThunk<
    object,
    SongsInputInterface,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/updateSong',
        async (params, { dispatch }) => { 
            try {
                const response: any = await songsService.updateSong(params);
                if (params.alignment) {
                    void dispatch(handleGetRecentSongs({ ...params.params }));
                } else {
                    void dispatch(handleGetSongs({ ...params.params }));
                }
                if(params.isFromPlaylist) {
                    void dispatch( handleGetPlaylistSongs({ playlistId: params.playlistId }) );
                }
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleAssignTagAction = createAsyncThunk<
    object,
    assignTagParams,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/assignTags',
        async (params, { dispatch }) => {
            try {
                const response: any = await songsService.assignTag(params);
                if (params.params?.isSong === true) {
                    if (params.alignment) {
                        void dispatch(handleGetRecentSongs({ ...params.params }));
                    } else {
                        void dispatch(handleGetSongs({ ...params.params }));
                    }
                } else {
                    void dispatch(handleGetPlaylistSongs({
                        playlistId: params?.params?.playlistId,
                        search: params?.params?.search,
                        sort: params?.params?.sort,
                        field: params?.params?.field,
                    }));
                }
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleDeleteSong = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/deleteSong',
        async (params, { dispatch }) => {
            try {
                const response: any = await songsService.deleteSong(params.songId);
                if (params.alignment === true) {
                    void dispatch(handleGetRecentSongs({ ...params.resetProps }));
                } else {
                    void dispatch(handleGetSongs({ ...params.resetProps }));
                }
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleDeleteMultipleSong = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/deleteMultipleSong',
        async (params, { dispatch }) => {
            try {
                const response: any = await songsService.deleteMultipleSongs(params);
                if (params.isSong === true) {
                    if (params.alignment === true) {
                        void dispatch(handleGetRecentSongs({ ...params.resetProps }));
                    } else {
                        void dispatch(handleGetSongs({ ...params.resetProps }));
                    }
                } else {
                    void dispatch(handleGetPlaylistSongs({
                        playlistId: params.playlistId,
                        search: params.search,
                        sort: params.sort,
                        field: params.field,
                    }));
                }
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const getAuthUrl = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'getAuthUrl',
        async (params, {dispatch}) => {
            try {
                const response: any = await songsService.getAuthUrl();
                if (response?.data?.message) {
                    params.callback(response.data.message);
                }
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

    export const handleAddSongFromDrive = createAsyncThunk<
    object,
    DropBoxSongsParamsType,
    {
        dispatch: AppDispatch
    }
>
    (
        'songs/addSongFromDrive',
        async (params, { dispatch }) => {
            try {
                dispatch(setLoadingState(true));
                const response: any = await songsService.addSongFromDrive(params.body);
                void dispatch(handleGetSongs({}));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                params.importSuccess();
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );