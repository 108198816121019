/* eslint-disable @typescript-eslint/restrict-template-expressions */
const url = process.env.REACT_APP_URL;
const bucketUrl = process.env.REACT_APP_STORAGEBUCKET;
const clientIdDrive = process.env.REACT_APP_CLIENT_ID;

export const ApiUrl: string = `${url}api/`;
export const DropboxAppKey: string = 'ddqu6ii2vivlst1';   // 'zgzif8cnoigrvx4';
export const ImageUrl: string = url as string;
export const PrefixUrl: string = bucketUrl as string;
export const socketUrlForProgress: string = url as string;
export const clientId: string = clientIdDrive as string;
