/* eslint-disable quotes */
import axios from 'axios';
import { ApiUrl } from '../utils/config';

const addCrossFade = (body: any): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken,
		}
	};
	return axios.post(ApiUrl + 'song/crossfade/duration', body, axiosConfig);
};

const getCrossFade = (): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken,
		}
	};
	return axios.get(ApiUrl + 'song/crossfade', axiosConfig);
};

export const crossFadeService = { addCrossFade, getCrossFade };