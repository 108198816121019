import React, { type FC } from 'react';
import { Form, Formik } from 'formik';
import InputComponent from '../../components/InputComponent';
import ButtonComponent from '../../components/ButtonComponent';
import { Box, Typography } from '@mui/material';
import { type CreateAndRenameModalInput } from '../../modals';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface CreateNewPlayListFormProps {
	isPlaylist: boolean;
	isActiveFolderId: any;
	item: any;
	isLoading: any;
	initialValuePlaylist: CreateAndRenameModalInput;
	validationSchemas: any;
	handleSubmitPlaylist: (value: any) => void;
	handleCancel: () => void;
	handleCreateNewPlaylist: () => void;
}

const CreateNewPlayListForm: FC<CreateNewPlayListFormProps> = ({
	isPlaylist,
	isActiveFolderId,
	item,
	isLoading,
	initialValuePlaylist,
	validationSchemas,
	handleSubmitPlaylist,
	handleCancel,
	handleCreateNewPlaylist
}) => {
	if (isPlaylist && isActiveFolderId === item.id) {
		return (
			<div>
				<Formik
					initialValues={initialValuePlaylist}
					validationSchema={validationSchemas}
					onSubmit={handleSubmitPlaylist}
				>
					{({ values, errors, touched, handleChange, handleBlur }) => (
						<Form>
							<div className='form-field'>
								<InputComponent
									id='name'
									name='name'
									value={values.name}
									placeholder='Name'
									type='text'
									variant='outlined'
									onBlur={handleBlur}
									onChange={handleChange}
									error={!!((touched.name ?? false) && errors.name != null)}
									helperText={(touched.name ?? false) && errors.name}
									required
								/>
							</div>
							<Typography>Are you sure you want to create new playlist ?</Typography>
							<Box sx={{ textAlign: 'right' }}>
								<ButtonComponent
									variant='outlined'
									btnName='Cancel'
									onClick={handleCancel}
								/>
								<ButtonComponent
									type='submit'
									variant='contained'
									btnName='Create'
									loading={isLoading}
								/>
							</Box>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
	return (
		<Box className='create-new-playlist'>
			<ButtonComponent
				startIcon={<AddCircleOutlineIcon />}
				variant='outlined'
				btnName='New playlist'
				onClick={handleCreateNewPlaylist}
			/>
		</Box>
	);
};

export default CreateNewPlayListForm;
