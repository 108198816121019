import React, { type FC } from 'react';
import { Typography, Box } from '@mui/material';
import ButtonComponent from '../../components/ButtonComponent';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface CreateNewPlaylistComponentProps {
	currentSelectedSongId: any;
	error: boolean;
	handleCreateNewFolder: () => void;
	handleCreateNewPlaylist: () => void;
	handleCancel: () => void;
}

const CreateNewPlaylistComponent: FC<CreateNewPlaylistComponentProps> = ({
	currentSelectedSongId,
	error,
	handleCreateNewFolder,
	handleCreateNewPlaylist,
	handleCancel
}) => {
	return (
		<>
			<Box className='playlist-bottom'>
				<Box className='playlist-length'>
					<Typography>Are you sure you want to add this playlist ?</Typography>
					<Typography style={{ marginLeft: 3 }}>
						({currentSelectedSongId?.length})
					</Typography>
				</Box>
				<Box className='create-new-folder'>
					<ButtonComponent
						startIcon={<AddCircleOutlineIcon />}
						variant='outlined'
						btnName='New folder'
						onClick={handleCreateNewFolder}
					/>
				</Box>
			</Box>
			{error && (
				<Typography style={{ color: '#d32f2f', marginTop: 4, marginLeft: 21 }}>
					Please select playlist
				</Typography>
			)}
			<Box className='modal-buttons'>
				<ButtonComponent
					variant='outlined'
					btnName='Cancel'
					onClick={handleCancel}
				/>
				<ButtonComponent
					type='button'
					variant='contained'
					btnName='Create'
					onClick={handleCreateNewPlaylist}
				/>
			</Box>
		</>
	);
};

export default CreateNewPlaylistComponent;
