import React, { type FC } from 'react';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import RouteProvider from './routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary : {
      main: '#29292b',
    },
    secondary: {
      main: '#FFC5B2',
      dark: '#F2A892'
    }
  },
  shape: {
    borderRadius: 10,
  }
});


const App: FC = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <RouteProvider />
        </Provider>
      </ThemeProvider>
    </div>
  );
};

export { App };
