import { memo, type FC } from 'react';
import { type RatingInterface } from '../modals/rating';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const RatingComponent: FC<RatingInterface> = ({
	currentValue,
	songId,
	handleRatingChange
}) => {
	const onChangeHandler = (value: any): void => {
		handleRatingChange(value, songId);
	};

	return (
		<Rating
			precision={0.5}
			value={currentValue}
			style={{ color: '#8E2734', fontSize: '14px' }}
			onChange={(e, value: any) => {
				onChangeHandler(value);
			}}
			onClick={(e: any) => {
				e.stopPropagation();
			}}
			emptyIcon={<StarIcon style={{ opacity: 1, color: '#fff' }} fontSize="inherit" />}

		/>
	);
};

export default memo(RatingComponent);
