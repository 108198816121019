import React, { type FC, type InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import { type InputCompoInterface } from '../modals';

const InputComponent: FC<
	InputCompoInterface &
		InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
> = (props) => {
	const {
		onChange,
		placeholder,
		type,
		variant,
		error,
		helperText,
		disabled,
		className,
		id,
		InputProps
	} = props;
	const [field] = useField(props);
	return (
		<>
			<TextField
				{...field}
				className={className}
				type={type}
				onChange={onChange}
				placeholder={placeholder}
				variant={variant}
				error={error}
				helperText={helperText}
				disabled={disabled}
				id={id}
				InputProps={InputProps}
			/>
		</>
	);
};
export default InputComponent;
