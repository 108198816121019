/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, type FC, useEffect } from 'react';
import {
	Box,
	Dialog,
	DialogContent,
	Divider,
	Menu,
	MenuItem,
	TextField
} from '@mui/material';
import { DndProvider } from 'react-dnd';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import musicIcon from '../../assets/images/icons/music-item.svg';
import folderIcon from '../../assets/images/icons/folder-icon.svg';
import BtnAddIcon from '../../assets/images/icons/plus-btn-icon.svg';
import {
	handleDeletePlaylist,
	handleDeletePlaylistFolder,
	handleGetPlaylistFolder,
	handleDragPlayList,
	handleDragFolder
} from '../../redux/actions/playlistAction';
import Confirmation from '../../components/ConfirmationModalComponent';
import CreateAndRename from './CreateAndRenameModal';
import {
	type SelectedPlaylist,
	type SelectedContextMenuFolderType
} from '../../modals';
import {
	setPlaylistFilterEmpty,
	setSelectedPlaylistState
} from '../../redux/slice/playlistSlice';
import {
	setSnackbarMessage,
	setSnackbarState
} from '../../redux/slice/snackbarSlice';
import ButtonComponent from '../../components/ButtonComponent';
import { isEmpty, isEmptyObject } from '../../utils/validators';
import {
	Tree,
	type NodeModel,
	MultiBackend,
	getBackendOptions
} from '@minoru/react-dnd-treeview';
import UpdateData from '../../utils/UpdateData';
import CopyPlaylistComponent from '../../components/CopyPlaylistComponent';
import { PlaylistFolderWrapper } from './style';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { setModalState } from '../../redux/slice/modalSlice';

const PlaylistFolder: FC = () => {
	const dispatch = useAppDispatch();
	const { playlistFolder, isPlaylistFilterEmpty } = useAppSelector(
		(state) => state.playlist
	);
	const [parentFolder, setParentFolder] = useState<any[]>([]);
	const [parentFolderv2, setParentFolderv2] = useState<any[]>([]);
	const [dragItem, setDragItem] = useState<any>({ type: '' });
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selectedItem, setSelectedItem] = useState<any>(0);
	const [openCopyPlaylist, setOpenCopyPlaylist] = useState<boolean>(false);
	const [selectPlaylistItem, setSelectPlaylistItem] = useState<object>({});
	const [playlistName, setPlaylistName] = useState('');
	const [clickPosition, setClickPosition] = useState<{
		x: number;
		y: number;
	} | null>(null);

	const handleFilterClear = (): void => {
		setPlaylistName('');
		setParentFolderv2(parentFolder);
	};

	useEffect(() => {
		void dispatch(handleGetPlaylistFolder({}));
	}, []);

	useEffect(() => {
		if (isPlaylistFilterEmpty) {
			handleFilterClear();
			setSelectPlaylistItem({});
		}
	}, [isPlaylistFilterEmpty]);

	useEffect(() => {
		if (playlistFolder.length > 0) {
			setParentFolder(UpdateData(playlistFolder));
			setParentFolderv2(UpdateData(playlistFolder));
		} else {
			setParentFolder([]);
			setParentFolderv2([]);
		}
	}, [playlistFolder]);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [contextMenuFolder, setContextMenuFolder] =
		useState<SelectedContextMenuFolderType>({});
	const [selectedPlaylist, setSelectedPlaylist] = useState<SelectedPlaylist>({});
	const [isNew, setIsNew] = useState<boolean>(false);

	const open = Boolean(anchorEl);

	useEffect(() => {
		dispatch(setSelectedPlaylistState(selectedPlaylist));
	}, [selectedPlaylist]);

	const handleCloseMenu = (
		e: React.MouseEvent<HTMLLIElement | HTMLDivElement>
	): void => {
		setAnchorEl(null);
		e.preventDefault();
		e.stopPropagation();
	};

	const selectPlaylist = (item: any): void => {
		setSelectedPlaylist(item);
	};

	const openContextMenu = (
		e: React.MouseEvent<HTMLDivElement>,
		item?: any
	): void => {
		setContextMenuFolder(item);
		setAnchorEl(e.currentTarget);
		e.preventDefault();
		e.stopPropagation();
	};

	// Confirmation
	const [isOpenConfirmation, setOpenConfirmation] = useState<boolean>(false);
	const [isFromFolder, setIsFromFolder] = useState<boolean>(false);
	const [isPaste, setIsPaste] = useState<boolean>(false);

	const handleCloseConfirmation = (): void => {
		setOpenConfirmation(false);
		dispatch(setModalState(false));
		handleFilterClear();
	};

	const handleFolderConfirmation = (): void => {
		setOpenConfirmation(true);
		dispatch(setModalState(true));
		setIsFromFolder(true);
		setAnchorEl(null);
	};

	const hanldeDeleteFolderOrPlaylist = (): void => {
		if (isFromFolder) {
			void dispatch(handleDeletePlaylistFolder(Number(contextMenuFolder.id)));
			setOpenConfirmation(false);
			dispatch(setModalState(false));
			return;
		}

		if (!isEmpty(contextMenuFolder.playlistId)) {
			void dispatch(handleDeletePlaylist(Number(contextMenuFolder.playlistId)));

			if (contextMenuFolder.playlistId === selectedPlaylist.playlistId) {
				setSelectedPlaylist({});
			}
		} else {
			void dispatch(setSnackbarState(true));
			void dispatch(setSnackbarMessage('Please Select valid playlist'));
		}

		setOpenConfirmation(false);
		dispatch(setModalState(false));
		setSelectPlaylistItem({});
		handleFilterClear();
	};

	const handlePlaylistConfirmation = (): void => {
		setOpenConfirmation(true);
		dispatch(setModalState(true));
		setIsFromFolder(false);
		setAnchorEl(null);
	};

	// Create folder and playlist
	const [isOpenCreateAndRename, setIsOpenCreateAndRename] =
		useState<boolean>(false);

	const handleCloseCreateAndRename = (): void => {
		handleFilterClear();
		setIsOpenCreateAndRename(false);
		dispatch(setModalState(false));
		setSelectPlaylistItem({});
	};

	const hanldeCreateFolder = (e: any): void => {
		e.stopPropagation();
		setIsFromFolder(true);
		setIsNew(true);
		setIsOpenCreateAndRename(true);
		dispatch(setModalState(true));
		setAnchorEl(null);
	};

	const hanldeCreatePlaylist = (): void => {
		setIsFromFolder(false);
		setIsNew(true);
		setIsOpenCreateAndRename(true);
		dispatch(setModalState(true));
		setAnchorEl(null);
	};

	const handleCopy = (): void => {
		setSelectPlaylistItem(contextMenuFolder);
		setAnchorEl(null);
	};

	const handlePaste = (): void => {
		setOpenCopyPlaylist(true);
		dispatch(setModalState(true));
		setIsPaste(true);
		setAnchorEl(null);
	};

	const hanldeRename = (): void => {
		setIsNew(false);
		setIsOpenCreateAndRename(true);
		dispatch(setModalState(true));
		setAnchorEl(null);
	};

	const OpenListFunction = (e?: {
		currentTarget: { classList: { add: (arg0: string) => void } };
	}): void => {
		document
			.querySelectorAll('.music-folder-list .playlist-title')
			.forEach((item) => {
				item.classList.remove('open');
			});
		e?.currentTarget.classList.add('open');
	};

	const handleDrop = (newTree: NodeModel[]): void => {
		const getData = newTree.filter((el) => dragItem.id === el.id);
		const parentFolder = getData.length > 0 ? Number(getData[0]?.parent) : null;
		if (dragItem?.type === 'playlist') {
			if (parentFolder === 0) {
				return;
			}
			const data = {
				playListId: Number(dragItem?.playlistId),
				parentFolderId: parentFolder
			};
			void dispatch(handleDragPlayList(data));
		}
		if (dragItem?.type === 'folder') {
			const data = parentFolder
				? {
						id: Number(dragItem?.folderId),
						parentFolderId: parentFolder
				  }
				: {
						id: dragItem?.folderId
				  };
			void dispatch(handleDragFolder(data));
		}
		setParentFolder(newTree);
		setParentFolderv2(newTree);
	};

	const handleonContextMenu = (
		e: any,
		item: any,
		isFromFolder: boolean
	): any => {
		openContextMenu(e, item);
		setIsFromFolder(isFromFolder);
		setClickPosition({ x: e?.clientX, y: e?.clientY });
	};

	const handlePlaylist = (item: any): any => {
		selectPlaylist(item);
		OpenListFunction();
		setSelectedItem(item.id);
	};

	const handleCopyPlaylist = (): void => {
		setOpenCopyPlaylist(true);
		dispatch(setModalState(true));
		setAnchorEl(null);
	};

	const handleClass = (item: any): string => {
		if (selectedItem === item.id) {
			return 'music-folder selected';
		} else return 'music-folder';
	};

	const getParentFolderId = (): any => {
		if (isFromFolder) {
			return contextMenuFolder?.id;
		}
		return contextMenuFolder?.parentFolderId;
	};

	function getUniqueArray(a: any): any {
		function onlyUnique(value: any, index: number, self: any): any {
			return self.indexOf(value) === index;
		}
		const unique = a?.filter(onlyUnique);

		return unique;
	}

	let parentFolderIds: any = [];
	const handleRecursiveData = (parentFolderId: any): any => {
		if (parentFolderId === null) return getUniqueArray(parentFolderIds);

		parentFolderIds = [...parentFolderIds, parentFolderId];

		const handleParentFolderId = parentFolder.find((e: any) => {
			return e.id === parentFolderId;
		});

		return handleRecursiveData(handleParentFolderId?.parentFolderId);
	};

	let filteredParentFolderIds: any[] = [];

	const handlePlaylistNameChange = (e: any): void => {
		if (!isEmpty(e.target?.value) && isPlaylistFilterEmpty) {
			dispatch(setPlaylistFilterEmpty(false));
		}
		parentFolderIds = [];
		setPlaylistName(e.target?.value);
		if (!isEmpty(e.target?.value)) {
			const filteredDataByPlaylistName = parentFolder?.filter((eve: any) => {
				return (
					eve.type === 'playlist' &&
					eve.name?.toLowerCase()?.includes(e.target?.value?.toLowerCase()?.trim())
				);
			});

			filteredParentFolderIds = [];
			for (let index = 0; index < filteredDataByPlaylistName?.length; index++) {
				const element = filteredDataByPlaylistName[index];
				const recursiveParentFolderIds = handleRecursiveData(
					element?.parentFolderId
				);
				filteredParentFolderIds = filteredParentFolderIds.concat(
					recursiveParentFolderIds
				);
			}

			const filteredDataById: any[] = parentFolder.filter((item: any) => {
				return filteredParentFolderIds?.includes(item.id);
			});
			const mergedArray = filteredDataById.concat(filteredDataByPlaylistName);
			setParentFolderv2(mergedArray);
		} else {
			setParentFolderv2([]);
			setParentFolderv2(parentFolder);
		}
	};

	const getChildCount = (folder: any): number => {
		const temp = parentFolderv2;
		const filteredArray = temp.filter((e: any) => {
			return e?.parentFolderId === folder?.id;
		});
		if (filteredArray) {
			return filteredArray?.length;
		}
		return 0;
	};

	return (
		<PlaylistFolderWrapper>
			<Box className='playlist-input'>
				<TextField
					id='playlistName'
					name='playlistName'
					value={playlistName}
					placeholder='Playlist Name'
					type='text'
					variant='outlined'
					onChange={handlePlaylistNameChange}
					sx={{ borderRadius: 1 }}
				/>
			</Box>
			{parentFolder.length > 0 ? (
				<DndProvider backend={MultiBackend} options={getBackendOptions()}>
					<Tree
						classes={{ root: 'root_droppable_area' }}
						onDragStart={(e) => {
							setDragItem(e);
						}}
						rootId={0}
						tree={parentFolderv2}
						render={(item, { depth, onToggle, isOpen }) => {
							return (
								<div style={{ marginInlineStart: depth * 20, cursor: 'pointer' }}>
									{item.droppable && (
										<Box
											className={`folder-name ${isOpen ? 'selected' : ''}`}
											onContextMenu={(e) => {
												handleonContextMenu(e, item, true);
											}}
											onClick={onToggle}
										>
											<Box>
												{<img src={folderIcon} alt='' />} {item.text}{' '}
												{`(${getChildCount(item)})`}
											</Box>
											{getChildCount(item) > 0 && <ArrowForwardIosIcon />}
										</Box>
									)}
									{!item.droppable && (
										<Box
											className={handleClass(item)}
											onClick={() => {
												handlePlaylist(item);
											}}
											onContextMenu={(e) => {
												handleonContextMenu(e, item, false);
												// handleCopyPlaylist(e, item);
											}}
										>
											{<img src={musicIcon} alt='' />} {item.text}
										</Box>
									)}
								</div>
							);
						}}
						dragPreviewRender={(monitorProps) => <div>{monitorProps.item.text}</div>}
						onDrop={handleDrop}
					/>
				</DndProvider>
			) : (
				<Box className="create_folder_button">
					<ButtonComponent
						type='button'
						btnName='Create Folder'
						variant='contained'
						startIcon={<img src={BtnAddIcon} alt='' />}
						onClick={hanldeCreateFolder}
					/>
				</Box>
			)}
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenu}
				onContextMenu={handleCloseMenu}
				className='custom-info-menu'
				MenuListProps={{
					'aria-labelledby': 'basic-button'
				}}
				anchorReference='anchorPosition'
				anchorPosition={
					clickPosition != null
						? { top: clickPosition?.y, left: clickPosition?.x }
						: undefined
				}
			>
				<MenuItem onClick={hanldeRename}>Rename</MenuItem>
				<Divider />
				{isFromFolder && (
					<MenuItem onClick={hanldeCreatePlaylist}>Create Playlist</MenuItem>
				)}
				{!isFromFolder && (
					<MenuItem onClick={handleCopyPlaylist}>Duplicate</MenuItem>
				)}
				{!isFromFolder && <MenuItem onClick={handleCopy}>Copy</MenuItem>}
				{!isEmptyObject(selectPlaylistItem) && (
					<MenuItem onClick={handlePaste}>Paste</MenuItem>
				)}
				{isFromFolder && (
					<MenuItem onClick={hanldeCreateFolder}>Create Folder</MenuItem>
				)}
				<Divider />
				{isFromFolder && (
					<MenuItem onClick={handleFolderConfirmation}>Delete Folder</MenuItem>
				)}
				{!isFromFolder && (
					<MenuItem onClick={handlePlaylistConfirmation}>Delete Playlist</MenuItem>
				)}
			</Menu>
			<Box>
				<Dialog
					open={openCopyPlaylist}
					onClose={() => {
						setOpenCopyPlaylist(false);
						dispatch(setModalState(false));
						setIsPaste(false);
						setSelectPlaylistItem({});
						handleFilterClear();
					}}
					aria-labelledby='responsive-dialog-title'
				>
					<DialogContent>
						<CopyPlaylistComponent
							playlist={
								isPaste
									? {
											...selectPlaylistItem,
											parentFolderId: getParentFolderId()
									  }
									: contextMenuFolder
							}
							onClose={() => {
								setOpenCopyPlaylist(false);
								dispatch(setModalState(false));
								setIsPaste(false);
								setSelectPlaylistItem({});
								handleFilterClear();
							}}
							title={isPaste ? 'Paste' : 'Duplicate'}
						/>
					</DialogContent>
				</Dialog>
				<Dialog
					open={isOpenConfirmation}
					onClose={handleCloseConfirmation}
					aria-labelledby='responsive-dialog-title'
				>
					<DialogContent>
						<Confirmation
							setConfirmation={setOpenConfirmation}
							message={isFromFolder ? 'folder' : 'playlist'}
							handleConfirmation={hanldeDeleteFolderOrPlaylist}
						/>
					</DialogContent>
				</Dialog>
				<Dialog
					open={isOpenCreateAndRename}
					onClose={handleCloseCreateAndRename}
					aria-labelledby='responsive-dialog-title'
				>
					<DialogContent>
						<CreateAndRename
							setIsOpenCreateAndRename={setIsOpenCreateAndRename}
							selectedContextFolder={
								playlistFolder.length > 0 ? contextMenuFolder : {}
							}
							selectedPlaylist={selectedPlaylist}
							isNew={isNew}
							isFromFolder={isFromFolder}
							onClose={handleCloseCreateAndRename}
						/>
					</DialogContent>
				</Dialog>
			</Box>
		</PlaylistFolderWrapper>
	);
};

export default PlaylistFolder;
