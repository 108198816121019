/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { AccountCircle } from '@mui/icons-material';
import { PrefixUrl } from '../../utils/config';

interface Display {
	currentTrack?: any;
	audioRef?: any;
	setDuration?: any;
	progressBarRef?: any;
	handleNext?: any;
}

const DisplayTrack = ({
	currentTrack,
	audioRef,
	setDuration,
	progressBarRef,
	handleNext
}: Display) => {
	const onLoadedMetadata = () => {
		const seconds = audioRef.current.duration;
		setDuration(Math.round(seconds));
		progressBarRef.current.max = Math.round(seconds);
	};

	const handleThumbnail = () => {
		if (currentTrack?.thumbnail) {
			return <img src={currentTrack?.thumbnail} alt='audio avatar' />;
		}
		return <AccountCircle />;
	};

	return (
		<div className='music-detail'>
			<audio
				src={`${PrefixUrl}${currentTrack?.path}`}
				ref={audioRef}
				onLoadedMetadata={onLoadedMetadata}
				onEnded={handleNext}
			/>
			<div className='audio_info'>
				<div className='music_info'>
					<p className='title'>{currentTrack?.title}</p>
					<p className='small'>{currentTrack?.artist}</p>
				</div>
				<div className='audio_image'>{handleThumbnail()}</div>
			</div>
		</div>
	);
};
export default DisplayTrack;
