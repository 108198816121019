import React, { type FC } from 'react';
import { Snackbar } from '@mui/material';
import { type SnackbarInterface } from '../modals';

const SnackbarComponent: FC<SnackbarInterface> = ({ open, autoHideDuration, message, onClose }) => {
    const anchorOrigin: any = { vertical: 'top', horizontal: 'center', };
    return(
        <>
            <Snackbar
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={onClose}
                message={message}
                anchorOrigin={anchorOrigin}
            />
        </>
    );
};

export default SnackbarComponent;