import React, { type FC } from 'react';
import { Route, Routes } from 'react-router-dom';
// import Home from '../pages/Home';
import Login from '../pages/login/Login';
import Client from '../pages/client/Client';
import PrivateRoute from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import Calendar from '../pages/calendar/Calendar';
import ImportSong from '../pages/importSongs/ImportSong';
import Songs from '../pages/songs/Song';
import Profile from '../pages/profile/Profile';
import CrossFade from '../pages/crossFade/CrossFade';
import SnackbarComponent from '../components/SnackbarComponent';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { setSnackbarState } from '../redux/slice/snackbarSlice';
import PlayList from '../pages/playlist/PlayList';
import Streaming from '../pages/streaming/Streaming';
import Landing from '../pages/landing';

const RouteProvider: FC = () => {
	const dispatch = useAppDispatch();
	const { isOpen, responseMsg } = useAppSelector((state) => state.snackbar);

	return (
		<>
			<Routes>
				<Route path='/' element={<PublicRoute component={Landing} />} />
				<Route path='/login' element={<PublicRoute component={Login} />} />
				<Route path='/client' element={<PrivateRoute component={Client} />} />
				<Route path='/' element={<PrivateRoute component={Songs} />} />
				<Route
					path='/calendar'
					element={<PrivateRoute component={Calendar} needDocUpload={true} />}
				/>
				<Route
					path='/play-list'
					element={<PrivateRoute component={PlayList} needDocUpload={true} />}
				/>
				<Route path='/import' element={<PrivateRoute component={ImportSong} />} />
				<Route path='/client/:id/stream' element={<PrivateRoute component={Streaming} />} />
				<Route path='/Songs' element={<PrivateRoute component={Songs} />} />
				<Route path='/profile' element={<PrivateRoute component={Profile} />} />
				<Route path='/crossFade' element={<PrivateRoute component={CrossFade} />} />
			</Routes>
			<SnackbarComponent
				open={isOpen}
				autoHideDuration={5000}
				onClose={() => {
					dispatch(setSnackbarState(false));
				}}
				message={responseMsg}
			/>
		</>
	);
};

export default RouteProvider;
