import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    crossFadeData: {
        startDuration: 0,
        endDuration: 0
    }
};

export const crossFadeSlice = createSlice({
    name: 'crossFade',
    initialState,
    reducers: {
        setCrossFadeData: (state, action: PayloadAction<any>) => {
            state.crossFadeData = action.payload;
        }
    }
});

export const { setCrossFadeData } = crossFadeSlice.actions;

export default crossFadeSlice.reducer;
