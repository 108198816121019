import axios from 'axios';
import { ApiUrl } from '../utils/config';

const getStreaming = ({ clientId }: { clientId: string }): any => {
  const userToken: any = localStorage.getItem('userToken');
  const axiosConfig: any = {
    headers: {
      token: userToken,
    }
  };
  return axios.get(ApiUrl + `auth/${clientId}/stream-url`, axiosConfig);
};

const startStreaming = ({ clientId, streamingKey }: { clientId: string, streamingKey: string }): any => {
  const userToken: any = localStorage.getItem('userToken');
  const axiosConfig: any = {
    headers: {
      token: userToken,
    }
  };
  return axios.put(ApiUrl + `stream/${clientId}/status/${streamingKey}`, {}, axiosConfig);
};

const getStreamingInfo = (url: string): any => {
  return axios.get(url, {});
};

export const streamingService = { getStreaming, startStreaming, getStreamingInfo };