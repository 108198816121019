import styled from 'styled-components';
import '../../style/main.scss';
import loginBackground from '../../assets/images/music-background.png';

export const StyledLoginPage = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction:column;
  min-height: 700px; 
  background-image: url(${loginBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position:bottom center;
`;

export const Container = styled.div`
  max-width: 1180px;
  margin: auto;
  padding: 0 10px;

  @media(max-width:1199px){
    max-width:991px;
  }
  @media(max-width:991px){
    max-width:767px;
    padding:0px 30px;
  }
`;

export const LoginWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;

  @media(max-width:1199px){
    grid-gap: 50px;
  }
  @media(max-width:991px){
    grid-gap: 20px;
  }
`;

export const LoginImage = styled.div`
  position:absolute;
  transform:scale(1.5);
  opacity: .05;
  pointer-events :none;

  img{
    width: 480px;
    height: auto;
  }

  @media(max-width:1199px){
    img{
      width: 370px;
      height: auto;
    }
  }
  @media(max-width:991px){
    img{
      width: 300px;
      height: auto;
    }
  }
  @media(max-width:767px){
    text-align:center;
    margin-bottom:20px;
    img{
      max-width: 200px;
      margin:auto;
      width:100%;
    }
  }
`;

export const FormBox = styled.div`
  display:flex;
  flex-direction: column;

  h2{
    font-size: 30px;
    font-weight: 400;
    font-family: 'regular-fonts';
    margin-bottom: 65px;
    
    a {
      color : #29292b;
    }
    a:hover {
      color : #000;
    }
  }
  .input-wrapper {
    position:relative;
  }
  .input-wrapper > div {
    width :100%; 
  }
  .input-wrapper img {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 18px;
    z-index: 1;
  }
  input {
    padding:20px 20px 20px 40px;
    width:100%;
    font-size:14px;
  }
  button {
    width : 100%;
    margin-top: 30px;
    font-size: 16px;
    text-transform:capitalize;
  }
  button + .bottom-link{
    text-align:center;
  }
  button + .bottom-link a {
    text-align:center;
    margin-top:20px;
    display:inline-block;
    text-align:center;
    color: #000;
    opacity :.5;
    transition-duration:.3s;
    width:max-content;
  }
  button + .bottom-link a:hover{
    opacity :1;
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #FFC5B2 inset !important;
  }

  @media(max-width:1199px){
    h2{
      font-size:24px;
      margin-bottom:30px;
    }
  }
  @media(max-width:767px){
    h2{
      font-size:22px;
      text-align:center;
      margin-bottom:20px;
    }
  }
`;

export const LoginHeader = styled.div`
  display:flex;

  img {
    width:auto;
    height:65px;
    margin: 8px 30px;
  }

  @media(max-width:991px){
    img{
      height:50px;
    }
  }
  @media(max-width:767px){
    img{
      height:40px;
    }
  }
`;
