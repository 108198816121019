import { createAsyncThunk } from '@reduxjs/toolkit';
import { playlistSongsService } from '../../services/playlistSongService';
import type { AppDispatch } from '../store';
import { setLoadingState } from '../slice/loadingSlice';
import { handleLogout } from '../../utils/handleLogout';
import { setSnackbarState, setSnackbarMessage } from '../slice/snackbarSlice';
import { setPlaylistSongs } from '../slice/playlistSongSlice';
import { type UpdatePlaylistSongParams, type PlaylistSongParams, type searchSongsFromPlaylist, type songRatingInterface } from '../../modals';
import { handleGetRecentSongs, handleGetSongs } from './songsAction';

export const handleAddPlaylistSongs = createAsyncThunk<
    object,
    PlaylistSongParams,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist-songs/addSong',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistSongsService.addPlaylistSongs(params);
                void dispatch(handleGetPlaylistSongs({ playlistId: params.playlistId }));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setLoadingState(false));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleGetPlaylistSongs = createAsyncThunk<
    object,
    searchSongsFromPlaylist,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist-songs/getSong',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistSongsService.getPlaylistSongs(params);
                dispatch(setPlaylistSongs(response.data.data.results));
                dispatch(setLoadingState(false));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                dispatch(setLoadingState(false));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleDeletePlaylistSong = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist-songs/deleteSong',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistSongsService.deletePlaylistSong(params);
                void dispatch(handleGetPlaylistSongs({
                    playlistId: params.playlistId,
                    search: params.search,
                    sort: params.sort,
                    field: params.field,
                }));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleDeleteBulkSongsFromPlaylist = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist-songs/deleteSong',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistSongsService.deleteBulkSongsFromPlaylist(params);
                void dispatch(handleGetPlaylistSongs({
                    playlistId: params.playlistId,
                    search: params.search,
                    sort: params.sort,
                    field: params.field,
                }));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleUpdatePlaylistSongs = createAsyncThunk<
    object,
    UpdatePlaylistSongParams,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist-songs/updateSong',
        async (params: any, { dispatch }) => {
            try {
                const response: any = await playlistSongsService.updatePlaylistSongs({ songsOrderIndex: params.data, playlistId: params.playlistId });
                void dispatch(handleGetPlaylistSongs({ playlistId: params.playlistId }));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleUpdateSongsRating = createAsyncThunk<
    object,
    songRatingInterface,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist-songs/updateSongRating',
        async (params: any, { dispatch }) => {
            try {
                const response: any = await playlistSongsService.updateSongRating({ data: params.data, songId: params.songId });
                if (params.isSong === true) {
                    if (params.alignment === true) {
                        void dispatch(handleGetRecentSongs({ ...params.resetProps }));
                    } else {
                        void dispatch(handleGetSongs({ ...params.resetProps }));
                    }
                } else {
                    void dispatch(handleGetPlaylistSongs({
                        playlistId: params.playlistId,
                        search: params.search,
                        sort: params.sort,
                        field: params.field,
                    }));
                }
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );