import React, { type FC } from 'react';
import LinearProgress, {
	type LinearProgressProps
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const LinearProgressWithLabel: FC<
	LinearProgressProps & { progress: number }
> = ({ progress }) => {
	return (
		<Box className='linear-progess-bar individual-progress' sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress
					variant='determinate'
					value={progress}
					color='primary'
					sx={{
						height: 8
					}}
				/>
			</Box>
			<Box className="percentage">
				<Typography
					variant='body2'
					color='text.secondary'
					sx={{
						fontSize: 20,
						fontWeight: 'bold'
					}}
				>
					{`${Math.round(progress)}%`}
				</Typography>
			</Box>
		</Box>
	);
};
export default LinearProgressWithLabel;
