import React, { type FC } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { loginSchema } from './validation';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { handleLoginUser } from '../../redux/actions/loginAction';
import InputComponent from '../../components/InputComponent';
import { type FormInputInterface } from '../../modals';
import { setLoadingState } from '../../redux/slice/loadingSlice';
import LoginIMg from '../../assets/images/login-bg-image.png';
import emailIcon from '../../assets/images/icons/email-icon-login.svg';
import passIcon from '../../assets/images/icons/pass-icon-login.svg';
import Logo from '../../assets/images/logo.svg';
import { StyledLoginPage, LoginWrapper, Container , LoginImage, FormBox , LoginHeader} from './style';
import ButtonComponent from '../../components/ButtonComponent';

const Login: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector((state) => state.loading);    

    // Formik 
    const initialValues: FormInputInterface = {
        email: '',
        password: '',
    };
    const validationSchema = loginSchema;
    const handleSubmit = (values: FormInputInterface): void => {
        dispatch(setLoadingState(true));
        const data = {
            email: values.email.toLocaleLowerCase(),
            password: values.password
        };
        void dispatch(handleLoginUser({ values: data, login: Login }));
    };

    const Login = (): void => {
        navigate('/');
    };

    return(
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
            {({ values, errors, touched, handleChange, handleBlur }) => (
                <StyledLoginPage>
                    <LoginHeader>
                        <img src={Logo} alt="" />
                    </LoginHeader>
                    <Container>
                        <LoginWrapper>
                            <LoginImage>
                                <img src={LoginIMg} alt='' />
                            </LoginImage>
                            <FormBox>
                                <h2>Get exclusives access to <a href="">MUSICALISM</a></h2>
                                <Form>
                                    <div className="input-wrapper">
                                        <img src={emailIcon} alt="" />
                                        <InputComponent 
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            placeholder="Email"
                                            type="email" 
                                            variant="standard"
                                            onBlur={handleBlur} 
                                            onChange={handleChange}
                                            error={!!((touched.email ?? false) && (errors.email != null))}
                                            helperText={(touched.email ?? false) && errors.email}
                                            required
                                        />
                                    </div>
                                    <div className="input-wrapper">
                                        <img src={passIcon} alt="" />
                                        <InputComponent 
                                            id="password"
                                            name="password"
                                            value={values.password}
                                            placeholder="Password"
                                            type="password" 
                                            variant="standard"
                                            onBlur={handleBlur} 
                                            onChange={handleChange}
                                            error={!!((touched.password ?? false) && (errors.password != null))}
                                            helperText={(touched.password ?? false) && errors.password}
                                            required
                                        />
                                    </div>
                                    <ButtonComponent
                                        type="submit"
                                        btnName="Login"
                                        variant="contained"
                                        loading={isLoading}
                                    />
                                    {/* <div className="bottom-link">
                                        <a href="">Forgot Password?</a>
                                    </div> */}
                                </Form>
                            </FormBox>
                        </LoginWrapper>
                    </Container>
                </StyledLoginPage>
            )}
            </Formik>
        </>
    );
};
export default Login;