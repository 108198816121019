import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
// import type { RootState } from '../store';

const initialState: any = {
    scheduleList: [],
    scheduleFromId: null
};

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setScheduleList: (state, action: PayloadAction<any>) => {
            state.scheduleList = action.payload;
        },
        setScheduleFromId: (state, action: PayloadAction<any>) => {
            state.scheduleFromId = action.payload;
        },
        
    }
});

export const { setScheduleList, setScheduleFromId } = scheduleSlice.actions;

export default scheduleSlice.reducer;
