/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {
	type FC,
	useState,
	useEffect,
	useRef,
	useCallback,
	useLayoutEffect
} from 'react';
import * as dayjs from 'dayjs';
import { type SongTableDataType, type PlayListDialog } from '../../modals';
import {
	Grid,
	Typography,
	Box,
	// TextField,
	// InputAdornment,
	Chip,
	Stack,
	Dialog,
	DialogContent,
	IconButton,
	Menu,
	MenuItem,
	CircularProgress
} from '@mui/material';
import ButtonComponent from '../../components/ButtonComponent';
import RatingComponent from '../../components/RatingComponent';
import AddPlayListSongs from './AddPlayListSongs';
import { handleGetPlaylistFolder } from '../../redux/actions/playlistAction';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import PlaylistFolder from './PlaylistFolder';
// import SearchIcon from '../../assets/images/icons/search.svg';
import BtnAddIcon from '../../assets/images/icons/plus-btn-icon.svg';
import DeleteIcon from '../../assets/images/icons/delete-icon.svg';
import UploadFileIcon from '../../assets/images/icons/upload-file.svg';
import EditNoteIcon from '../../assets/images/icons/edit-note-icon.svg';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
	PlaylistWrapper,
	MusicFolderList,
	PlaylistSearchHeader
} from './style';
import {
	handleDeleteMultipleSong,
	handleGetSongs
} from '../../redux/actions/songsAction';
import Confirmation from '../../components/ConfirmationModalComponent';
import {
	handleDeleteBulkSongsFromPlaylist,
	handleGetPlaylistSongs,
	handleUpdateSongsRating
} from '../../redux/actions/playlistSongAction';
import { convertToMinutesSeconds, totalTime } from '../../utils/getTotalTime';
import CreateAndRename from './CreateAndRenameModal';
import { isEmpty, isEmptyObject } from '../../utils/validators';
import DataTable from '../../components/DataTable';
import {
	setPlay,
	setSelectedSong,
	isPlayed,
	isSelectedSong
} from '../../redux/slice/selectedSong';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import TimeDuration from './TimeDuration';
import {
	type MRT_TableInstance as mrtTableInstance,
	type MRT_SortingState as mrtSortingState
} from 'material-react-table';
import PlayIcon from '../../assets/images/icons/play-button-icon.svg';
import PauseIcon from '../../assets/images/icons/pause-button-icon.svg';
import { SongModal } from '../songs/style';
import AssignTagForm from '../songs/AssignTagForm';
import Split from '@uiw/react-split';
import { setPlaylistFilterEmpty } from '../../redux/slice/playlistSlice';
import { setLoadingState } from '../../redux/slice/loadingSlice';
import { setPlaylistSongs } from '../../redux/slice/playlistSongSlice';
import SongForm from '../songs/SongForm';

const PlayList: FC = () => {
	const isPlay = useAppSelector(isPlayed);
	const dispatch = useAppDispatch();
	const { playlistSongs } = useAppSelector((state) => state.playlistSong);
	const { isModalOpen } = useAppSelector((state) => state.modalState);
	const { selectedPlaylist, playlistFolder } = useAppSelector(
		(state) => state.playlist
	);
	const playlistSongsListRef = useRef();

	useEffect(() => {
		void dispatch(handleGetPlaylistFolder({}));
		void dispatch(handleGetSongs({}));
	}, []);

	const [isOpenDialog, setOpenMode] = useState<PlayListDialog>(false);
	const [totalSongs, setTotalSongs] = useState<number>(playlistSongs.length);
	const [totalSize, setTotalSize] = useState<number>(0);
	const [totalDuration, setTotalDuration] = useState<any>(0);
	const [playlistSongsList, setPlaylistSongsList] = useState<any>(playlistSongs);
	const [rowSelection, setRowSelection] = useState({});
	const [selectedRowsV2, setSelectedRowsV2] = useState<any[]>([]);
	const [isOpenSongForm, setSongForm] = useState<boolean>(false);
	const [preFillData, setPreFillData] = useState<SongTableDataType>({id:0});
	const [activeButton, setActiveButton] = useState('');

	const arr: string[] = [];

	const [focused, setFocus] = useState(-1);

	useEffect(() => {
		setPlaylistSongsList(playlistSongs);
		setTotalSize(0);
		playlistSongs.length > 0 &&
			playlistSongs.map((item: { song: { duration: any; size: number } }) => {
				setTotalSize((totalSize) => totalSize + item.song.size);
				return arr.push(item.song.duration);
			});
		setTotalDuration(totalTime(arr));
		setTotalSongs(playlistSongs.length);
	}, [playlistSongs]);
	const handleClose = (): void => {
		setOpenMode(false);
	};
	const addPlaylistModalOpen = (): void => {
		setOpenMode(true);
	};

	const [isOpenConfirmation, setConfirmation] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [clickPosition, setClickPosition] = useState<{
		x: number;
		y: number;
	} | null>(null);
	const [isFromFolder, setIsFromFolder] = useState<boolean>(false);

	const [isOpenCreateAndRename, setIsOpenCreateAndRename] =
		useState<boolean>(false);
	const [contextMenuFolder, setContextMenuFolder] = useState({});
	const [isNew, setIsNew] = useState<boolean>(false);
	const [orderBy, setOrderBy] = useState<string>('createdAt');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
	const [selectedRows] = useState<any[]>([]);
	const [isDeleteBulkSong, setIsDeleteBulkSong] = useState(false);
	const [searchInput, setSearchInput] = useState<string>('');
	const [isPlaying, setIsPlaying] = useState(false);
	const [songItem, setSongItem] = useState<any>();
	const [rowId, setRowId] = useState(1);
	const [id, setId] = useState(0);
	const [currentPlaylistId, setCurrentPlaylistId] = useState<number>(0);
	const handleCloseConfirmation = (): void => {
		setConfirmation(false);
		setIsDeleteBulkSong(false);
		setIsOpenCreateAndRename(false);
	};

	useEffect(() => {
		setIsPlaying(isPlay);
		return () => {
			setPlay(false);
		};
	}, [isPlay]);

	const handleSelectSong = (id: number, songId: number): void => {
		setConfirmation(true);
		setFocus(songId);
		setCurrentSelectedSongId([songId]);
	};

	const songFormModalOpen = (data: SongTableDataType, id: number, playlistId: number): void => {
		setPreFillData(data);
		setCurrentPlaylistId(playlistId);
		setSongForm(true);
		setFocus(id);
		setActiveButton('open');
	};

	const handleClear = () => {
		setRowSelection({});
		setSelectedRowsV2([]);
		setSearchInput('');
		setSorting([]);
		setSortDirection('desc');
		setOrderBy('createdAt');
		setId(0);
	};

	const handleSelectionClear = () => {
		setRowSelection({});
		setSelectedRowsV2([]);
		setId(0);
	};

	const handleDeleteSongConfirm = (): void => {
		const data = {
			ids: currentSelectedSongId.toString()
		};
		dispatch(setLoadingState(true));
		void dispatch(
			handleDeleteBulkSongsFromPlaylist({
				body: data,
				playlistId: selectedPlaylist.playlistId,
				field: orderBy,
				sort: sortDirection.toLocaleUpperCase(),
				search: searchInput?.trim() || ''
			})
		);
		handleSelectionClear();
		setIsDeleteBulkSong(false);
		setConfirmation(false);
	};

	useEffect(() => {
		if (!isEmpty(selectedPlaylist.playlistId)) {
			dispatch(setLoadingState(true));
			void dispatch(
				handleGetPlaylistSongs({
					playlistId: selectedPlaylist.playlistId,
					field: orderBy,
					sort: sortDirection.toLocaleUpperCase(),
					search: searchInput?.trim() || ''
				})
			);
		}
	}, [sortDirection, orderBy]);

	useEffect(() => {
		if (!isOpenConfirmation) {
			setFocus(-1);
		}
	}, [isOpenConfirmation]);

	useEffect(() => {
		if (
			!isEmpty(selectedPlaylist.playlistId) &&
			playlistSongsListRef.current !== selectedPlaylist.playlistId
		) {
			setRowSelection({});
			setSearchInput('');
			playlistSongsListRef.current = selectedPlaylist.playlistId;
			dispatch(setLoadingState(true));
			dispatch(setPlaylistSongs([]));
			void dispatch(
				handleGetPlaylistSongs({
					playlistId: selectedPlaylist.playlistId,
					field: orderBy,
					sort: sortDirection.toLocaleUpperCase(),
					search: searchInput?.trim() || ''
				})
			);
		}
	}, [playlistSongsList, playlistSongsListRef, selectedPlaylist]);

	const CreateFolder = (e: {
		clientY: number;
		clientX: number;
		preventDefault: () => void;
		currentTarget: React.SetStateAction<HTMLElement | null>;
	}): any => {
		if (isModalOpen) return;
		e.preventDefault();
		setAnchorEl(e.currentTarget);
		setClickPosition({ x: e.clientX, y: e.clientY });
		return setContextMenuFolder;
	};

	const handleCloseMenu = (
		e: React.MouseEvent<HTMLLIElement | HTMLDivElement>
	): void => {
		setAnchorEl(null);
		e.preventDefault();
		e.stopPropagation();
	};

	const hanldeCreateFolder = (): void => {
		setAnchorEl(null);
		setIsFromFolder(true);
		setIsOpenCreateAndRename(true);
		setIsNew(true);
	};

	// const handlePlaylistSearch = (search: any): void => {
	// 	if (!isEmpty(selectedPlaylist.playlistId)) {
	// 		dispatch(setLoadingState(true));
	// 		void dispatch(
	// 			handleGetPlaylistSongs({
	// 				playlistId: selectedPlaylist.playlistId,
	// 				search: search.trim(),
	// 				sort: sortDirection.toUpperCase(),
	// 				field: orderBy
	// 			})
	// 		);
	// 		setRowSelection({});
	// 		setSelectedRowsV2([]);
	// 		setId(0);
	// 	}
	// };

	const handleCloseCreateAndRename = (): void => {
		setIsOpenCreateAndRename(false);
		dispatch(setPlaylistFilterEmpty(true));
	};

	const handleOnPlayPause = (bool: any): any => {
		setIsPlaying(bool);
	};
	const handleRowId = (id: any): any => {
		setRowId(id);
	};
	const togglePlayPause = (prev: any, id: any, row: any) => {
		setSongItem(row);
		if (rowId === id) {
			setIsPlaying((prev) => !prev);
			dispatch(setPlay(!isPlay));
		} else {
			setIsPlaying(true);
			dispatch(setPlay(true));
		}
		setRowId(id);
		dispatch(setSelectedSong(row));
	};
	const minSize = 40;
	const maxSize = 10000;

	useEffect(() => {
		return () => {
			dispatch(setSelectedSong(undefined));
		};
	}, []);

	const _songController = () => {
		return (
			<Box
				alignContent={'center'}
				width={'100%'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<AudioPlayer />{' '}
			</Box>
		);
	};

	const _renderSongController = () => {
		if (songItem !== undefined) {
			return _songController();
		}
	};
	const useKeyPress = (
		keys: any,
		callback: (event: any) => void,
		node = null
	) => {
		const callbackRef = useRef(callback);
		useLayoutEffect(() => {
			callbackRef.current = callback;
		});

		const handleKeyPress = useCallback(
			(event: { key: any }) => {
				if (keys.some((key: any) => event.key === key)) {
					callbackRef.current(event);
				}
			},
			[keys]
		);

		useEffect(() => {
			const targetNode = node ?? document;
			targetNode && targetNode.addEventListener('keydown', handleKeyPress);
			return () => {
				targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
			};
		}, [handleKeyPress, node]);
	};

	const onKeyPress = (event: any) => {
		if (event.shiftKey && event.key === ' ') {
			event.preventDefault();
			playlistSongsList?.length > 0 &&
				togglePlayPause(false, Number(id), playlistSongsList[id]?.song);
			return;
		}
		// if (event.key === ' ') {
		// 	event.preventDefault();
		// 	return;
		// }
		if (event.key === 's') {
			dispatch(setPlay(false));
		}
	};

	const handleRatingChange = (value: number, songId: number) => {
		handleSelectionClear();
		const data = {
			isSong: false,
			data: {
				rating: value
			},
			songId,
			playlistId: selectedPlaylist.playlistId,
			search: searchInput?.trim() || '',
			sort: sortDirection.toUpperCase(),
			field: orderBy
		};
		dispatch(setLoadingState(true));
		void dispatch(handleUpdateSongsRating(data));
	};

	useKeyPress([' ', 's'], onKeyPress);

	const [sorting, setSorting] = useState<mrtSortingState>([]);

	const handleSort = (property: string, isDesc: boolean): void => {
		setSortDirection(isDesc ? 'desc' : 'asc');
		setOrderBy(property);
		setRowSelection({});
		setSelectedRowsV2([]);
		setId(0);
	};

	useEffect(() => {
		if (sorting.length > 0) {
			handleSort(sorting[0]?.id, sorting[0]?.desc);
		}
	}, [sorting]);

	const tableInstanceRef = useRef<mrtTableInstance<any>>(null);
	const [isMultiDelete, setIsMultiDelete] = useState<boolean>(false);
	const [currentSelectedSongId, setCurrentSelectedSongId] = useState<number[]>(
		[]
	);
	const [isTagAssign, setIsTagAssign] = useState(false);

	const handleAction = (array: any, actionType: string): void => {
		switch (actionType) {
			case 'tagAssign':
				setIsTagAssign(true);
				break;
			case 'multiDeleteSongs':
				setIsMultiDelete(true);
				break;
			case 'removeSongsFromPlaylist':
				setConfirmation(true);
				break;
			default:
				break;
		}

		const latestSelectedSongIds = array.map((item: any): any => {
			return item?.song?.id;
		});

		setCurrentSelectedSongId(latestSelectedSongIds);
	};

	const handleCloseConfirmationForMultiDelete = (): void => {
		setIsMultiDelete(false);
		handleSelectionClear();
	};

	const handleMultiDelete = (): any => {
		const resetProps = {
			sort: sortDirection.toUpperCase(),
			field: orderBy,
			search: searchInput?.trim() || ''
		};
		const data = {
			playlistId: selectedPlaylist.playlistId,
			ids: currentSelectedSongId.toString(),
			resetProps,
			isSong: false
		};
		dispatch(setLoadingState(true));
		void dispatch(handleDeleteMultipleSong(data));
		handleCloseConfirmationForMultiDelete();
	};

	let totalSizeV2: number = 0;
	const getTotalDuration = (data: any) => {
		if (data.length > 0) {
			const arr: string[] = data.map((item: { duration: any; size: number }) => {
				totalSizeV2 = totalSizeV2 + item?.size;
				return item?.duration;
			});
			if (arr && arr.length > 0) {
				return totalTime(arr);
			}
			return [];
		}
		return [];
	};

	const selectedSong: any = useAppSelector(isSelectedSong);

	const { isLoading } = useAppSelector((state) => state.loading);

	return (
		<PlaylistWrapper>
			<Box className='playlist-inner'>
				<Split>
					<Box
						className={'music-folder-list'}
						onContextMenu={CreateFolder}
						style={{ width: '25%', minWidth: 350 }}
					>
						<MusicFolderList>
							<div
								style={{
									flexDirection: 'row',
									display: 'flex',
									justifyContent: 'space-between'
								}}
							>
								<PlaylistFolder />
							</div>
						</MusicFolderList>
					</Box>
					<div className='right-part' style={{ width: '75%', minWidth: 100 }}>
						<div className='overlay'></div>
						<div className='dropdrag-icon'>
							<img src={UploadFileIcon} alt='' />
							<p>Drag and Drop files</p>
						</div>
						{playlistFolder?.length > 0 && !isEmptyObject(selectedPlaylist) ? (
							<Box className='playlist-table'>
								<Grid item xs={12}>
									<Box>
										<PlaylistSearchHeader>
											{/* <TextField
												type='text'
												placeholder='Search'
												variant='outlined'
												size='small'
												className='search-field'
												value={searchInput}
												onChange={(e) => {
													handlePlaylistSearch(e.target.value);
													setSearchInput(e.target.value);
												}}
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>
															<img src={SearchIcon} alt='' />
														</InputAdornment>
													)
												}}
											/> */}
											<React.Fragment>
												<Box className='playlist-buttons-time'>
													<Box className='playlist-buttons'>
														<ButtonComponent
															variant='outlined'
															btnName='Assign tags'
															onClick={() => {
																handleAction(selectedRowsV2, 'tagAssign');
															}}
															sx={selectedRowsV2?.length === 0 ? { opacity: 0.6 } : {}}
															disabled={selectedRowsV2?.length === 0}
															startIcon={<AssignmentOutlinedIcon />}
														/>
														<ButtonComponent
															variant='outlined'
															btnName='Delete'
															onClick={() => {
																handleAction(selectedRowsV2, 'multiDeleteSongs');
															}}
															sx={selectedRowsV2?.length === 0 ? { opacity: 0.6 } : {}}
															disabled={selectedRowsV2?.length === 0}
															startIcon={<DeleteOutlineOutlinedIcon />}
														/>
														<ButtonComponent
															variant='outlined'
															btnName='Playlist'
															tooltipText='Remove songs from playlist'
															onClick={() => {
																handleAction(selectedRowsV2, 'removeSongsFromPlaylist');
															}}
															sx={selectedRowsV2?.length === 0 ? { opacity: 0.6 } : {}}
															disabled={selectedRowsV2?.length === 0}
															startIcon={<RemoveCircleOutlineOutlinedIcon />}
														/>
													</Box>
													{selectedRowsV2?.length > 0 && (
														<Box className='playlist-time'>
															<TimeDuration
																totalSongs={selectedRowsV2?.length}
																totalDuration={getTotalDuration(
																	selectedRowsV2?.map((e: any) => e.song)
																)}
																totalSize={totalSizeV2}
															/>
														</Box>
													)}
												</Box>
											</React.Fragment>
											<Box className='main-playlist-time'>
												<Box className='playlist-time'>
													<TimeDuration
														totalSongs={totalSongs}
														totalDuration={totalDuration}
														totalSize={totalSize}
													/>
												</Box>
												<ButtonComponent
													type='button'
													btnName='Add Song'
													variant='contained'
													startIcon={<img src={BtnAddIcon} alt='' />}
													onClick={addPlaylistModalOpen}
													disabled={isEmptyObject(selectedPlaylist)}
												/>
											</Box>
											<>
												{selectedRows.length > 0 && (
													<ButtonComponent
														type='button'
														btnName='Delete'
														variant='contained'
														startIcon={<img src={DeleteIcon} alt='' />}
														onClick={() => {
															handleAction(selectedRowsV2, 'multiDeleteSongs');
														}}
														className={
															!(playlistSongsList.length > 0)
																? 'in-active delete-button'
																: 'delete-button'
														}
													/>
												)}
											</>
										</PlaylistSearchHeader>
									</Box>
									{_renderSongController()}
									<Grid item xs={18}>
										<Box width={'100%'}>
											{isLoading ? (
												<Box
													sx={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center'
													}}
												>
													<CircularProgress sx={{ color: 'var(--primary)' }} size={50} />
												</Box>
											) : (
												<div className='playlist-table-wrapper'>
													<DataTable
														stateVar={isPlaying}
														rowId={rowId}
														getRowId={handleRowId}
														onPlayPause={handleOnPlayPause}
														setSelectedRows={setSelectedRowsV2}
														playlistId={selectedPlaylist.playlistId}
														setId={setId}
														sorting={sorting}
														setSorting={setSorting}
														enableRowNumbers={false}
														tableInstanceRef={tableInstanceRef}
														dataLength={playlistSongsList?.length - 1}
														columns={[
															{
																accessorFn: (row: string) => `${row} ${row}`,
																id: '#',
																header: '#',
																minSize,
																size: 42,
																maxSize,
																enableSorting: false,
																enableResizing: false,
																muiTableHeadCellProps: {
																	align: 'center'
																},
																Cell: ({ row }: any) => {
																	return (
																		<Box
																			sx={{
																				display: 'flex',
																				alignItems: 'center',
																				justifyContent: 'center',
																				gap: '0.2rem'
																			}}
																		>
																			<Box>
																				<IconButton
																					onClick={(e: any) => {
																						e.stopPropagation();
																						togglePlayPause(
																							false,
																							Number(row?.id),
																							row?.original?.song
																						);
																					}}
																					className='playIcon'
																					style={{ padding: '0', position: 'relative', top: '-1px' }}
																				>
																					{row?.original?.song?.id === selectedSong?.id &&
																					isPlaying ? (
																						<img style={{ width: '18px' }} src={PauseIcon} alt='' />
																					) : (
																						<img style={{ width: '12px' }} src={PlayIcon} alt='' />
																					)}
																				</IconButton>
																			</Box>
																		</Box>
																	);
																}
															},
															{
																accessorFn: (row: string) => `${row} ${row}`,
																id: 'id',
																header: '#',
																minSize,
																size: 42,
																maxSize,
																enableSorting: false,
																enableResizing: false,
																muiTableHeadCellProps: {
																	align: 'center'
																},
																Cell: ({ row }: any) => {
																	return (
																		<Box
																			sx={{
																				display: 'flex',
																				alignItems: 'center',
																				justifyContent: 'center',
																				gap: '0.2rem'
																			}}
																		>
																			<Box>
																				<Typography>{Number(row?.id) + 1}</Typography>
																			</Box>
																		</Box>
																	);
																}
															},
															{
																accessorKey: 'song.artist',
																id: 'artist',
																header: 'Artist',
																minSize,
																size: 300,
																maxSize
															},
															{
																accessorKey: 'song.title',
																id: 'title',
																header: 'Title',
																minSize,
																size: 300,
																maxSize
															},
															{
																accessorFn: ({ row }: any) => `${row} ${row}`,
																id: 'tags',
																header: 'Tags',
																minSize,
																size: 200,
																maxSize,
																enableSorting: false,
																Cell: ({ row }: any) => (
																	<Box
																		sx={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '1rem'
																		}}
																	>
																		<Stack direction='row' spacing={1}>
																			{row?.original?.song?.tags?.map(
																				(
																					itemName:
																						| string
																						| number
																						| boolean
																						| React.ReactElement<
																								any,
																								string | React.JSXElementConstructor<any>
																						  >
																						| React.ReactFragment
																						| React.ReactPortal
																						| null
																						| undefined,
																					index: React.Key | null | undefined
																				) => (
																					<Chip
																						sx={{ background: '#F2A892' }}
																						key={index}
																						label={itemName}
																					/>
																				)
																			)}
																		</Stack>
																	</Box>
																)
															},
															{
																accessorFn: ({ row }: any) => `${row} ${row}`,
																id: 'duration',
																header: 'Duration',
																size: 60,
																minSize,
																maxSize,
																Cell: ({ row }: any) => (
																	<Box
																		sx={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '1rem'
																		}}
																	>
																		<Typography sx={{ fontSize: '14px' }}>
																			{convertToMinutesSeconds(row?.original?.song.duration)}
																		</Typography>
																	</Box>
																)
															},
															{
																accessorKey: 'createdAt',
																id: 'createdAt',
																header: 'Date',
																enableResizing: false,
																Cell: ({ row }: any) => (
																	<Box
																		sx={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '1rem'
																		}}
																	>
																		<Typography sx={{ fontSize: '14px' }}>
																			{dayjs
																				.default(row.original?.song?.createdAt)
																				.format('YYYY-MM-DD')}
																		</Typography>
																	</Box>
																)
															},
															{
																accessorKey: 'createdAt',
																id: 'createdAt',
																header: 'Time',
																size: 75,
																enableResizing: false,
																Cell: ({ row }: any) => (
																	<Box
																		sx={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '1rem'
																		}}
																	>
																		<Typography sx={{ fontSize: '14px' }}>
																			{dayjs
																				.default(row.original?.song?.createdAt)
																				.format('hh:mm A')}
																		</Typography>
																	</Box>
																)
															},
															{
																accessorFn: (row: any) => `${row} ${row}`,
																id: 'rating',
																header: 'Ratings',
																minSize,
																size: 100,
																maxSize,
																enableResizing: false,
																Cell: ({ row }: any) => (
																	<Box
																		sx={{
																			display: 'flex',
																			alignItems: 'center',

																			gap: '1rem'
																		}}
																	>
																		<Typography>
																			<RatingComponent
																				currentValue={Number(row?.original?.song?.rating)}
																				songId={Number(row?.original?.song?.id)}
																				handleRatingChange={handleRatingChange}
																			/>
																		</Typography>
																	</Box>
																)
															},
															{
																accessorFn: ({ row }: any) => `${row} ${row}`,
																id: 'actions',
																header: 'Action',
																minSize,
																size: 70,
																maxSize,
																enableSorting: false,
																enableResizing: false,
																Cell: ({ row }: any) => (
																	<Box
																		sx={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '1rem'
																		}}
																	>
																		<div className='btn-wrapper'>
																			<IconButton
																				onClick={(e: any) => {
																					e.stopPropagation();
																					songFormModalOpen(row?.original?.song, Number(row?.original?.id), Number(row?.original?.playlistId));
																				}}
																				className={
																					activeButton === 'open' && focused === row.id
																						? 'is-active'
																						: 'table-button'
																				}
																			>
																				<img src={EditNoteIcon} alt='' />
																			</IconButton>
																			<IconButton
																				className='delete-btn table-button'
																				onClick={(e: any) => {
																					e.stopPropagation();
																					handleSelectSong(
																						row?.original?.song?.id,
																						Number(row?.original?.song?.id)
																					);
																				}}
																			>
																				<img src={DeleteIcon} alt='Delete' />
																			</IconButton>
																		</div>
																	</Box>
																)
															}
														]}
														data={playlistSongs}
														setRowSelection={setRowSelection}
														rowSelection={rowSelection}
														sortDirection={sortDirection}
														orderBy={orderBy}
														searchInput={searchInput}
													/>
												</div>
											)}
										</Box>
									</Grid>
								</Grid>
							</Box>
						) : (
							<div className='container'>
								<p className='text-center-null-val'>
									Please {playlistFolder?.length > 0 ? 'Select' : 'Create'} Playlist
								</p>
							</div>
						)}
						<Menu
							id='basic-menu'
							className='custom-info-menu'
							anchorEl={anchorEl}
							MenuListProps={{
								'aria-labelledby': 'basic-button'
							}}
							onClose={handleCloseMenu}
							onClick={hanldeCreateFolder}
							open={open}
							anchorReference='anchorPosition'
							anchorPosition={
								clickPosition != null
									? { top: clickPosition.y, left: clickPosition.x }
									: undefined
							}
						>
							<MenuItem>Create Folder</MenuItem>
						</Menu>
						<Dialog
							open={isOpenDialog}
							onClose={handleClose}
							fullWidth={true}
							maxWidth={'sm'}
						>
							<DialogContent>
								<AddPlayListSongs setOpenMode={setOpenMode} handleClear={handleClear} />
							</DialogContent>
						</Dialog>
						<Dialog
							open={isOpenSongForm}
							onClose={handleClose}
							fullWidth={true}
							maxWidth={'sm'}
							className='client-dialog-box'
						>
							<SongModal>
								<DialogContent>
									<SongForm
										setSongForm={setSongForm}
										preFillData={preFillData}
										resetProps={{
											sort: sortDirection.toUpperCase(),
											field: orderBy,
											search: searchInput?.trim() || '',
										}}
										orderBy={orderBy}
										sortDirection={sortDirection}
										isFromPlaylist={true}
										playlistId={currentPlaylistId}
										handleClear={handleSelectionClear} page={0} alignment={false}									/>
								</DialogContent>
							</SongModal>
						</Dialog>
						<Dialog
							open={isOpenCreateAndRename}
							onClose={handleCloseCreateAndRename}
							aria-labelledby='responsive-dialog-title'
						>
							<DialogContent>
								<CreateAndRename
									setIsOpenCreateAndRename={setIsOpenCreateAndRename}
									selectedContextFolder={contextMenuFolder}
									selectedPlaylist={selectedPlaylist}
									isNew={isNew}
									isFromFolder={isFromFolder}
									onClose={handleCloseCreateAndRename}
								/>
							</DialogContent>
						</Dialog>
						<Dialog
							open={isOpenConfirmation}
							onClose={handleCloseConfirmation}
							aria-labelledby='responsive-dialog-title'
						>
							<Confirmation
								setConfirmation={setConfirmation}
								handleConfirmation={handleDeleteSongConfirm}
								message={isDeleteBulkSong ? 'Songs' : 'Song'}
							/>
						</Dialog>
						<SongModal>
							<Dialog
								open={isMultiDelete}
								onClose={handleCloseConfirmationForMultiDelete}
								aria-labelledby='responsive-dialog-title'
							>
								<Confirmation
									setConfirmation={setIsMultiDelete}
									handleConfirmation={handleMultiDelete}
									message='songs'
								/>
							</Dialog>
						</SongModal>
						<Dialog
							open={isTagAssign}
							onClose={() => {
								setIsTagAssign(false);
							}}
							fullWidth={true}
							maxWidth={'sm'}
							className='client-dialog-box'
						>
							<SongModal>
								<DialogContent>
									<AssignTagForm
										setIsTagAssign={setIsTagAssign}
										songIds={currentSelectedSongId}
										page={1}
										resetProps={{
											playlistId: selectedPlaylist.playlistId,
											sort: sortDirection.toUpperCase(),
											field: orderBy,
											search: searchInput?.trim() || ''
										}}
										orderBy={orderBy}
										sortDirection={sortDirection}
										alignment={false}
										handleClear={handleSelectionClear}
										isSong={false}
									/>
								</DialogContent>
							</SongModal>
						</Dialog>
					</div>
				</Split>
			</Box>
		</PlaylistWrapper>
	);
};

export default PlayList;
