import React, { type FC } from 'react';
import { type ConfirmationProps } from '../modals';
import { Box, Typography , IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ButtonComponent from './ButtonComponent';

const Confirmation: FC<ConfirmationProps>  = ( { setConfirmation , handleConfirmation , message } ) => {

    return(
        <>
        <div className='confirmation-modal'>
            <div className='confirmation-title'>
                <Typography variant="h5" >Confirmation</Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => {setConfirmation(false);}}
                    className="popup-close-btn"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className='confirmation-body'>
                <Typography>Are you sure you want to delete this {message}?</Typography>
                <Box className="modal-buttons">
                    <ButtonComponent
                        variant="outlined"
                        btnName="No"
                        onClick={() => {setConfirmation(false);}}
                    />
                    <ButtonComponent
                        type="button"
                        variant="contained"
                        btnName="Yes"
                        onClick={handleConfirmation}
                    />
                </Box>
            </div>
        </div>
        </>
    );
};

export default Confirmation;