import styled from 'styled-components';
import '../../style/main.scss';


export const ProfileFormWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0px;
  padding: 20px;
`;

export const ProfileBox = styled.div`
  max-width:550px;
  margin:auto;
  width: 100%;
  margin-top:10px;

  .profile-header{
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    
    h5{
      margin-bottom: 20px;
      font-size:18px;
    }
    img{
      margin-top: 5px;
    }
  }

  .input-wrapper{
    display: grid;
    grid-template-columns: 1fr 5fr;
    margin-bottom: 20px;
    align-items:center;

    input{
      background-color:var(--darkerBgColor);
      padding:10px 20px;
      border-radius: 10px;
      font-size: 14px;
    }
  }

  .MuiBox-root{

    button{
      width: 105px;
      text-transform:capitalize;
      margin-left: 20px;
    }
  }
`;