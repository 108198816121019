import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { type LoadingState } from '../../modals';

const initialState: LoadingState = {
    isLoading: false,
    isLoadingV2: false,
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoadingState: (state, action: PayloadAction<any>) => {
            state.isLoading = action.payload;
        },
        setLoadingStateV2: (state, action: PayloadAction<any>) => {
            state.isLoadingV2 = action.payload;
        },
    },
});

export const { setLoadingState, setLoadingStateV2 } = loadingSlice.actions;

export const isLoading = (state: RootState): boolean => state.loading.isLoading;

export default loadingSlice.reducer;
