import React, { useEffect, type FC } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { socket } from '../socket';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
	setCount,
	setTotalCount,
	setProgress
} from '../redux/slice/progressBarSlice';

const LinearProgressWithLabel: FC = () => {
	const dispatch = useAppDispatch();
	const { count, progress, totalCount } = useAppSelector(
		(state) => state.progressBar
	);

	const handleUploadProgress = (count: number, totalCount: number): any => {
		dispatch(setCount(count));
		dispatch(setTotalCount(totalCount));
		dispatch(setProgress(Math.floor((count / totalCount) * 100)));
	  };
  
	  const handleDriveUploadProgress = (count: number, totalCount: number): any => {
		dispatch(setCount(count));
		dispatch(setTotalCount(totalCount));
		dispatch(setProgress(Math.floor((count / totalCount) * 100)));
	  };
  
	  const handleTotalCount = (totalCount: any): any => {
		dispatch(setTotalCount(totalCount));
	  };

	useEffect(() => {
		socket.connect();
		socket.on('upload-progress', handleUploadProgress);
		socket.on('drive-upload-progress', handleDriveUploadProgress);
		socket.on('total-count', handleTotalCount);


		return () => {
			socket.off('upload-progress', handleUploadProgress);
			socket.off('drive-upload-progress', handleDriveUploadProgress);
			socket.off('total-count', handleTotalCount);
		  };
	
	}, []);

	return (
		<Box className='linear-progess-bar'>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress
					variant='determinate'
					value={progress}
					color='primary'
					sx={{
						height: 8
					}}
				/>
			</Box>
			<Box className='linear-progress-bar-info'>
				<Box>
					<Typography
						variant='body2'
						color='text.secondary'
						sx={{
							fontSize: 20,
							fontWeight: 'bold'
						}}
					>
						{`Uploaded ${String(count)} out of ${String(
							totalCount
						)} files (${Math.round(progress)}%)`}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
export default LinearProgressWithLabel;
