/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState, type FC, } from 'react';
import { Formik, Form, type FormikProps,  } from 'formik';
import { ScheduleFormSchema } from './validation';
import { type ScheduleFormProps, type ScheduleFormInterface } from '../../modals';
import { ChromePicker } from 'react-color';
import dayjs, { type Dayjs } from 'dayjs';
import ButtonComponent from '../../components/ButtonComponent';
import InputComponent from '../../components/InputComponent';
import SelectComponent from '../../components/SelectComponent';
import ErrorMsgComponent from '../../components/ErrorMsgComponent'; 
import CustomRepeatModal from './CustomRepeatModal';
import { Box, TextField, Typography, IconButton , Popper, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarModal, CalendarModalHeader, CalendarModalBody, CalendarInnerWrapper } from './style';
import Popover from '@mui/material/Popover';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { handleCreateSchedule, handleDeleteSchedule, handleEditSchedule, handleGetScheduleFromId } from '../../redux/actions/scheduleAction';
import moment from 'moment';
import { isEmpty } from '../../utils/validators';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import duration from 'dayjs/plugin/duration';
import { setPlaylistSongs } from '../../redux/slice/playlistSongSlice';
import CustomTimePicker from './CustomTimePicker';
import checkIcon from '../../assets/images/icons/checkbox-none.svg';
import checkIconFill from '../../assets/images/icons/checkbox-checked.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import PreviewButton from '../../components/PreviewButtonV2';

dayjs.extend(dayOfYear);
dayjs.extend(duration);

const ScheduleForm: FC<ScheduleFormProps> = ({
    isNew,
    setOpenScheduleForm,
    getScheduleList,
    isEdit,
    selectData,
    clientId,
    scheduleList
}) => {
    // Color picker
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
    const [selectColor, setChangeColor] = useState(isEdit ? '#ff0000' : '#8E2734');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [clickPosition, setClickPosition] = useState<{ x: number, y: number } | null>(null);
    const dispatch = useAppDispatch();
    const { playlist } = useAppSelector((state) => state.playlist);
    const { scheduleFromId } = useAppSelector((state) => state.schedule);
    const scheduleId = selectData?.event?._def.extendedProps?.scheduleId;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [repeat, setRepeat] = useState<any>('');
    const [isShuffle, setIsShuffle] = useState<boolean>(true);
    const [formikValue, setFormikValue] = useState<any>();
    const [titleName, setTitleName] = useState<any>();
    const [selectedPlaylist, setSelectedPlaylist] = useState<any>('');
    const [startTimeCustomPicker, setStartTimeCustomPicker] = useState<boolean>(false);
    const [endTimeCustomPicker, setEndTimeCustomPicker] = useState<boolean>(false);

    const [anchorElDelete, setAnchorElDelete] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorElDelete);
    const customId = open ? 'simple-popper' : undefined;

    // PlayList selection
    const playListInfo = playlist?.map((item: {id: any, name: any; }) => {
        return {name: item.name, id: item.id};
    });

    useEffect(() => {
        const playlistName = playlist?.filter((item: { id: any; }) => {
            return item.id === formikValue;
        });

        setSelectedPlaylist(formikValue);
        setTitleName(playlistName[0]?.name);
    }, [formikValue]);

    useEffect(() => {
        if (isEdit && !isEmpty(scheduleId)) {
            void dispatch(handleGetScheduleFromId(scheduleId));
        }
    }, [scheduleId]);

    useEffect(() => {
        if (scheduleFromId !== null && isEdit) {
            setChangeColor(scheduleFromId.color);
            setSelectedPlaylist(scheduleFromId.playlistId);
            setRepeat(scheduleFromId.repeat);
            setTitleName(scheduleFromId.title);
            setIsShuffle(scheduleFromId.isShuffle);
            setStartTimeValue(convertToString(scheduleFromId.startTime));
            setEndTimeValue(convertToString(scheduleFromId.endTime));
            setDateValue(scheduleFromId.startDate);
            setEndDateValue(scheduleFromId?.endDate);
            setEndsValue(isEmpty(scheduleFromId.endOn) ? 'never' : scheduleFromId.endOn);
        };

        return () => {
            setAnchorElDelete(null);
        };
    }, [scheduleFromId]);

    const colorPickerHandle = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
        setShowColorPicker(true);
        setClickPosition({ x: event.clientX, y: event.clientY });
    };

    const handleClose = (): void => {
        setShowColorPicker(false);
    };

    const changeColorOption = (color: any): void => {
        setChangeColor(color.hex);
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const convertToString = (value: string) => {
        const items: string [] = value.split(':') ?? [];
        items.splice(2, 1);
        return items.join(':');
    };
    
    // Date picker
    const [dateValue, setDateValue] = useState<any | null>(isEdit ? (scheduleFromId ? scheduleFromId.startDate : selectData.startStr) : selectData?.startStr);
    const [endDateValue, setEndDateValue] = useState<Dayjs | null>(isEdit ? (scheduleFromId ? scheduleFromId.endDate : selectData.endStr) : selectData?.endStr);
    const [startTimeValue, setStartTimeValue] = useState<any | null>(
        isEdit ? (
            scheduleFromId ? convertToString(scheduleFromId.startTime) : convertToString(selectData.event.startStr)
        ) : (
            (selectData == null) ? null : (
                selectData?.allDay ? null : dayjs(selectData?.startStr).format('HH:mm')
            )
        )
    );
    const [endTimeValue, setEndTimeValue] = useState<any | null>(
        isEdit ? (
            scheduleFromId ? convertToString(scheduleFromId.endTime) : convertToString(selectData.event.endStr)
        ) : (
            (selectData == null) ? null : (
                selectData?.allDay ? null : dayjs(selectData?.endStr).format('HH:mm')
            )
        )
    ); // (scheduleFromId ? scheduleFromId.endTime : selectData.event.endStr)
    const [, setOpenCustomRepeatModal] = useState<boolean>(false);

    // Ends
    const [endsValue, setEndsValue] = useState<string>('never');
    // const [repeatValue, setRepeatValue] = useState<string>('none');
    const [afterValue, setAfterValue] = useState<number>(scheduleFromId?.occurrences ? scheduleFromId?.occurrences : 1);
    // const handleRepeatChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
    //     setRepeatValue(event.target.value);
    // };
    const handleEndChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
        setEndsValue(event.target.value);
    };

    // Formik 
    const initialValues: ScheduleFormInterface = {
        title: titleName || '', // isNew ? (titleName || '') : (isEdit ? selectData.event.title : ''),
        playList: isEdit ? selectedPlaylist : (selectedPlaylist ?? ''), // isNew ? 30 : (isEdit ? prePlaylist : ''), // (selectedPlaylist.current ?? '')
        timezone: '',
        repeat: isNew ? 'everyDay' : (isEdit ? repeat : 'everyDay'),
        startTime: startTimeValue,
        endTime: endTimeValue,
    };

    const validationSchema = ScheduleFormSchema;

    const getRRuleString = (
        freqType: string,
        count: string,
        byMonthDay: string,
        byYearDate: string,
        byDay: string,
        dtStart: string,
        dtEnd: string,
    ): string => {
        let str = `FREQ=${freqType};`;
        if (freqType === 'MONTHLY') {
            str += `BYMONTHDAY=${byMonthDay};DTSTART=${dayjs(dtStart).format('YYYYMMDD')};`;
        } else if (freqType === 'YEARLY') {
            str += `DTSTART=${byYearDate};`;
        } else if (freqType === 'DAILY') {
            str += `DTSTART=${dayjs(dtStart).format('YYYYMMDD')};`;
        } else if (freqType === 'WEEKLY') {
            str += `BYDAY=${byDay};DTSTART=${dayjs(dtStart).format('YYYYMMDD')};`;
        } else if (freqType === 'none') {
            return `FREQ=DAILY;COUNT=1;DTSTART=${dayjs(dtStart).format('YYYYMMDD')}`;
        }
        if (endsValue === 'on') {
            str += `DTSTART=${dayjs(dtStart).format('YYYYMMDD')};UNTIL=${dayjs(dtEnd).format('YYYYMMDDThhmmss')};`;
        }
        str += `COUNT=${count}`;
        return str;
        // return `FREQ=${freqType};${freqType === 'MONTHLY' ? `BYMONTHDAY=${byMonthDay}` : freqType === 'YEARLY' ? `BYYEARDAY=${byMonthDay}` : `BYDAY=${byDay}`};COUNT=${count}`;
    };

    const getPrefixString = (array: any): any => {
        const storePrefix: any[] = [];
        // eslint-disable-next-line array-callback-return
        array.map((item: any) => {
            storePrefix.push(item.slice(0, 2).toLocaleUpperCase());
        });
        return storePrefix.toString();
    };

    const handleSubmitForm = (values: ScheduleFormInterface, freqType: any, dayType: any): void => {
        const byDay = customRepeatValue.length > 0 ? getPrefixString(customRepeatValue) : dayType;
        const count = endsValue === 'after' && !isEmpty(afterValue) && afterValue > 1 ? afterValue : 40;
        let endDate: any = values.repeat === 'none' ? dayjs(new Date()).format('YYYY-MM-DD') : dayjs(endDateValue).format('YYYY-MM-DD');

        if (endsValue === 'never') {
            endDate = null;
        }
        const byMonthDay = dayjs(dateValue).format('DD');
        const byYearDate = dayjs(dateValue).format('YYYYMMDD');
        const startDateValue = isEdit && values.repeat === 'none' ? selectData?.event?._def.extendedProps?.date : dayjs(dateValue).format('YYYY-MM-DD');
        const scheduleFormData: any = {
            title: values.title,
            playlistId: values.playList,
            startDate: startDateValue,
            startTime: startTimeValue,
            endTime: endTimeValue === '00:00' ? '23:59' : endTimeValue,
            endDate,
            timezone: values.timezone,
            repeat: values.repeat,
            day: customRepeatValue.toString(),
            ends: endsValue,
            color: selectColor,
            isShuffle,
            // occurrences: afterValue,
            clientId,
            recurrenceRule: getRRuleString(
                freqType,
                count.toString(),
                byMonthDay,
                byYearDate,
                byDay,
                startDateValue,
                dayjs(endDateValue).format()
            ),
            recurrenceStartDate: startDateValue,
            type: 'month'
        };

        // console.log({ scheduleFormData });
        // return;
        void dispatch(setPlaylistSongs([]));
        if (isEdit) {
            void dispatch(handleEditSchedule({scheduleId, scheduleFormData, getScheduleList}));
            setIsLoading(false);
            return;
        }
        

        void dispatch(handleCreateSchedule(scheduleFormData));
        setIsLoading(false);
    };

    const handleSubmit = async (values: ScheduleFormInterface): Promise<void> => {
        if (startDateError || pastStartDateError || pastEndDateError || endDateError || startTimeError || endTimeError) {
            return;
        }
        setRepeat(values.repeat);
        const freqType = await handleRepeat(values.repeat);
        const dayType = moment(dateValue).format('dd').toUpperCase();
        // setIsLoading(true);
        handleSubmitForm(values, freqType, dayType);
    };

    // Error
    const [startDateError, setStartDateError] = useState<boolean>(false);
    const [pastStartDateError, setPastStartDateError] = useState<boolean>(false);
    const [pastEndDateError, setPastEndDateError] = useState<boolean>(false);
    const [endDateError, setEndDateError] = useState<boolean>(false);
    const [startTimeError, setStartTimeError] = useState<boolean>(false);
    const [endTimeError, setEndTimeError] = useState<boolean>(false);
    const [customRepeatValue, setCustomRepeatValue] = useState<[]>([]);
    
    const beforSubmitData = (): void => {
        if (isEdit) {
            return;
        }
        const currentDate = dayjs();
        const selectedDateObj = dayjs(dateValue);
        const selectedEndDateObj = dayjs(endDateValue);
        (dateValue === null) ? setStartDateError(true) : setStartDateError(false);
        (selectedDateObj.isBefore(currentDate, 'day')) ? setPastStartDateError(true) : setPastStartDateError(false);
        (selectedEndDateObj.isBefore(currentDate, 'day')) ? setPastEndDateError(true) : setPastEndDateError(false);
        (startTimeValue === null) ? setStartTimeError(true) : setStartTimeError(false);
        (endTimeValue === null) ? setEndTimeError(true) : setEndTimeError(false);
        (endDateValue === null && endsValue === 'on') ? setEndDateError(true) : setEndDateError(false);
    };

    const handleRepeat = (type: any): any => {
        switch(type) {
            case 'everyDay':
                return 'DAILY';
            case 'everyWeek':
                return 'WEEKLY';
            case 'custom':
                return 'WEEKLY';
            default:
                return 'none';
        }
    };

    const getDiffHour = (): any => {
        if (!endTimeValue || !startTimeValue) {
            return null;
        }

        // Split the times into hours and minutes
        const start = startTimeValue.split(':');
        const end = endTimeValue.split(':');

        // Create date objects with the same date (use the current date)
        const startDate = new Date();
        startDate.setHours(start[0], start[1], 0); // Set start time
        const endDate = new Date();
        endDate.setHours(end[0], end[1], 0); // Set end time

        // Calculate the time difference in milliseconds
        const diff = endDate.getTime() - startDate.getTime();

        // Calculate the difference in hours and minutes
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff / (1000 * 60)) % 60);

        let str = '';

        if (hours === 0 && minutes === 0) {
            str = '0 min';
        }
        if (hours > 0) {
            str += `${hours} Hr`;
        }
        if (minutes > 0) {
            str += ` ${Math.floor(minutes)}  min`;
        }

        return (
            <Typography>({str})</Typography>
        );
    };

    const todayDate = dayjs();

    return (
            <CalendarModal key={selectData?.event?._def.extendedProps?.scheduleId} className="calender-modal">
                <CalendarModalHeader>
                    <Box className="schedule-header">
                        <Typography variant="h5" >{isEdit ? 'Edit Schedule' : 'Add Schedule'}</Typography>
                        <Box className="two-col-grid">
                            <IconButton className={isShuffle ? 'sync-alt-icon active' : 'sync-alt-icon'}
                                onClick={() => { setIsShuffle(!isShuffle); }}
                            >
                                <img src={checkIcon} alt='' />
                                <img src={checkIconFill} alt='' />
                            </IconButton>
                            <Typography variant="h6" >Shuffle</Typography>
                        </Box>
                    </Box>
                </CalendarModalHeader>
                <CalendarModalBody>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    >
                        {(props: FormikProps<any>) => {
                            const { values, errors, touched, handleChange, handleBlur } = props;
                            return (
                                <Form>
                                    <Box sx={{ textAlign: 'right', p: '20px' }} className='bottom-buttons'>
                                        <span className='calendar-hours'>{getDiffHour()}</span>
                                        {
                                            isEdit && (
                                                <>
                                                    <ButtonComponent
                                                        describedBy={customId}
                                                        variant="outlined"
                                                        className="delete-btn"
                                                        btnName={<DeleteIcon/>}
                                                        onClick={(e) => {
                                                            setAnchorElDelete(anchorElDelete ? null : e.currentTarget);
                                                        }}
                                                    />
                                                    <Popper
                                                        id={customId}
                                                        open={open}
                                                        style={{
                                                            zIndex: 9999
                                                        }}
                                                        anchorEl={anchorElDelete}
                                                    >
                                                        <Box className="alert-component-calendar">
                                                            <div className='calendar-wrapper'>
                                                                <Box>
                                                                    Are you sure you want to delete the schedule ?
                                                                </Box>
                                                                <Box>
                                                                    <ButtonComponent
                                                                        variant="outlined"
                                                                        btnName="No"
                                                                        onClick={() => { 
                                                                            setAnchorElDelete(null);
                                                                        }}
                                                                    />
                                                                    <ButtonComponent
                                                                        variant="contained"
                                                                        btnName="Yes"
                                                                        onClick={() => { 
                                                                            void dispatch(handleDeleteSchedule({ scheduleId, clientId, getScheduleList }));
                                                                            setAnchorElDelete(null);
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </Box>
                                                    </Popper>
                                                </>
                                            )
                                        }
                                        
                                        <ButtonComponent
                                            variant="outlined"
                                            btnName="Cancel"
                                            onClick={() => { void dispatch(setPlaylistSongs([])); setOpenScheduleForm(false); }}
                                        />
                                        <ButtonComponent
                                            type="submit"
                                            variant="contained"
                                            btnName={isEdit ? 'Update' : 'Save'}
                                            onClick={beforSubmitData}
                                            loading={isLoading}
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    <CalendarInnerWrapper>
                                        <Box className="two-col-grid">
                                            <Typography variant="h6" >Title</Typography>
                                            <InputComponent
                                                id="title"
                                                name="title"
                                                value={values.title} // //  ?? values.title
                                                placeholder="Title"
                                                type="text"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched.title) && Boolean(errors.title !== null)}
                                                helperText={Boolean(touched.title) && errors.title}
                                                required
                                            />
                                            <Box
                                                sx={{
                                                    backgroundColor: !isEmpty(selectColor) ? selectColor : '#8E2734'
                                                }}
                                                className="absolute-selector"
                                                onClick={colorPickerHandle}
                                            >
                                            </Box>
                                            {showColorPicker ?
                                                <Popover
                                                    open={showColorPicker}
                                                    id="colorPopup"
                                                    onClose={handleClose}
                                                    anchorEl={anchorEl}
                                                    className="colorpicker-box"
                                                    anchorReference="anchorPosition"
                                                    anchorPosition={(clickPosition != null) ? { top: clickPosition.y, left: clickPosition.x } : undefined}
                                                >
                                                    <ChromePicker
                                                        onChange={changeColorOption}
                                                        color={selectColor}
                                                    />
                                                    <ButtonComponent
                                                        variant="contained"
                                                        btnName="Close"
                                                        onClick={handleClose}
                                                    />
                                                </Popover>
                                                : null
                                            }
                                        </Box>
                                        {/* <Box className="two-col-grid">
                                            <Typography variant="h6" >Shuffle</Typography>
                                            <IconButton className={isShuffle ? 'sync-alt-icon active' : 'sync-alt-icon'}
                                                onClick={() => { setIsShuffle(!isShuffle); }}
                                            >
                                                <img src={checkIcon} alt='' />
                                                <img src={checkIconFill} alt='' />
                                            </IconButton>
                                        </Box> */}
                                        <Box className="two-col-grid">
                                            <Typography variant="h6" >PlayList</Typography>
                                            <SelectComponent
                                                id="playList"
                                                name="playList"
                                                value={values.playList}
                                                placeholder="Play list"
                                                type="text"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                // onChange={handleChange}
                                                onchangeValue={(e) => { handleChange(e); setFormikValue(e.target.value);
                                                }}
                                                data={playListInfo}
                                                error={Boolean(touched.playList) && Boolean(errors.playList !== '')}
                                                helperText={Boolean(touched.playList) && errors.playList}
                                                required
                                            />
                                            {!isEmpty(values?.playList) && <Box
                                                className="absolute-selector"
                                            >
                                                <PreviewButton id={values?.playList}/>
                                            </Box>}
                                        </Box>
                                        <Box className="flexbox-inline">
                                            <Typography variant="h6" >Start Date</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <div className='form-inner-width'>
                                                    <DatePicker
                                                        value={dateValue}
                                                        minDate={todayDate}
                                                        onChange={(newValue: any) => { setDateValue(newValue); }}
                                                        renderInput={(params: any) => <TextField {...params} />}
                                                        inputFormat="DD/MM/YYYY"
                                                    />
                                                    {startDateError && <ErrorMsgComponent message="Start date required" />}
                                                    {pastStartDateError && <ErrorMsgComponent message="Please select future dates" />}
                                                </div>
                                                <div className='flex-inner-margin'>
                                                <div>
                                                    <TextField disabled={true}  value={values.startTime}/>
                                                </div>
                                                <span
                                                    className='btn-inner-custom'
                                                    onClick={() => {
                                                        if (isEdit) {
                                                            return;
                                                        }
                                                        setStartTimeCustomPicker(true);
                                                    }}
                                                >
                                                        <AccessTimeIcon />
                                                </span>
                                                {
                                                    startTimeCustomPicker && 
                                                    <CustomTimePicker 
                                                        value={values.startTime}
                                                        setStartTimeCustomPicker={setStartTimeCustomPicker} 
                                                        setEndTimeCustomPicker={setEndTimeCustomPicker}
                                                        setTimeValue={setStartTimeValue}
                                                        date={dateValue}
                                                    />
                                                }
                                                    {(Boolean(touched.startTime) && Boolean(errors.startTime)) && <ErrorMsgComponent message="Start time required" />}
                                                </div>
                                                <Typography
                                                    className=" small mt-top"
                                                >
                                                    To
                                                </Typography>
                                                <div className='flex-inner-margin'>
                                                <div>
                                                    <TextField disabled={true}  value={values.endTime}/>
                                                </div>
                                                <span className='btn-inner-custom'
                                                    onClick={() => { setEndTimeCustomPicker(true); }}
                                                >
                                                    <AccessTimeIcon />
                                                </span>
                                                {
                                                    endTimeCustomPicker && 
                                                    <CustomTimePicker 
                                                        value={values.endTime}
                                                        setStartTimeCustomPicker={setStartTimeCustomPicker} 
                                                        setEndTimeCustomPicker={setEndTimeCustomPicker}
                                                        setTimeValue={setEndTimeValue}
                                                        date={dateValue}
                                                    />
                                                }
                                                {(Boolean(touched.endTime) && Boolean(errors.endTime)) && <ErrorMsgComponent message="End time required" />}
                                                {/* <span className='calendar-hours'>{getDiffHour()}</span> */}
                                                </div>
                                            </LocalizationProvider>
                                        </Box>
                                        <Box className="two-col-grid">
                                            <Typography variant="h6" >Repeat</Typography>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="repeat"
                                                    value={values.repeat}
                                                    onChange={handleChange}
                                                    row
                                                >
                                                    <FormControlLabel value="none" control={<Radio />} label="None" />
                                                    <FormControlLabel value="everyDay" control={<Radio />} label="Every Day" />
                                                    <FormControlLabel value="everyWeek" control={<Radio />} label="Every Week" />
                                                    <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                        {
                                            !(customRepeatValue.length > 0) && (values.repeat === 'custom')
                                            && (
                                                <Typography className="left-text-calendar">{moment(dateValue).format('dddd')} will be selected by default</Typography>
                                            )
                                        }
                                        {
                                            values.repeat === 'custom' &&
                                            <CustomRepeatModal
                                                setOpenCustomRepeatModal={setOpenCustomRepeatModal}
                                                customRepeatValue={customRepeatValue}
                                                setCustomRepeatValue={setCustomRepeatValue}
                                                scheduleFromId={isEdit ? scheduleFromId : false}
                                            />
                                        }
                                        {
                                            values.repeat !== 'none' &&
                                            <Box className="two-col-grid end-radio-btn">
                                            <Typography variant="h6" >Ends</Typography>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                    value={endsValue}
                                                    onChange={handleEndChange}
                                                >
                                                    <FormControlLabel value="never" control={<Radio />} label="Never" />
                                                    <Box sx={{ display: 'flex' }}>
                                                        <FormControlLabel value="on" control={<Radio />} label="On" />
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                value={endDateValue}
                                                                minDate={todayDate}
                                                                onChange={(newValue: any) => { setEndDateValue(newValue); }}
                                                                renderInput={(params: any) => <TextField {...params} />}
                                                                disabled={endsValue !== 'on'}
                                                                inputFormat="DD/MM/YYYY"
                                                            />
                                                            {endDateError && <ErrorMsgComponent message="Date is required" />}
                                                            {pastEndDateError && <ErrorMsgComponent message="Please select future dates" />}
                                                        </LocalizationProvider>
                                                    </Box>
                                                    <Box className="occurrences">
                                                        <FormControlLabel value="after" control={<Radio />} label="After" />
                                                        <TextField
                                                            type='number'
                                                            value={afterValue}
                                                            onChange={(e: any) => { setAfterValue(e.target.value); }}
                                                            disabled={endsValue !== 'after'}
                                                        />
                                                        <Typography>Occurrences</Typography>
                                                        
                                                    </Box>
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                        }
                                    </CalendarInnerWrapper>
                                </Form>
                            );
                        }}
                    </Formik>
                </CalendarModalBody>
            </CalendarModal>
    );
};

export default ScheduleForm;
