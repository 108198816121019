import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const initialState: { isModalOpen: boolean } = {
  isModalOpen: false,
};

export const modalSlice = createSlice({
  name: 'modalState',
  initialState,
  reducers: {
    setModalState: (state, action: PayloadAction<any>) => {
      state.isModalOpen = action.payload;
    },
  },
});

export const { setModalState } = modalSlice.actions;

export default modalSlice.reducer;
