import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import {
	Dialog,
	DialogContent,
	IconButton,
	Typography,
	Box,
	TextField,
	Autocomplete
} from '@mui/material';
import {
	SongModal,
	SongModalBody,
	SongModalHeader
} from '../pages/songs/style';
import ButtonComponent from './ButtonComponent';
import ErrorMsgComponent from './ErrorMsgComponent';
import { isEmpty } from '../utils/validators';
import LinearProgressWithLabel from './LinearProgressComponentV2';
import {
	handleAddSongWithProgressBarV2,
	handleGetSongs
} from '../redux/actions/songsAction';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
	handleCreatePlaylistFolderV3,
	handleGetPlaylistFolder
} from '../redux/actions/playlistAction';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import DroppedFilesTable from './DroppedFilesTable/index';
import { useLocation } from 'react-router-dom';
import {
	setCount,
	setTotalCount,
	setProgress,
	setIndividualProgress
} from '../redux/slice/progressBarSlice';
import {
	setSnackbarMessage,
	setSnackbarState
} from '../redux/slice/snackbarSlice';
import UploadFileIcon from '../assets/images/icons/upload-file.svg';
import { TagsWrapper } from '../pages/importSongs/style';

interface PropType {
	component: React.FC;
}

const FileUploadComponentV2: React.FC<PropType> = ({
	component: Component
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [files, setFiles] = useState<File[]>([]);
	const [parentId, setParentId] = useState('');
	const [isImportFileError, setImportFileError] = useState(false);
	const [parentIdError, setParentIdError] = useState(false);
	const [folderNameError, setFolderNameError] = useState(false);
	const [folderName, setFolderName] = useState('');
	const [playlistNameError, setPlaylistNameError] = useState(false);
	const [playlistName, setPlaylistName] = useState('');
	const { isLoading } = useAppSelector((state) => state.loading);
	const { playlistFolder } = useAppSelector((state) => state.playlist);
	const tagList = ['New Year', 'Night Event', 'Birthday', 'Christmas'];
	const [tags, setTags] = useState<string[]>([]);
	const [inputValue, setInputValue] = React.useState('');
	const textFieldRef = useRef<HTMLInputElement>(null);
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const handleFolderName = (folder: any): void => {
		if (!isEmpty(folder.path)) {
			const tempPlaylistName = folder.path?.split('/')[1];
			if (!isEmpty(tempPlaylistName)) {
				setPlaylistName(tempPlaylistName);
				setTags([...tags, tempPlaylistName]);
			}
		}
	};

	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles.length === 0) {
			dispatch(setSnackbarState(true));
			dispatch(setSnackbarMessage('Only mp3 files are allowed.'));
			return;
		}
		setFiles(acceptedFiles);
		setOpenModal(true);
		handleFolderName(acceptedFiles[0]);
		setImportFileError(false);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true,
		accept: { 'audio/mpeg': ['.mp3'] }
	});

	const handleCloseModal = (): void => {
		if (isLoading) return;
		setOpenModal(false);
		setFiles([]);
		setImportFileError(false);
		setParentIdError(false);
		setFolderNameError(false);
		setPlaylistNameError(false);
		setTags([]);
		setInputValue('');
	};

	useEffect(() => {
		void dispatch(handleGetPlaylistFolder({}));
		const unloadCallback = (event: any): any => {
			event.preventDefault();
			event.returnValue = '';
			return '';
		};

		window.addEventListener('beforeunload', unloadCallback);
		return () => {
			window.removeEventListener('beforeunload', unloadCallback);
		};
	}, []);

	const songImportSuccess = (): void => {
		handleCloseModal();
		setOpenSuccessModal(true);
		if (pathname === '/play-list') {
			void dispatch(handleGetPlaylistFolder({}));
			void dispatch(handleGetSongs({}));
		}
	};

	const handleChange = (event: SelectChangeEvent): void => {
		setParentId(event.target.value);
		setParentIdError(false);
		setImportFileError(false);
	};

	const handleProgressStateClear = (): void => {
		dispatch(setCount(0));
		dispatch(setTotalCount(0));
		dispatch(setProgress(0));
		dispatch(setIndividualProgress(0));
	};

	// const uploadFolder = (): void => {
	// 	files.length === 0 ? setImportFileError(true) : setImportFileError(false);
	// 	isEmpty(parentId) && files.length > 0
	// 		? setParentIdError(true)
	// 		: setParentIdError(false);
	// 	isEmpty(playlistName?.trim()) && files.length > 0
	// 		? setPlaylistNameError(true)
	// 		: setPlaylistNameError(false);

	// 	if (files?.length === 0) return;
	// 	if (isEmpty(parentId)) return;
	// 	if (isEmpty(playlistName?.trim())) return;

	// 	const data = new FormData();
	// 	for (const item of files) {
	// 		data.append('song', item);
	// 	}
	// 	data.append('folderName', playlistName?.trim());
	// 	data.append('tags', tags.toString());

	// 	if (parentId === 'CreateFolder') {
	// 		handleCreateFolder({
	// 			body: data,
	// 			importSuccess: songImportSuccess,
	// 			handleClear: handleProgressStateClear
	// 		});
	// 		return;
	// 	}

	// 	data.append('parentFolderId', parentId);

	// 	void dispatch(
	// 		handleAddSongWithProgressBar({
	// 			body: data,
	// 			importSuccess: songImportSuccess,
	// 			handleClear: handleProgressStateClear
	// 		})
	// 	);
	// };

	const uploadFolderV2 = (): void => {
		files.length === 0 ? setImportFileError(true) : setImportFileError(false);
		isEmpty(parentId) && files.length > 0
			? setParentIdError(true)
			: setParentIdError(false);
		isEmpty(playlistName?.trim()) && files.length > 0
			? setPlaylistNameError(true)
			: setPlaylistNameError(false);

		if (files?.length === 0) return;
		if (isEmpty(parentId)) return;
		if (isEmpty(playlistName?.trim())) return;

		const data = {
			files,
			name: playlistName?.trim(),
			tags: tags.toString()
		};

		dispatch(setTotalCount(files?.length));

		if (parentId === 'CreateFolder') {
			handleCreateFolder({
				body: { ...data, parentFolderId: parentId, isPlaylistAvailable: true },
				importSuccess: songImportSuccess,
				handleClear: handleProgressStateClear
			});
			return;
		}
		void dispatch(
			handleAddSongWithProgressBarV2({
				body: { ...data, parentFolderId: parentId, isPlaylistAvailable: true },
				importSuccess: songImportSuccess,
				handleClear: handleProgressStateClear
			})
		);
	};

	const handleCreateFolder = (args: any): void => {
		isEmpty(folderName?.trim())
			? setFolderNameError(true)
			: setFolderNameError(false);

		if (isEmpty(folderName?.trim())) return;
		void dispatch(
			handleCreatePlaylistFolderV3({
				name: { name: folderName?.trim() },
				params: args
			})
		);
	};

	// const handleClear = (): void => {
	// 	setFiles([]);
	// 	setPlaylistName('');
	// 	setFolderName('');
	// 	setParentId('');
	// 	setImportFileError(false);
	// 	setParentIdError(false);
	// 	setPlaylistNameError(false);
	// 	setFolderNameError(false);
	// };

	const handlePlaylistNameChange = (e: any): void => {
		setPlaylistName(e.target.value);
		setPlaylistNameError(false);
		setFolderNameError(false);
	};
	const handleFolderInputChange = (e: any): void => {
		setFolderName(e.target.value);
		setPlaylistNameError(false);
		setFolderNameError(false);
	};

	const handleTagChange = (
		event: React.SyntheticEvent,
		values: string[]
	): void => {
		setTags(values);
		setInputValue('');
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const newTags = pastedText.split(',').map((tag) => tag.trim());
		setTags([...tags, ...newTags]);

		setTimeout(() => {
			if (textFieldRef.current != null) {
				textFieldRef.current.value = '';
			}
		}, 200);
	};

	const handleInputBlur = (): void => {
		setInputValue('');
	};

	useEffect(() => {
		if (textFieldRef.current != null) {
			textFieldRef.current.value = inputValue;
		}
	}, [inputValue]);

	const handleSuccessModalClose = (): void => {
		setOpenSuccessModal(false);
		setFolderName('');
		setPlaylistName('');
		setParentId('');
	};

	const getFolderName = (array: any): string => {
		const temp = array?.filter((e: any) => {
			return e?.folderId === parentId;
		});
		return temp[0]?.name;
	};

	return (
		<div>
			<div>
				<div
					{...getRootProps()}
					className={isDragActive ? 'dropzone-area' : ''}
				>
					<input {...getInputProps()} />
					<Component />
					<div className='overlay'></div>
					<div className='dropdrag-icon'>
						<img src={UploadFileIcon} alt='' />
						<p>Drag and Drop files</p>
					</div>
				</div>
			</div>
			{isImportFileError && <ErrorMsgComponent message='File is required' />}
			<Dialog
				open={openModal}
				onClose={handleCloseModal}
				fullWidth={true}
				maxWidth={'sm'}
				className='client-dialog-box create-playlist-modal'
			>
				<SongModal>
					<DialogContent>
						<SongModal>
							<Box sx={{ display: 'flex' }}>
								<SongModalHeader>
									<Typography variant='h5'>Create Playlist</Typography>
									<IconButton
										aria-label='close'
										onClick={handleCloseModal}
										className='popup-close-btn'
									>
										<CloseIcon />
									</IconButton>
								</SongModalHeader>
							</Box>
							<SongModalBody className='songModalBody'>
								{isLoading && <LinearProgressWithLabel />}
								<Box
									sx={{
										marginTop: 0,
										marginBottom: 0,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<Box className='playlistinput'>
										<FormControl sx={{ m: 0, width: 183 }}>
											<Select
												id='demo-simple-select'
												value={parentId}
												label='ParentId'
												onChange={handleChange}
												sx={{
													backgroundColor: 'var(--darkerBgColor)',
													fontSize: '14px',
													padding: '10px 20px'
												}}
												displayEmpty
												inputProps={{ 'aria-label': 'Without label' }}
											>
												<MenuItem value='' disabled>
													Select Parent Folder
												</MenuItem>
												<MenuItem value='CreateFolder'>Create Folder</MenuItem>
												{playlistFolder?.length > 0 &&
													playlistFolder
														?.filter((e: any, i: number) => !isEmpty(e?.folderId))
														?.map((e: any, index: number) => {
															return (
																<MenuItem key={index} value={e?.folderId}>
																	{e?.name}
																</MenuItem>
															);
														})}
											</Select>
											{parentIdError && (
												<ErrorMsgComponent message='Please select folder first' />
											)}
										</FormControl>
										<Box className='foldernamefeild'>
											<TextField
												hiddenLabel
												id='playlistName'
												name='playlistName'
												value={playlistName}
												placeholder='Playlist Name'
												type='text'
												onChange={handlePlaylistNameChange}
												sx={{ backgroundColor: 'var(--darkerBgColor)', borderRadius: 1 }}
											/>
											{playlistNameError && (
												<ErrorMsgComponent message='Playlist name should not be empty.' />
											)}
										</Box>
										{parentId === 'CreateFolder' && (
											<Box className='foldernamefeild'>
												<TextField
													hiddenLabel
													id='folderName'
													name='folderName'
													value={folderName}
													placeholder='Folder Name'
													type='text'
													onChange={handleFolderInputChange}
													sx={{ backgroundColor: 'var(--darkerBgColor)', borderRadius: 1 }}
												/>
												{folderNameError && (
													<ErrorMsgComponent message='Folder name should not be empty.' />
												)}
											</Box>
										)}
									</Box>
								</Box>
								<TagsWrapper>
									<Autocomplete
										freeSolo
										multiple
										id='tag-select'
										className='tags-wrapper'
										onChange={handleTagChange}
										inputValue={inputValue}
										options={tagList.map((option) => option)}
										value={tags}
										onInputChange={(event, value) => {
											if (value.trim() !== '') {
												setInputValue(value);
											} else if (value === '') {
												setInputValue('');
											}
										}}
										renderInput={(params) => {
											return (
												<TextField
													{...params}
													placeholder='Tags'
													inputRef={textFieldRef}
													onPaste={handlePaste}
													onBlur={handleInputBlur}
												/>
											);
										}}
									/>
								</TagsWrapper>
								<div className='import_button_wrapper'>
									<Typography variant='h6'>Dropped files</Typography>
									<ButtonComponent
										variant='contained'
										btnName='Import'
										onClick={uploadFolderV2}
										loading={isLoading}
										disabled={isLoading}
									/>
								</div>
								{files.length > 0 && (
									<Box className='DroppedFilesTable'>
										<DroppedFilesTable files={files} />
									</Box>
								)}
							</SongModalBody>
						</SongModal>
					</DialogContent>
				</SongModal>
			</Dialog>
			<Dialog
				open={openSuccessModal}
				onClose={handleSuccessModalClose}
				fullWidth={true}
				maxWidth={'sm'}
				className='client-dialog-box create-playlist-modal'
			>
				<SongModal>
					<DialogContent>
						<SongModal>
							<Box sx={{ display: 'flex' }}>
								<SongModalHeader>
									<Typography variant='h5'>Congratulations</Typography>
									<IconButton
										aria-label='close'
										onClick={handleSuccessModalClose}
										className='popup-close-btn'
									>
										<CloseIcon />
									</IconButton>
								</SongModalHeader>
							</Box>
							<SongModalBody className='songModalBody'>
								<Box
									sx={{
										marginTop: 0,
										marginBottom: 0,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<Box className='playlistinput'>
										<Box className='success-message'>
											<p>{`Your '${playlistName}' playlist is now created and ready for enjoyment!`}</p>
											<p>{`All your favorite songs are neatly organized under this playlist in the '${
												getFolderName(playlistFolder) ?? folderName
											}' folder. Happy listening!`}</p>
										</Box>
									</Box>
								</Box>
								<div className='Modal-buttons'>
									<ButtonComponent
										variant='contained'
										btnName='Ok'
										onClick={handleSuccessModalClose}
										loading={isLoading}
										disabled={isLoading}
									/>
								</div>
							</SongModalBody>
						</SongModal>
					</DialogContent>
				</SongModal>
			</Dialog>
		</div>
	);
};

export default FileUploadComponentV2;
