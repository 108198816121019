import React, { type FC } from 'react';
import Logo from '../../assets/images/logo.svg';
import { Wrapper, MainWrapper } from './style';
import { Link } from 'react-router-dom';

const Landing: FC = () => {
    return (
        <MainWrapper>
            <Wrapper>
                <img src={Logo} alt="logo" />
            </Wrapper>
            <Wrapper>
            <h2><Link to="/login">Click here to Login</Link></h2>
                
            </Wrapper>
        </MainWrapper>
    );
};

export default Landing;