/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import { type SerchParams, type SongsInputInterface, type assignTagParams } from '../modals';
import { ApiUrl } from '../utils/config';
import { isEmpty } from '../utils/validators';
import { setCount, setIndividualProgress, setProgress } from '../redux/slice/progressBarSlice';
import { store } from '../redux/store';
import { setLoadingState } from '../redux/slice/loadingSlice';
import { setSnackbarMessage, setSnackbarState } from '../redux/slice/snackbarSlice';

const getSongs = ({ search = '', offset = 0, limit = 100, field = '', sort = '' }: SerchParams): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.get(ApiUrl + `song/?search=${search}&field=${field}&sort=${sort}&offset=${offset}&limit=${limit}`, axiosConfig);
};

const getRecentSongs = ({ search = '', offset = 0, limit = 1000, field = '', sort = '' }: SerchParams): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.get(ApiUrl + `song/recent?search=${search}&field=${field}&sort=${sort}&offset=${offset}&limit=${limit}`, axiosConfig);
};

const getSongFromFolderPlayList = ({ folderId, search = '', offset = 0, recent = false }: SerchParams): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.get(ApiUrl + `folder/${folderId}/song/list?recent=${recent}&search=${search}&offset=${offset}`, axiosConfig);
};

const addSong = (body: FormData): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			'Content-Type': 'multipart/form-data',
			token: userToken,
		},
		onUploadProgress: (progressEvent: any) => {
			const { loaded, total } = progressEvent;
			const percent = Math.floor((loaded * 100) / total);
			store.dispatch(setProgress(percent));
		}
	};
	return axios.post(ApiUrl + 'song/add', body, axiosConfig);
};

const addSongWithProgressBar = (body: FormData): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			'Content-Type': 'multipart/form-data',
			token: userToken,
		},
	};
	return axios.post(ApiUrl + 'song/addFolderSongs', body, axiosConfig);
};

const addSongV3 = (body: FormData, totalFiles: number, count: number): any => {
	const userToken: any = localStorage.getItem('userToken');
	store.dispatch(setCount(count + 1));
	store.dispatch(setIndividualProgress(0));
	const axiosConfig: any = {
		headers: {
			'Content-Type': 'multipart/form-data',
			token: userToken,
		},
		onUploadProgress: (progressEvent: any) => {
			const { loaded, total } = progressEvent;
			const per = loaded / total * 100;
			const per2 = per / totalFiles;
			const per3 = per2 + (100 * count / totalFiles);
			store.dispatch(setProgress(Math.floor(per3)));
			store.dispatch(setIndividualProgress(Math.floor(per)));
		}
	};
	return axios.post(ApiUrl + 'song/add', body, axiosConfig);
};

let indexId = 0;
const addSongV2 = async (
	data: any,
	params: any
) => {
	indexId = data?.files?.length;
	try {
		await _fileUpload(data, 0, params); return;
	} catch (e: any) {
		const errorMessage = e?.response?.data?.message ?? 'Unknown error';
		store.dispatch(setSnackbarState(true));
		store.dispatch(setSnackbarMessage(errorMessage));
		store.dispatch(setLoadingState(false));
		return errorMessage;
	}
};

const addSongFromDropbox = (body: object): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken,
		}
	};
	return axios.post(ApiUrl + 'song/dropbox/add', body, axiosConfig);
};

const updateSong = ({ id, body }: SongsInputInterface): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.put(ApiUrl + `song/${id}`, body, axiosConfig); /* eslint-disable-line */
};

const assignTag = ({ body }: assignTagParams): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.put(ApiUrl + 'song/update-tags', body, axiosConfig); /* eslint-disable-line */
};

const deleteSong = (id: number): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.delete(ApiUrl + `song/${id}`, axiosConfig);
};

const deleteMultipleSongs = (body: any): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		},
		data: body
	};
	return axios.delete(ApiUrl + 'song/bulk-delete', axiosConfig);
};

const getAuthUrl = (): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		},
	};
	return axios.get(ApiUrl + 'song/getUrl', axiosConfig);
};

const addSongFromDrive = (body: object): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken,
		}
	};
	return axios.post(ApiUrl + 'song/drive/add', body, axiosConfig);
};

const _fileUpload = async (
	data: any,
	index: number,
	params: any,
) => {
	const { files, tags } = data;
	if (files?.length > 0) {
		indexId = index;
		if (!isEmpty(files[index])
		) {
			const formData = new FormData();
			formData.append('song', files[index]);
			if (!isEmpty(tags)) {
				formData.append('tags', tags);
			}
			formData.append('isPlaylistAvailable', data?.isPlaylistAvailable);
			if (!isEmpty(data?.playlistId)) {
				formData.append('playlistId', data?.playlistId);
			}
			formData.append('isAddedNewSong', String(index === 0));
			await callSongUpload(
				formData,
				index,
				files,
				params,
				data
			);
		}
	}
};

let songId: any[] = [];
const callSongUpload = async (
	formData: FormData,
	index: number,
	files: any,
	params: any,
	data: any,
) => {
	indexId = index;
	_returnId(indexId);
	if (index === 0) {
		songId = [];
	}
	try {
		const response = await addSongV3(formData, files?.length, index);
		songId = [...songId, response?.data?.data[0]?.id];
		void onUploaderResponse(true, index + 1, files, params, songId, data);
	} catch (error: any) {
		store.dispatch(setSnackbarState(true));
		store.dispatch(setSnackbarMessage(error?.response?.data?.message));
		store.dispatch(setLoadingState(false));
	}
};

const onUploaderResponse = async (
	res: any,
	index: number,
	files: any[],
	params: any,
	songId: any,
	data: any
) => {
	if (files.length > index) {
		if (res === true) {
			await _fileUpload(data, index, params);
		}
	} else {
		store.dispatch(setLoadingState(false));
		params.importSuccess(songId, data?.playlistId);
		params.handleClear();
	}
};

export const _returnId = (id: any) => {
	return id;
};

export const songsService = {
	getSongs,
	deleteSong,
	addSong,
	addSongV2,
	addSongWithProgressBar,
	updateSong,
	assignTag,
	getSongFromFolderPlayList,
	addSongFromDropbox,
	getRecentSongs,
	deleteMultipleSongs,
	getAuthUrl,
	addSongFromDrive
};
