import axios from 'axios';
import { type SerchParams } from '../modals';
import { ApiUrl } from '../utils/config';

const addClient = (body: any): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			'Content-Type': 'multipart/form-data',
			token: userToken,
		}
	};
	return axios.post(ApiUrl + 'auth/client/signup', body, axiosConfig);
};

const getClientList = ({ search = '', offset = 0, type = '' }: SerchParams): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.get(ApiUrl + `auth/client-user/list?type=${type}&search=${search}`, axiosConfig);
};

const updateClient = ({ id = 0, body }: any): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			'Content-Type': 'multipart/form-data',
			token: userToken
		}
	};
	return axios.put(ApiUrl + `auth/client-user/${Number(id)}`, body.data, axiosConfig);
};

const deleteClient = ({ id = 0 }: any): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.delete(ApiUrl + `auth/remove-user/${Number(id)}`, axiosConfig);
};

export const clientService = { addClient, getClientList, updateClient, deleteClient };