export function totalTime(sumProps: string[]): any {
    const times: any = [3600, 60, 1];
        let sum = sumProps
            .map(function (s) {
                return s
                    .split(':')
                    .reduce(function (s, v, i) {
                        return s + times[i] * Number(v);
                    }, 0);
            })
            .reduce(function (a, b) { return a + b; }, 0);
        
            return  times
        .map(function (t: any) {
            const value = Math.floor(sum / t);
            sum %= t;
            return value;
        })
        .map(function (v: any) {
            return v.toString().padStart(2, 0);
        })
        .join(':');
    
};

// convertToMinutesSeconds
export const convertToMinutesSeconds = (time: any): any => {
    const parts = time.split(':');
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);
    const seconds = parseInt(parts[2]);
    
    // Calculate the total number of seconds in the time
    const totalSeconds = (hours * 60 * 60) + (minutes * 60) + seconds;
    
    // Calculate the number of minutes and seconds
    const convertedMinutes = Math.floor(totalSeconds / 60);
    const convertedSeconds = totalSeconds % 60;
    
    // Return the time in mm:ss format
    return `${convertedMinutes.toString().padStart(2, '0')}:${convertedSeconds.toString().padStart(2, '0')}`;
};