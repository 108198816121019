import axios from 'axios';
import { ApiUrl } from '../utils/config';
import { type UpdatePlaylistSongParams, type PlaylistSongParams, type searchSongsFromPlaylist, type songRatingInterface } from '../modals';

const addPlaylistSongs = ({playlistId, songIds}: PlaylistSongParams): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.post(ApiUrl + `play-list/${Number(playlistId)}/song/add`, { songIds }, axiosConfig);
};

const getPlaylistSongs = ({ playlistId, search='', field='createdAt', sort='ASC' } :searchSongsFromPlaylist ): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	return axios.get(ApiUrl + `play-list/${playlistId}/song?search=${search}&field=${field}&sort=${sort}`, axiosConfig);
};

const deletePlaylistSong = ({songId, playlistId}: any): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.delete(ApiUrl + `play-list/${Number(playlistId)}/song/${Number(songId)}`, axiosConfig);
};

const deleteBulkSongsFromPlaylist = ({body, playlistId}: any): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		},
		data: body
	};
	return axios.delete(ApiUrl + `play-list/${Number(playlistId)}/song`, axiosConfig);
};

const updatePlaylistSongs = ({songsOrderIndex, playlistId}: UpdatePlaylistSongParams): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.put(ApiUrl + `play-list/${Number(playlistId)}/song/re-order`, songsOrderIndex, axiosConfig);
};

const updateSongRating = ({data, songId}: songRatingInterface): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.put(ApiUrl + `song/${Number(songId)}/rating`, data, axiosConfig);
};

export const playlistSongsService = { addPlaylistSongs, getPlaylistSongs, deletePlaylistSong, deleteBulkSongsFromPlaylist, updatePlaylistSongs, updateSongRating };
