import React, { type FC } from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { type ButtonCompoInterface } from '../modals';

const ButtonComponent: FC<ButtonCompoInterface> = ({
	describedBy,
	type,
	btnName,
	onClick,
	variant,
	startIcon,
	sx,
	loading = false,
	className,
	disabled,
	tooltipText
}) => {
	return (
		<>
			<Tooltip title={tooltipText} arrow>
				<Button
					aria-describedby={describedBy}
					type={type}
					onClick={onClick}
					variant={variant}
					startIcon={startIcon}
					sx={{ textTransform: 'capitalize', ...sx }}
					className={className}
					disabled={disabled}
				>
					{loading ? <CircularProgress sx={{ color: 'var(--primary)' }} size={25} /> : btnName}
				</Button>
			</Tooltip>
		</>
	);
};

export default ButtonComponent;
