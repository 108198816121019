import React, { useState, type FC, useEffect, useRef } from 'react';
import { Formik, Form, type FormikProps } from 'formik';
import {
	Box,
	Typography,
	IconButton,
	Autocomplete,
	TextField
} from '@mui/material';
import { SongFormSchema } from './validation';
import ButtonComponent from '../../components/ButtonComponent';
import InputComponent from '../../components/InputComponent';
import { type SongFormProps, type SongTableDataType } from '../../modals';
import CloseIcon from '@mui/icons-material/Close';
import { SongModal, SongModalHeader, SongModalBody } from './style';
import { useAppDispatch } from '../../redux/hooks';
import { handleUpdateSong } from '../../redux/actions/songsAction';
import { setLoadingState } from '../../redux/slice/loadingSlice';

const SongForm: FC<SongFormProps> = ({
	setSongForm,
	preFillData,
	page,
	orderBy,
	sortDirection,
	alignment,
	resetProps,
	handleClear,
	isFromPlaylist = false,
	playlistId,
}) => {
	const dispatch = useAppDispatch();
	const tagList: any[] = ['New Year', 'Night Event', 'Birthday', 'Christmas'];
	const [tagValue, setTagValue] = useState<any>(
		preFillData.tags !== undefined && preFillData.tags?.length > 0
			? preFillData?.tags
			: []
	);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValue, setInputValue] = React.useState('');

	const textFieldRef = useRef<HTMLInputElement>(null);

	// Formik
	const initialValues: SongTableDataType = {
		title: preFillData.title,
		artist: preFillData.artist,
		id: preFillData.id
	};
	const validationSchema = SongFormSchema;
	const handleSubmit = (values: SongTableDataType): void => {
		handleClear();
		setIsLoading(true);
		const data = {
			artist: values.artist,
			title: values.title,
			tags: tagValue
		};
		dispatch(setLoadingState(true));
		void dispatch(
			handleUpdateSong({
				id: preFillData.id,
				body: data,
				params: { ...resetProps },
				orderBy,
				sortDirection,
				isFromPlaylist,
				playlistId,
			})
		);
		setIsLoading(false);
		setSongForm(false);
	};

	const handleTagChange = (
		event: React.SyntheticEvent,
		values: string[]
	): void => {
		setTagValue(values);
		setInputValue('');
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const newTags = pastedText.split(',').map((tag) => tag.trim());
		setTagValue([...tagValue, ...newTags]);

		setTimeout(() => {
			if (textFieldRef.current != null) {
				textFieldRef.current.value = '';
			}
		}, 100);
	};

	const handleInputBlur = (): void => {
		setInputValue('');
	};

	useEffect(() => {
		if (textFieldRef.current != null) {
			textFieldRef.current.value = inputValue;
		}
	}, [inputValue]);

	return (
		<>
			<SongModal>
				<Box sx={{ display: 'flex' }}>
					<SongModalHeader>
						<Typography variant='h5'>Edit Song</Typography>
						<IconButton
							aria-label='close'
							onClick={() => {
								setSongForm(false);
							}}
							className='popup-close-btn'
						>
							<CloseIcon />
						</IconButton>
					</SongModalHeader>
				</Box>
				<SongModalBody>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{(props: FormikProps<SongTableDataType>) => {
							const { values, errors, touched, handleChange, handleBlur } = props;
							return (
								<Form>
									<div className='form-field'>
										<Typography variant='h6'>Title</Typography>
										<InputComponent
											id='title'
											name='title'
											value={values.title}
											placeholder='Title'
											type='text'
											variant='outlined'
											onBlur={handleBlur}
											onChange={handleChange}
											error={!!((touched.title ?? false) && errors.title != null)}
											helperText={(touched.title ?? false) && errors.title}
											required
										/>
									</div>
									<div className='form-field'>
										<Typography variant='h6'>Artist</Typography>
										<InputComponent
											id='artist'
											name='artist'
											value={values.artist}
											placeholder='Artist'
											type='text'
											variant='outlined'
											onBlur={handleBlur}
											onChange={handleChange}
											error={!!((touched.artist ?? false) && errors.artist != null)}
											helperText={(touched.artist ?? false) && errors.artist}
											required
										/>
									</div>
									<div className='form-field select-option-tags'>
										<Typography variant='h6'>Tags</Typography>
										<div>
											<Autocomplete
												freeSolo
												multiple
												id='tag-select'
												className='tags-wrapper'
												onChange={handleTagChange}
												inputValue={inputValue}
												options={tagList.map((option) => option)}
												value={tagValue}
												onInputChange={(event, value) => {
													if (value.trim() !== '') {
														setInputValue(value);
													} else if (value === '') {
														setInputValue('');
													}
												}}
												renderInput={(params) => {
													return (
														<TextField
															{...params}
															placeholder='tags'
															inputRef={textFieldRef}
															onPaste={handlePaste}
															onBlur={handleInputBlur}
														/>
													);
												}}
											/>
										</div>
									</div>
									<Box sx={{ textAlign: 'right' }} className='form-buttons'>
										<ButtonComponent
											variant='outlined'
											btnName='Cancel'
											onClick={() => {
												setSongForm(false);
											}}
										/>
										<ButtonComponent
											type='submit'
											variant='contained'
											btnName='Update'
											disabled={isLoading}
											loading={isLoading}
										/>
									</Box>
								</Form>
							);
						}}
					</Formik>
				</SongModalBody>
			</SongModal>
		</>
	);
};

export default SongForm;
