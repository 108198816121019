import styled from 'styled-components';
import '../../style/main.scss';

export const Audioplayer = styled.div`
  width: 100%;
  .inner {
    border: 1px solid;
    padding: 15px 15px 15px 54px;
    margin: 0 0 10px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .control_track {
    width: 25%;
    display: flex;
    align-items: center;
    .controls {
      .pause-play-icon {
        padding: 0;
        margin-left: 0;
        svg {
          fill: #29292B;
          font-size: 36px;
        }
      }
    }
  }
  .main_audio {
    padding-right: 5px;
  }
  .music-detail {
    .music_info {
      padding-left: 10px;
      p {
        font-size: 14px;
        line-height: 17px;
        font-family: "regular-fonts";
      }
    }
  }
  .audio_info {
    display: flex;
    align-items: center;
    margin: 0;
    .audio_image {
      margin-right: 5px;
      line-height: 0;
      display: none;
      svg {
        width: 36px;
        height: 36px;
      }
    }
    .music_image {
      line-height: 0;
    }
  }
  .control_progress_bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0px;
    width: 75%;
    padding-left: 30px;
    .main_progress {
      width: 100%;      
      .audio_text {
        text-align: center;
      }
    }
    .controls_wrapper {
      width: 140px;
    }
    .controls {
      text-align: center;
      button {
        padding: 0;
        color: #fff;
        width: 30px;
        height: 30px;
        margin-right: 5px;
        border-radius: 10px;
        &:nth-child(2) {
          padding: 0 5px;
        }
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    .progress {
      width: 100%;
      padding: 5px 0 0;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      input {
        background: linear-gradient(to right, #8E2734 0%, #8E2734 0%, #F2A892 0%, #F2A892 100%);
        border-radius: 8px;
        height: 7px;
        width: calc(100% - 100px);
        outline: none;
        transition: background 450ms ease-in;
        appearance: none;
        &::-webkit-slider-thumb {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          appearance: none;
          cursor: ew-resize;
          background: var(--bgColor);
          border: 2px solid var(--swichSuccess);
        }
        &::-moz-range-thumb {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          appearance: none;
          cursor: ew-resize;
          background: var(--bgColor);
          border: 2px solid var(--swichSuccess);
        }
      }
      .timer {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 8px;
      }
      .time {
        font-size: 14px;
        &.timeprogress {
          padding-right: 10px;
          width: 50px;
        }
        &.duration {
          padding-left: 10px;
        }
      }
      .MuiSlider-sizeSmall {
        padding: 8px 0 0 0;
      }
      .range-slider {
        padding: 0;
      }
    }
  }
`;

