import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  (process.env.NODE_ENV === 'development')
    ? <BrowserRouter>
        <App />
      </BrowserRouter>
    : <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </React.StrictMode>
);
reportWebVitals();
