import axios from 'axios';
import { ApiUrl } from '../utils/config';
import { type RenameParamsType, type CreateParamsType, type dragPlayListType, type dragFolderType, type CreateParamsTypeV2, type getPlaylistProps } from '../modals';

const getPlaylistFolderList = (): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.get(ApiUrl + 'folder/play-list', axiosConfig);
};

const getPlaylistFolderListV2 = (): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.get(ApiUrl + 'folder', axiosConfig);
};

const getPlayList = ({ field='name', sort='ASC' } : getPlaylistProps ): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	return axios.get(ApiUrl + `play-list?&field=${field}&sort=${sort}`, axiosConfig);
};

const createPlaylistFolder = (body: CreateParamsType): any => {
	const bodyData = (body.parentFolderId !== null) ?
		{ name: body.name.name, parentFolderId: body.parentFolderId } :
		{ name: body.name.name };
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.post(ApiUrl + 'folder/add', bodyData, axiosConfig);
};

const renamePlaylistFolder = ({ id, name }: RenameParamsType): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.put(ApiUrl + `folder/${Number(id)}`, name, axiosConfig);
};
const deletePlaylistFolder = (id: number): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.delete(ApiUrl + `folder/${Number(id)}`, axiosConfig);
};

const getPlaylistChildFolderList = (id: number): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.get(ApiUrl + `folder/${id}`, axiosConfig);
};

const createPlaylistV2 = (body: CreateParamsTypeV2): any => {
	const bodyData = { name: body.name, parentFolderId: body.parentFolderId };
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.post(ApiUrl + 'play-list/add', bodyData, axiosConfig);
};
const createPlaylist = (body: CreateParamsType): any => {
	const bodyData = { name: body.name.name, parentFolderId: body.parentFolderId };
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.post(ApiUrl + 'play-list/add', bodyData, axiosConfig);
};

const deletePlaylist = (id: number): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.delete(ApiUrl + `play-list/${Number(id)}`, axiosConfig);
};

const renamePlaylist = ({ id, name }: RenameParamsType): any => {

	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.put(ApiUrl + `play-list/${Number(id)}`, name, axiosConfig);
};

const dragPlayList = (data: dragPlayListType): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.put(ApiUrl + 'play-list/parent-folder', data, axiosConfig);
};

const dragFolder = (data: dragFolderType): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.put(ApiUrl + 'folder/parent-folder', data, axiosConfig);
};

const duplicatePlaylist = (data:any): any => {
	const userToken: any = localStorage.getItem('userToken');
	const axiosConfig: any = {
		headers: {
			token: userToken
		}
	};
	return axios.post(ApiUrl + 'play-list/duplicate', data, axiosConfig);
};

export const playlistService = {
	getPlaylistFolderList,
	getPlaylistChildFolderList,
	createPlaylistFolder,
	renamePlaylistFolder,
	deletePlaylistFolder,
	createPlaylist,
	deletePlaylist,
	renamePlaylist,
	getPlayList,
	dragPlayList,
	dragFolder,
	duplicatePlaylist,
	createPlaylistV2,
	getPlaylistFolderListV2
};
