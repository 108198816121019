import styled from 'styled-components';

export const TableWrapper = styled.div`
	margin-bottom: 0;

	h6 {
		font-size: 18px;
		font-family: "medium-fonts";
		line-height: normal;
		margin: 0 0 10px 0;
	}
	.dropped-files-table {
		border-radius: 10px;
		border: 1px solid black;
		overflow: hidden;
	}
	.dropped-files-table .inner-dropped-files-table {
		overflow: auto;
		max-height: calc(100vh - 380px);
		background: var(--darkerBgColor);
		border-radius: 10px;
	}
	.dropped-files-table .inner-dropped-files-table table thead tr th:first-child {
		border-radius: 10px 0 0 0;
	}
	.dropped-files-table .inner-dropped-files-table table tr th:first-child {
		border-radius: 0;
	}
	.dropped-files-table .inner-dropped-files-table table tr th:last-child {
		border-radius: 0;
	}
	.dropped-files-table table {
		background: var(--bgColor);
		overflow: unset;
		border: none;
	}
	.dropped-files-table table tr td:last-child {
		border-left: none;
	}
	.dropped-files-table table tr th {
		border-bottom: 1px solid;
	}
	.dropped-files-table table tr td ul {
		list-style-type: none;
		padding: 0;
	}
	.dropped-files-table table tr td ul li p {
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		max-width: 500px;
		display: block;
	}

	table {
		border: solid 1px var(--primary);
		border-radius: 10px;
		overflow: hidden;
	}
	table tr td,
	table tr th {
		padding: 8px 15px;
	}
	table tr td ul {
		padding-left: 15px;
	}
	table tr td ul li {
		padding: 0 !important;
	}
	table tr td ul li:hover {
		background-color: transparent !important;
		color: var(--black) !important;
	}
	table tr td ul li.table-listing {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	table tr td ul li.table-listing .linear-progess-bar.individual-progress {
		flex-wrap: unset;
		position: relative;
		width: 20%;
	}
	table tr td ul li.table-listing p {
		width: calc(100% - 25%);
	}
	table tr td ul li.table-listing .linear-progess-bar.individual-progress span.MuiLinearProgress-root {
		height: 25px;
	}
	table tr td ul li.table-listing .linear-progess-bar.individual-progress .percentage {
		position: absolute;
	}
	table tr td ul li.table-listing .linear-progess-bar.individual-progress .percentage p {
		white-space: normal;
		text-overflow: unset;
		width: 100%;
		color: #fff;
	}
	table tr td ul li.table-listing .linear-progess-bar.individual-progress .MuiBox-root {
		margin-right: 0;
	}
	table tr td:last-child,
	table tr th:last-child {
		min-width: 120px;
		border-left: solid 1px var(--primary);
	}
	table tr th:last-child {
		border-left: none;
	}
	table tr th {
		background-color: var(--darkerBgColor);
		border-bottom: none;
	}
	table tr th p {
		font-size: 16px;
		font-family: "medium-fonts";
	}
	table tr th:first-child,
	table tr td:first-child {
		width: 100%;
	}
	table tr td {
		padding: 10px 15px;
		border-bottom: solid 1px var(--primary) !important;
	}
	table tr td p {
		font-family: 'regular-fonts';
		font-size: 14px;
	}
	table tr:last-child td {
		border-bottom: none !important;
	}
	.preview_table table tr th:first-child, 
	.preview_table table tr td:first-child {
		width: auto;
	}
	.preview_table table tbody {
		background: var(--bgColor);
	}
	.preview_table table tr th {
		border-bottom: 1px solid;
	}
	.preview_table table tr h6 {
		margin: 0;
		font-size: 14px;
		padding: 10px 15px;
	}
	.preview_table table tbody tr td ul {
		list-style-type: none;
		padding: 0;
	}
	.preview_table table tr td:last-child {
		border-left: none;
		min-width: 60px;
	}
	.preview_table table tr th:last-child{
		min-width: 60px;
	}
`;
