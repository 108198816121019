import React, { useState, type FC, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { loginSchema } from './validation';
import InputComponent from '../../components/InputComponent';
import ButtonComponent from '../../components/ButtonComponent';
import { handleEditUsersProfile, handleGetCurrentUser } from '../../redux/actions/loginAction';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { type EditFormInputInterface } from '../../modals';
import { ProfileFormWrapper , ProfileBox } from './style';
import { Typography , Box, IconButton } from '@mui/material';
import editIcon from '../../assets/images/icons/edit-note-icon.svg';
import { setLoadingState } from '../../redux/slice/loadingSlice';

const Profile: FC = () => {
  const currentUserId = localStorage.getItem('currentUser'); 
  useEffect(() => {
    void dispatch(handleGetCurrentUser(Number(currentUserId)));
  },[]);

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.loading);    
  const { currentUser } = useAppSelector((state) => state.auth);    
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Formik 
  const initialValues: EditFormInputInterface = {
    name: currentUser.name,
    email: currentUser.email,
    password: ''
  };
  const validationSchema = loginSchema;
  const handleSubmit = (values: EditFormInputInterface, action: { resetForm: () => any; }): void => {
    dispatch(setLoadingState(true));
    const data = {
      name: values.name,
      password: values.password,
      id: currentUser?.id
    };
    void dispatch(handleEditUsersProfile(data));
    action.resetForm();
    setIsEdit(false);
  };

  return (
    <>
      <ProfileFormWrapper>
        <ProfileBox>
          <div className='profile-header'>
            <Typography variant="h5" >User Details</Typography>
            { !isEdit && 
              <IconButton 
                onClick={() => { setIsEdit(!isEdit); }} 
              >
                <img src={editIcon} alt="" />
              </IconButton>
            }
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
                <div className="input-wrapper">
                  <Typography variant="subtitle2" >Name</Typography>
                  <InputComponent 
                    id="name"
                    name="name"
                    value={values.email}
                    placeholder="Name"
                    type="text" 
                    variant="outlined"
                    color="primary"
                    onBlur={handleBlur} 
                    onChange={handleChange}
                    error={!!((touched.name ?? false) && (errors.name != null))}
                    helperText={(touched.name ?? false) && errors.name}
                    required
                  />
                </div>
                <div className="input-wrapper">
                <Typography variant="subtitle2" >Email</Typography>
                  <InputComponent 
                    disabled
                    id="email"
                    name="email"
                    value={values.password}
                    placeholder="Email"
                    type="email" 
                    variant="outlined"
                    color="primary"
                    onBlur={handleBlur} 
                    onChange={handleChange}
                    error={!!((touched.email ?? false) && (errors.email != null))}
                    helperText={(touched.email ?? false) && errors.email}
                    required
                  />
                </div>
                { isEdit && 
                  <>
                    <div className="input-wrapper">
                      <Typography variant="subtitle2">Password</Typography>
                      <InputComponent
                        id="password"
                        name="password"
                        value={values.password}
                        placeholder="Password"
                        type="password"
                        variant="outlined"
                        color="primary"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!((touched.password ?? false) && (errors.password != null))}
                        helperText={(touched.password ?? false) && errors.password}
                        required />
                    </div>
                    <Box sx={{ textAlign: 'right' }}>
                      <ButtonComponent
                        onClick={() => { setIsEdit(!isEdit); } }
                        variant="outlined"
                        btnName="Cancel" 
                      />
                      <ButtonComponent
                        type="submit"
                        variant="contained"
                        btnName='Save'
                        loading={isLoading}
                      />
                    </Box>
                  </>
                }    
            </Form>
            )}
          </Formik>
        </ProfileBox>
      </ProfileFormWrapper>
    </>
  );
};

export default Profile;