import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

const initialState: any = {
    playlistSongs: [],
};

export const playlistSongSlice = createSlice({
    name: 'playlistSong',
    initialState,
    reducers: {
        setPlaylistSongs: (state, action: PayloadAction<any>) => {
            state.playlistSongs = action.payload;
        },
    },
});

export const { setPlaylistSongs } = playlistSongSlice.actions;

export const playlistSongs = (state: RootState): any => state.playlistSong.playlistSongs;

export default playlistSongSlice.reducer;
