/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */


export const isEmpty = (value: any): boolean =>
  value === null || value === 'undefined' || value === '' || value === undefined;

export const isEmptyObject = (obj: any): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const isValidTimeFormat = (time: string): boolean => {
  const timeRegex = /^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
  return timeRegex.test(time);
};

