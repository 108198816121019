import { configureStore } from '@reduxjs/toolkit';
import loadingSlice from './slice/loadingSlice';
import snackbarSlice from './slice/snackbarSlice';
import clientSlice from './slice/clientSlice';
import authSlice from './slice/authSlice';
import songsSlice from './slice/songsSlice';
import playlistSlice from './slice/playlistSlice';
import playlistSongSlice from './slice/playlistSongSlice';
import scheduleSlice from './slice/scheduleSlice';
import selectedsongSlice from './slice/selectedSong';
import crossFadeSlice from './slice/crossFade';
import progressBarSlice from './slice/progressBarSlice';
import modalSlice from './slice/modalSlice';
import streamingSlice from './slice/streamingSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    loading: loadingSlice,
    snackbar: snackbarSlice,
    client: clientSlice,
    songs: songsSlice,
    playlist: playlistSlice,
    playlistSong: playlistSongSlice,
    schedule: scheduleSlice,
    selectedsong: selectedsongSlice,
    crossFade: crossFadeSlice,
    progressBar: progressBarSlice,
    modalState: modalSlice,
    streaming: streamingSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;