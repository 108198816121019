/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useRef, useState } from 'react';
import DisplayTrack from './DisplayTrack';
import Controls from './Controls';
import ProgressBar from './ProgressBar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { isSelectedSong, setPlay } from '../../redux/slice/selectedSong';
import { Audioplayer } from './style';

const AudioPlayer = () => {
	const dispatch = useAppDispatch();
	const selectedSong = useAppSelector(isSelectedSong);
	const [trackIndex, setTrackIndex] = useState(0);
	const [currentTrack, setCurrentTrack] = useState(
		selectedSong && selectedSong !== undefined ? selectedSong : undefined
	);
	const [timeProgress, setTimeProgress] = useState(0);
	const [duration, setDuration] = useState(0);
	const audioRef = useRef<any>(null);
	const progressBarRef = useRef();

	useEffect(() => {
		selectedSong && selectedSong !== undefined && setCurrentTrack(selectedSong);
	}, [selectedSong]);

	const handleNext = () => {
		dispatch(setPlay(false));
		audioRef?.current?.pause();
	};

	const _songController = () => {
		return (
			<div className='inner'>
				<div className='control_track'>
					<Controls
						{...{
							audioRef,
							progressBarRef,
							duration,
							setTimeProgress,
							trackIndex,
							setTrackIndex,
							setCurrentTrack,
							currentTrack
						}}
					/>
					<DisplayTrack
						{...{
							currentTrack,
							audioRef,
							setDuration,
							progressBarRef,
							handleNext
						}}
					/>
				</div>
				<div className='control_progress_bar'>
					<ProgressBar
						{...{ progressBarRef, audioRef, timeProgress, duration, currentTrack }}
					/>
				</div>
			</div>
		);
	};
	const _renderSongController = () => {
		if (selectedSong !== undefined) {
			return _songController();
		}
	};
	return (
		<>
			<Audioplayer>{_renderSongController()}</Audioplayer>
		</>
	);
};
export default AudioPlayer;
