/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, type FC } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import ScheduleForm from './ScheduleForm';
import { Dialog, DialogContent, Popover, MenuItem } from '@mui/material';
// import EventTooltipModal from './EventTooltipModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
	handleEditSchedule,
	handleGetScheduleListV2
} from '../../redux/actions/scheduleAction'; // handleGetScheduleList,
import { handleGetPlaylist } from '../../redux/actions/playlistAction';
import { handleGetClientList } from '../../redux/actions/clientAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from '../../utils/validators';
import AddClientModal from './AddClientModal';
import dayjs from 'dayjs';
import { setPlaylistSongs } from '../../redux/slice/playlistSongSlice';
import { CalendarWrapper } from './style';

const Calendar: FC = () => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isNew, setIsNew] = useState<boolean>(false);
	const [isOpenScheduleForm, setOpenScheduleForm] = useState<boolean>(false);
	const [selectData, setSelectData] = useState<any | null>(null);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const { state } = location;
	const { scheduleList } = useAppSelector((state) => state.schedule);
	const { activeClient } = useAppSelector((state) => state.client);
	// Restaurant
	const [restaurantListOpen, setRestaurantListOpen] = useState<boolean>(false);
	const [restaurant, setRestaurant] = useState<any>(activeClient[0]?.name);
	const [clientId, setclientId] = useState<any>(
		state?.id ? state.id : activeClient[0]?.id
	);
	const [hasNoClient, setHasNoClient] = useState<boolean>(false);
	const [currentMonthStartDate, setCurrentMonthStartDate] = useState<any>();
	const [currentMonthEndDate, setCurrentMonthEndDate] = useState<any>();
	// const [isShuffle, ] = useState<boolean>(true);

	useEffect(() => {
		void dispatch(handleGetPlaylist({}));
		void dispatch(handleGetClientList({}));
	}, []);

	const getScheduleList = (id?: any): void => {
		// setOpenTooltip(false)
		if (
			!isEmpty(clientId) &&
			!isEmpty(currentMonthStartDate) &&
			!isEmpty(currentMonthEndDate)
		) {
			const data = {
				startDate: currentMonthStartDate,
				endDate: currentMonthEndDate,
				clientId: id || clientId,
				currentDate: dayjs(new Date()).format('YYYY-MM-DD'),
				// type: 'month',
			};
			void dispatch(handleGetScheduleListV2(data));
		}
	};

	useEffect(() => {
		getScheduleList();
	}, [clientId]);

	useEffect(() => {
		setclientId(state?.id ? state.id : activeClient[0]?.id);
		setRestaurant(state?.name ? state.name : activeClient[0]?.name);
		getScheduleList(state?.id ? state.id : activeClient[0]?.id);
	}, [state, activeClient.length]);

	useEffect(() => {
		setOpenScheduleForm(false);
	}, [scheduleList]);

	const selectFunction = (e: any): void => {
		setHasNoClient(false);
		if (!isEmpty(clientId)) {
			setSelectData(e);
			setIsEdit(false);
			setIsNew(false);
			setOpenScheduleForm(true);
			return;
		}
		setHasNoClient(true);
	};

	const eventEdit = (e: any): void => {
		// setOpenTooltip(true);
		setSelectData(e);
		setIsEdit(true);
		setIsNew(false);
		setOpenScheduleForm(true);
	};

	const addEvent = (): void => {
		setSelectData(null);
		setIsNew(true);
		setIsEdit(false);
		setOpenScheduleForm(true);
	};

	const addClient = (): void => {
		setHasNoClient(false);
		navigate('/client');
	};
	const closeClientModal = (): void => {
		setHasNoClient(false);
	};

	// EventTooltip
	// const [isOpenTooltip, setOpenTooltip] = useState<boolean>(false);
	// const handleClick = (): void => {
	//     setOpenScheduleForm(true);
	//     setOpenTooltip(false);
	// };
	// const handleCloseTooltip = (): void => {
	//     setOpenTooltip(false);
	// };

	const handleSelectRestaurant = (): void => {
		setRestaurantListOpen(true);
	};

	const handleMonthChange = async (payload: any): Promise<any> => {
		const startDate = !isEmpty(payload.startStr)
			? dayjs(payload.startStr).format('YYYY-MM-DD')
			: dayjs(new Date()).format('YYYY-MM-DD');
		let endDate = !isEmpty(payload.endStr)
			? dayjs(payload.endStr).format('YYYY-MM-DD')
			: dayjs(new Date()).format('YYYY-MM-DD');

		if (payload.view.type === 'timeGridDay') {
			endDate = startDate;
		}

		setCurrentMonthStartDate(startDate);
		setCurrentMonthEndDate(endDate);

		if (!isEmpty(clientId)) {
			const data = {
				startDate,
				endDate,
				clientId,
				currentDate: dayjs(new Date()).format('YYYY-MM-DD'),
			};
			void dispatch(handleGetScheduleListV2(data));
		}
	};

	const handleClickRestaurant = (item: any): void => {
		setRestaurantListOpen(false);
		setRestaurant(item.name);
		setclientId(item.id);
	};

	const handleSelect = (arg: any): any => {
		if (
			dayjs(arg.start).format('YYYY-MM-DD') >
			dayjs(new Date()).format('YYYY-MM-DD')
		) {
			return true;
		} else if (
			dayjs(arg.start).format('YYYY-MM-DD') <
			dayjs(new Date()).format('YYYY-MM-DD')
		) {
			return false;
		} else {
			return true;
		}
	};

	const [eventList, setEventList] = useState<any>([]);

	useEffect(() => {
		const eventInfo = scheduleList?.map(
			(item: {
				schedule: any;
				date: any;
				startDate: any;
				color: any;
				id: any;
			}) => {
				let endTime = item?.schedule.endTime;
				if (item.schedule.endTime === '00:00:00') {
					endTime = '23:59:59';
				}
				return {
					scheduleId: item?.schedule?.id,
					title: item?.schedule?.title,
					date: item?.date,
					backgroundColor: item?.schedule?.color,
					start: `${item?.date}T${item?.schedule?.startTime}`,
					end: `${item?.date}T${endTime}`,
					extendedProps: item
				};
			}
		);
		setEventList(eventInfo);
	}, [scheduleList]);

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const eventDidMount = (info: any) => {
		const { event, el } = info;
		if (event.extendedProps?.schedule?.isShuffle) {
			el.classList.add('shuffle');
		}
	};

	return (
		<CalendarWrapper>
			<FullCalendar
				plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, momentPlugin]}
				initialView='timeGridWeek'
				customButtons={{
					addButton: {
						text: 'Add',
						click: addEvent
					},
					restaurantButton: {
						click: isEmpty(clientId) ? addClient : handleSelectRestaurant,
						text: isEmpty(clientId) ? 'Add Client' : `${restaurant}`
					}
				}}
				headerToolbar={{
					left: 'restaurantButton title',
					center: 'timeGridDay timeGridWeek dayGridMonth',
					right: 'prev,today,next,addButton'
				}}
				editable
				droppable
				eventStartEditable
				selectable={true}
				selectMirror={true}
				eventDidMount={eventDidMount}
				selectAllow={handleSelect}
				select={selectFunction}
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				datesSet={handleMonthChange}
				events={eventList}
				// eventClassNames={ isShuffle ? 'shuffle' : '' }
				eventTimeFormat={{
					hour: 'numeric',
					minute: '2-digit',
					meridiem: 'short'
					// hourCycle: 'h23'
				}}
				// eventColor={'#8E2734'}
				eventClick={eventEdit}
				eventReceive={(event) => {
					const scheduleFormData = {
						title: event?.event?.extendedProps?.schedule?.title,
						playlistId: event?.event?.extendedProps?.schedule?.playlistId,
						startDate: event?.event?.extendedProps?.schedule?.startDate,
						startTime: dayjs(event?.event?.start).format('HH:mm'),
						endTime: dayjs(event?.event?.end).format('HH:mm'),
						endDate: event?.event?.extendedProps?.schedule?.endDate,
						// timezone: values.timezone,
						repeat: event?.event?.extendedProps?.schedule?.repeat,
						clientId: event?.event?.extendedProps?.schedule?.clientId,
						recurrenceRule: event?.event?.extendedProps?.schedule?.recurrenceRule,
						recurrenceStartDate:
							event?.event?.extendedProps?.schedule?.recurrenceStartDate,
						type: 'month'
					};
					const scheduleId = Number(event?.event?.extendedProps?.schedule?.id);
					void dispatch(
						handleEditSchedule({ scheduleId, scheduleFormData, getScheduleList })
					);
				}}
				eventResize={(event) => {
					const scheduleFormData = {
						title: event?.event?.extendedProps?.schedule?.title,
						playlistId: event?.event?.extendedProps?.schedule?.playlistId,
						startDate: event?.event?.extendedProps?.schedule?.startDate,
						startTime: dayjs(event?.event?.start).format('HH:mm'),
						endTime: dayjs(event?.event?.end).format('HH:mm'),
						endDate: event?.event?.extendedProps?.schedule?.endDate,
						// timezone: values.timezone,
						repeat: event?.event?.extendedProps?.schedule?.repeat,
						clientId: event?.event?.extendedProps?.schedule?.clientId,
						recurrenceRule: event?.event?.extendedProps?.schedule?.recurrenceRule,
						recurrenceStartDate:
							event?.event?.extendedProps?.schedule?.recurrenceStartDate,
						type: 'month'
					};
					const scheduleId = Number(event?.event?.extendedProps?.schedule?.id);
					void dispatch(
						handleEditSchedule({ scheduleId, scheduleFormData, getScheduleList })
					);
				}}
				eventDrop={(event) => {
					const scheduleFormData = {
						title: event?.event?.extendedProps?.schedule?.title,
						playlistId: event?.event?.extendedProps?.schedule?.playlistId,
						startDate: event?.event?.extendedProps?.schedule?.startDate,
						startTime: dayjs(event?.event?.start).format('HH:mm'),
						endTime: dayjs(event?.event?.end).format('HH:mm'),
						endDate: event?.event?.extendedProps?.schedule?.endDate,
						// timezone: values.timezone,
						repeat: event?.event?.extendedProps?.schedule?.repeat,
						clientId: event?.event?.extendedProps?.schedule?.clientId,
						recurrenceRule: event?.event?.extendedProps?.schedule?.recurrenceRule,
						recurrenceStartDate:
							event?.event?.extendedProps?.schedule?.recurrenceStartDate,
						type: 'month'
					};
					const scheduleId = Number(event?.event?.extendedProps?.schedule?.id);
					void dispatch(
						handleEditSchedule({ scheduleId, scheduleFormData, getScheduleList })
					);
				}}
				eventDragStart={(event) => {}}
				allDaySlot={false}
				views={{
					dayGridMonth: {
						titleFormat: 'MMMM YYYY',
						dayHeaderFormat: 'dddd',
						forceEventDuration: true
					},
					timeGridWeek: {
						titleFormat: 'MMMM YYYY',
						dayHeaderFormat: 'D dddd',
						nowIndicator: true
					},
					timeGridDay: {
						titleFormat: 'D MMMM YYYY',
						dayHeaderFormat: 'dddd',
						nowIndicator: true,
						slotMinTime: '08:00:00',
					}
				}}
			/>
			{restaurantListOpen && (
				<Popover
					id='restaurant-list'
					open={restaurantListOpen}
					anchorReference='anchorPosition'
					className='calender-info-menu-list'
					anchorPosition={{ top: 140, left: 30 }}
					onClose={() => {
						setRestaurantListOpen(false);
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
				>
					{activeClient?.map((item, index): any => (
						<MenuItem
							key={index}
							value={item.id}
							onClick={() => {
								handleClickRestaurant(item);
							}}
						>
							{item.name}
						</MenuItem>
					))}
				</Popover>
			)}
			<Dialog
				// key={new Date().getTime().toString()}
				open={isOpenScheduleForm}
				onClose={() => {
					void dispatch(setPlaylistSongs([]));
					setOpenScheduleForm(false);
				}}
				fullWidth={true}
				maxWidth={'sm'}
				className='client-dialog-box'
			>
				<DialogContent>
					<ScheduleForm
						setOpenScheduleForm={setOpenScheduleForm}
						getScheduleList={getScheduleList}
						isEdit={isEdit}
						isNew={isNew}
						selectData={selectData}
						clientId={clientId}
						scheduleList={scheduleList}
					/>
				</DialogContent>
			</Dialog>
			<Dialog
				open={hasNoClient}
				onClose={closeClientModal}
				fullWidth={true}
				maxWidth={'sm'}
			>
				<DialogContent>
					<AddClientModal
						handleConfirmation={() => {
							addClient();
						}}
						closeModal={() => {
							closeClientModal();
						}}
						message={''}
						setConfirmation={function (arg: boolean): void {
							throw new Error('Function not implemented.');
						}}
					/>
				</DialogContent>
			</Dialog>
		</CalendarWrapper>
	);
};

export default Calendar;
