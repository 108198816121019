import React, { memo, type FC } from 'react';
import { Box, Typography, Stack, Chip, IconButton } from '@mui/material';
import RatingComponent from '../../components/RatingComponent';
import { convertToMinutesSeconds } from '../../utils/getTotalTime';
import { isEmpty } from '../../utils/validators';
import * as dayjs from 'dayjs';
import DataTable from '../../components/DataTable';
import DeleteIcon from '../../assets/images/icons/delete-icon.svg';
import EditNoteIcon from '../../assets/images/icons/edit-note-icon.svg';
import PlayIcon from '../../assets/images/icons/play-button-icon.svg';
import PauseIcon from '../../assets/images/icons/pause-button-icon.svg';

interface DataTableForSongProps {
	selectedSong: any;
	isPlaying: boolean;
	rowId: number;
	handleRowId: any;
	handleOnPlayPause: any;
	togglePlayPause: any;
	minSize: number;
	maxSize: number;
	getStatusString: any;
	songFormModalOpen: any;
	handleSelectSong: any;
	activeButton: string;
	focused: number;
	songs: any;
	setRowSelection: (e: any) => void;
	rowSelection: any;
	setId?: any;
	tableInstanceRef?: any;
	setSelectedRows?: any;
	alignment: any;
	page: any;
	sorting: any;
	setSorting: any;
	pagination: any;
	handleRatingChange: (value: number, songId: number) => void;
	sortDirection: string;
	orderBy: string;
	searchInput: string;
	dataLength: number;
}

const DataTableForSong: FC<DataTableForSongProps> = ({
	selectedSong,
	isPlaying,
	rowId,
	handleRowId,
	handleOnPlayPause,
	togglePlayPause,
	minSize,
	maxSize,
	getStatusString,
	songFormModalOpen,
	handleSelectSong,
	activeButton,
	focused,
	songs,
	setRowSelection,
	rowSelection,
	setId,
	tableInstanceRef,
	setSelectedRows,
	alignment,
	page,
	sorting,
	setSorting,
	pagination,
	handleRatingChange,
	sortDirection,
	orderBy,
	searchInput,
	dataLength
}) => {
	return (
		<div className={`songs-table-wrapper ${
			songs?.length === 0 ? 'empty-playlist' : ''
		}`}>
			<DataTable
				stateVar={isPlaying}
				rowId={rowId}
				isFromSong={true}
				getRowId={handleRowId}
				onPlayPause={handleOnPlayPause}
				setId={setId}
				tableInstanceRef={tableInstanceRef}
				setSelectedRows={setSelectedRows}
				sorting={sorting}
				setSorting={setSorting}
				enableRowNumbers={false}
				pagination={pagination}
				dataLength={dataLength}
				columns={[
					{
						accessorFn: (row: string) => `${row} ${row}`,
						id: '#',
						header: '#',
						minSize,
						size: 42,
						maxSize,
						enableSorting: false,
						enableResizing: false,
						muiTableHeadCellProps: {
							align: 'center'
						},
						Cell: ({ row }: any) => {
							return (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										gap: '0.2rem',
										lineHeight: 'normal'
									}}
								>
									<Box>
										<IconButton
											onClick={(e: any) => {
												e.stopPropagation();
												togglePlayPause(false, Number(row?.id), row?.original);
											}}
											className='playIcon'
											style={{ padding: '0', position: 'relative', top: '-1px' }}
										>
											{row?.original?.id === selectedSong?.id && isPlaying ? (
												<img style={{ width: '15px' }} src={PauseIcon} alt='' />
											) : (
												<img style={{ width: '12px' }} src={PlayIcon} alt='' />
											)}
										</IconButton>
									</Box>
								</Box>
							);
						}
					},
					{
						accessorFn: (row: string) => `${row} ${row}`,
						id: 'id',
						header: '#',
						minSize,
						size: 42,
						maxSize,
						enableSorting: false,
						enableResizing: false,
						muiTableHeadCellProps: {
							align: 'center'
						},
						Cell: ({ row }: any) => {
							return (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										gap: '0.2rem',
										lineHeight: 'normal'
									}}
								>
									<Box>
										<Typography sx={{ fontSize: '14px' }}>
											{Number(row?.id) + 1}
											{/* {Number(row?.id) + 1 + (page - 1) * pagination?.pageSize} */}
										</Typography>
									</Box>
								</Box>
							);
						}
					},
					{
						accessorKey: 'artist',
						header: 'Artist',
						minSize,
						size: 300,
						maxSize
					},
					{
						accessorKey: 'title',
						header: 'Title',
						minSize,
						size: 300,
						maxSize
					},
					{
						accessorFn: (row: string) => `${row} ${row}`,
						id: 'tags',
						header: 'Tags',
						minSize,
						size: 200,
						maxSize,
						// enableSorting: true,
						Cell: ({ row }: any) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',

									gap: '1rem'
								}}
							>
								<Stack direction='row' spacing={1}>
									{row?.original?.tags?.map(
										(
											itemName:
												| string
												| number
												| boolean
												| React.ReactElement<any, string | React.JSXElementConstructor<any>>
												| React.ReactFragment
												| React.ReactPortal
												| null
												| undefined,
											index: React.Key | null | undefined
										) => (
											<Chip sx={{ background: '#F2A892' }} key={index} label={itemName} />
										)
									)}
								</Stack>
							</Box>
						)
					},
					{
						accessorFn: (row: string) => `${row} ${row}`,
						id: 'duration',
						header: 'Duration',
						size: 60,
						minSize,
						maxSize,
						Cell: ({ row }: any) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',

									gap: '1rem'
								}}
							>
								<Typography sx={{ fontSize: '14px' }}>
									{convertToMinutesSeconds(row?.original?.duration)}
								</Typography>
							</Box>
						)
					},
					{
						accessorKey: 'createdAt',
						id: 'createdAt',
						header: 'Date',
						enableResizing: false,
						Cell: ({ row }: any) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '1rem'
								}}
							>
								<Typography sx={{ fontSize: '14px' }}>
									{dayjs.default(row.original.createdAt).format('YYYY-MM-DD')}
								</Typography>
							</Box>
						)
					},
					{
						accessorKey: 'createdAt',
						id: 'createdAt',
						header: 'Time',
						size: 75,
						enableResizing: false,
						Cell: ({ row }: any) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '1rem'
								}}
							>
								<Typography sx={{ fontSize: '14px' }}>
									{dayjs.default(row.original.createdAt).format('hh:mm A')}
								</Typography>
							</Box>
						)
					},
					{
						accessorFn: (row: string) => `${row} ${row}`,
						id: 'rating',
						header: 'Ratings',
						minSize,
						size: 100,
						maxSize,
						enableResizing: false,
						Cell: ({ row }: any) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '1rem'
								}}
							>
								<Typography>
									<RatingComponent
										currentValue={Number(row?.original?.rating)}
										songId={Number(row?.original?.id)}
										handleRatingChange={handleRatingChange}
									/>
								</Typography>
							</Box>
						)
					},
					{
						accessorFn: (row: string) => `${row} ${row}`,
						id: 'status',
						header: 'Status',
						minSize,
						size: 90,
						maxSize,
						Cell: ({ row }: any) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '1rem'
								}}
							>
								<Typography sx={{ fontSize: '14px' }}>
									{!isEmpty(row?.original?.status)
										? getStatusString(row?.original?.status)
										: '-'}
								</Typography>
							</Box>
						)
					},
					{
						accessorFn: (row: string) => `${row} ${row}`,
						id: 'actions',
						header: 'Action',
						minSize,
						size: 85,
						maxSize,
						enableSorting: false,
						enableResizing: false,
						Cell: ({ row }: any) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '1rem'
								}}
							>
								<div className='btn-wrapper'>
									<IconButton
										onClick={(e: any) => {
											e.stopPropagation();
											songFormModalOpen(row?.original, Number(row?.original?.id));
										}}
										className={
											activeButton === 'open' && focused === row.id
												? 'is-active'
												: 'table-button'
										}
									>
										<img src={EditNoteIcon} alt='' />
									</IconButton>
									<IconButton
										onClick={(e: any) => {
											e.stopPropagation();
											handleSelectSong(Number(row?.original?.id));
										}}
										className={
											activeButton === 'close' && focused === row?.original?.id
												? 'is-active'
												: 'table-button'
										}
									>
										<img src={DeleteIcon} alt='' />
									</IconButton>
								</div>
							</Box>
						)
					}
				]}
				data={songs}
				setRowSelection={setRowSelection}
				rowSelection={rowSelection}
				alignment={alignment}
				page={page}
				sortDirection={sortDirection}
				orderBy={orderBy}
				searchInput={searchInput}
			/>
		</div>
	);
};

export default memo(DataTableForSong);
