import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store'; 

interface SelectedSong {
    item : any;
    isPlay: boolean;
    isPause: boolean;
}
const initialState: SelectedSong = {
    item: undefined,
    isPause: false,
    isPlay: false,
};

export const selectedsongSlice = createSlice({
    name: 'selectedSong',
    initialState,
    reducers: {
        setSelectedSong: (state, action: PayloadAction<any>) => {
            state.item = action.payload;
        },
        setPlay: (state, action: PayloadAction<any>) => {
            state.isPlay = action.payload;
        },
        setPause: (state, action: PayloadAction<any>) => {
            state.isPause = action.payload;
        },
    },
});

export const { setSelectedSong, setPlay, setPause } = selectedsongSlice.actions;

export const isSelectedSong = (state: RootState): boolean => state.selectedsong.item;
export const isPlayed = (state: RootState): any => state.selectedsong.isPlay;
export const isPaused = (state: RootState): any => state.selectedsong.isPause;
export default selectedsongSlice.reducer;