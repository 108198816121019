import React, { type FC } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import InputComponent from '../../components/InputComponent';
import ButtonComponent from '../../components/ButtonComponent';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
	type CreateAndRenameModalInput,
	type CreateAndRenameModalProps
} from '../../modals';
import {
	ConfirmationModal,
	ConfirmationModalHeader,
	ConfirmationModalBody
} from './style';
import {
	handleCreatePlaylist,
	handleCreatePlaylistFolder,
	handleRenamePlaylist,
	handleRenamePlaylistFolder
} from '../../redux/actions/playlistAction';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';

const CreateAndRename: FC<CreateAndRenameModalProps> = ({
	onClose,
	setIsOpenCreateAndRename,
	selectedContextFolder,
	isNew,
	selectedPlaylist,
	isFromFolder
}) => {
	console.log('selectedPlaylist===>', selectedPlaylist);
	const dispatch = useAppDispatch();
	const { isLoading } = useAppSelector((state) => state.loading);

	const initialValues: CreateAndRenameModalInput = {
		name: isNew
			? ''
			: isFromFolder
			? String(selectedContextFolder?.name)
			: String(selectedContextFolder.name)
	};
	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Field is required')
	});
	const handleSubmit = (values: CreateAndRenameModalInput): void => {
		const name = { name: values.name?.trim() };
		if (isNew) {
			if (isFromFolder) {
				void dispatch(
					handleCreatePlaylistFolder({
						name,
						parentFolderId: selectedContextFolder?.id
					})
				);
			} else {
				void dispatch(
					handleCreatePlaylist({ name, parentFolderId: selectedContextFolder?.id })
				);
			}
		} else {
			if (isFromFolder) {
				void dispatch(
					handleRenamePlaylistFolder({ name, id: Number(selectedContextFolder?.id) })
				);
			} else {
				void dispatch(
					handleRenamePlaylist({
						name,
						id: Number(selectedContextFolder.playlistId)
					})
				);
			}
		}
		setIsOpenCreateAndRename(false);
		onClose();
	};
	return (
		<>
			<ConfirmationModal>
				<ConfirmationModalHeader>
					<Typography>
						{isNew ? 'Create ' + (isFromFolder ? 'Folder' : 'Playlist') : 'Rename'}
					</Typography>
					<IconButton
						aria-label='close'
						onClick={() => {
							setIsOpenCreateAndRename(false);
							onClose();
						}}
						className='popup-close-btn'
					>
						<CloseIcon />
					</IconButton>
				</ConfirmationModalHeader>
				<ConfirmationModalBody>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, handleBlur }) => (
							<Form>
								<div className='form-field'>
									<InputComponent
										id='name'
										name='name'
										value={values.name}
										placeholder='Name'
										type='text'
										variant='outlined'
										onBlur={handleBlur}
										onChange={handleChange}
										error={!!((touched.name ?? false) && errors.name != null)}
										helperText={(touched.name ?? false) && errors.name}
										required
									/>
								</div>
								<Box sx={{ textAlign: 'right' }}>
									<ButtonComponent
										variant='outlined'
										btnName='Cancel'
										onClick={() => {
											setIsOpenCreateAndRename(false);
											onClose();
										}}
									/>
									<ButtonComponent
										type='submit'
										variant='contained'
										btnName='Save'
										loading={isLoading}
									/>
								</Box>
							</Form>
						)}
					</Formik>
				</ConfirmationModalBody>
			</ConfirmationModal>
		</>
	);
};

export default CreateAndRename;
