import React, { type FC } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Box
} from '@mui/material';
import { TableWrapper } from './style';
import { isEmpty } from '../../utils/validators';
import LinearProgressWithLabel from '../LinearProgressComponent';
import { useAppSelector } from '../../redux/hooks';

interface DroppedFilesTableProps {
	files: File[];
	dropFiles?: File[];
	driveFiles?: File[];
	heading?: string;
}

const DroppedFilesTable: FC<DroppedFilesTableProps> = ({
	files,
	dropFiles = [],
	driveFiles = [],
	heading
}) => {
	const { isLoading } = useAppSelector((state) => state.loading);
	const { count, individualProgress } = useAppSelector(
		(state) => state.progressBar
	);

	const getProgress = (count: any, index: any): any => {
		if (count === index) {
			return individualProgress;
		} else if (index < count) {
			return 100;
		} else {
			return 0;
		}
	};

	return (
		<TableWrapper>
			{!isEmpty(heading) && <Typography variant='h6'>{heading}</Typography>}
			<TableContainer className='dropped-files-table'>
				<Box className='inner-dropped-files-table'>
					<Table stickyHeader aria-label='sticky table'>
						<TableHead>
							<TableRow>
								<TableCell>
									<p>Filename</p>
								</TableCell>
								<TableCell>
									<p>Size</p>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{files?.length > 0 &&
								files?.map((item, index) => (
									<TableRow key={index}>
										<TableCell>
											<ul>
												<li className='table-listing'>
													<p>{item.name}</p>
													{isLoading && (
														<LinearProgressWithLabel
															progress={getProgress(count, index + 1)}
														/>
													)}
												</li>
											</ul>
										</TableCell>
										<TableCell>
											<Typography>{(item.size / 1024 / 1024).toFixed(2)} MB</Typography>
										</TableCell>
									</TableRow>
								))}
							{dropFiles?.length > 0 &&
								dropFiles?.map((item: any, index: any) => (
									<TableRow key={index}>
										<TableCell>
											<ul>
												<li>
													<Typography>{item.name}</Typography>
												</li>
											</ul>
										</TableCell>
										<TableCell>
											<Typography>{(item.bytes / 1024 / 1024).toFixed(2)} MB</Typography>
										</TableCell>
									</TableRow>
								))}
							{driveFiles?.length > 0 &&
								driveFiles?.map((item: any, index: any) => (
									<TableRow key={index}>
										<TableCell>
											<ul>
												<li>
													<Typography>{item.name}</Typography>
												</li>
											</ul>
										</TableCell>
										<TableCell>
											<Typography>
												{(item.sizeBytes / 1024 / 1024).toFixed(2)} MB
											</Typography>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Box>
			</TableContainer>
		</TableWrapper>
	);
};

export default DroppedFilesTable;
