import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type StreamingState } from '../../modals';

const initialState: StreamingState = {
  streaming: null,
  streamingStatus: null,
  streamingInfo: null,
  currentStreamUrl: null,
};

export const streamingSlice = createSlice({
  name: 'streaming',
  initialState,
  reducers: {
    setStreaming: (state, action: PayloadAction<any>) => {
      state.streaming = action.payload;
    },
    setStreamingStatus: (state, action: PayloadAction<any>) => {
      state.streamingStatus = action.payload;
    },
    setStreamingInfo: (state, action: PayloadAction<any>) => {
      state.streamingInfo = action.payload;
    },
    setCurrentStreamUrl: (state, action: PayloadAction<any>) => {
      state.currentStreamUrl = action.payload;
    },

  },
});

export const { setStreaming, setStreamingStatus, setStreamingInfo, setCurrentStreamUrl } = streamingSlice.actions;

export default streamingSlice.reducer;