import React, { type FC } from 'react';
import { Typography } from '@mui/material';
import { type ErrorMsgInterface } from '../modals';

const ErrorMsgComponent: FC<ErrorMsgInterface> = ({ message }) => {
    return(
        <>
            <Typography color="error" sx={{ fontSize: '0.75rem', ml: '14px' }}>
                { message }
            </Typography>
        </>
    );
};

export default ErrorMsgComponent;