import React, { type FC, type InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { type SelectCompoInterface } from '../modals';
import { TextField, MenuItem } from '@mui/material';

const SelectComponent: FC<SelectCompoInterface & InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>> = (props) => {
    const { data, error, helperText, chieldNode, onchangeValue } = props;
    const [field] = useField(props);

    return(
        <>
            <TextField
                select
                { ...field }
                error={error}
                helperText={helperText}
                onChange={onchangeValue}
                // value={textValue}
            >
                {data?.map((item: any, index: number) => (
                    <MenuItem
                        key={index}
                        value={item.id}
                    >
                        {item.name}
                    </MenuItem>
                ))}
                {chieldNode}
            </TextField>
        </>
    );
};

export default SelectComponent;