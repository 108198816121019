import React, { useState, type FC, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
	Box,
	Typography,
	IconButton,
	Autocomplete,
	TextField
} from '@mui/material';
import ButtonComponent from '../../components/ButtonComponent';
import { type AssignTagFormProps, type SongTableDataType } from '../../modals';
import CloseIcon from '@mui/icons-material/Close';
import { SongModal, SongModalHeader, SongModalBody } from './style';
import { useAppDispatch } from '../../redux/hooks';
import { handleAssignTagAction } from '../../redux/actions/songsAction';
import ErrorMsgComponent from '../../components/ErrorMsgComponent';
import { setLoadingState } from '../../redux/slice/loadingSlice';

const AssignTagForm: FC<AssignTagFormProps> = ({
	setIsTagAssign,
	songIds,
	alignment,
	page,
	orderBy,
	sortDirection,
	resetProps,
	handleClear,
	isSong
}) => {
	const dispatch = useAppDispatch();
	const tagList: any[] = ['New Year', 'Night Event', 'Birthday', 'Christmas'];
	const [tagValue, setTagValue] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const [emptyTagError, setEmptyTagError] = useState(false);

	const textFieldRef = useRef<HTMLInputElement>(null);

	// Formik
	const initialValues: SongTableDataType = {id:0};
	const handleSubmit = (values: SongTableDataType): void => {
		if (tagValue.length === 0) {
			setEmptyTagError(true);
			return;
		} else setEmptyTagError(false);
		setIsLoading(true);
		const data = {
			tags: tagValue,
			songIds
		};
		handleClear();
		dispatch(setLoadingState(true));
		void dispatch(
			handleAssignTagAction({
				body: data,
				params: { ...resetProps, isSong },
				orderBy,
				sortDirection,
				alignment
			})
		);
		setIsLoading(false);
		setIsTagAssign(false);
	};

	const handleTagChange = (
		event: React.SyntheticEvent,
		values: string[]
	): void => {
		setTagValue(values);
		setInputValue('');
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const newTags = pastedText.split(',').map((tag) => tag.trim());
		setTagValue([...tagValue, ...newTags]);

		setTimeout(() => {
			if (textFieldRef.current != null) {
				textFieldRef.current.value = '';
			}
		}, 100);
	};

	const handleInputBlur = (): void => {
		setInputValue('');
	};

	useEffect(() => {
		if (textFieldRef.current != null) {
			textFieldRef.current.value = inputValue;
		}
	}, [inputValue]);

	return (
		<>
			<SongModal>
				<Box sx={{ display: 'flex' }}>
					<SongModalHeader>
						<Typography variant='h5'>Assign Tag</Typography>
						<IconButton
							aria-label='close'
							onClick={() => {
								setIsTagAssign(false);
							}}
							className='popup-close-btn'
						>
							<CloseIcon />
						</IconButton>
					</SongModalHeader>
				</Box>
				<SongModalBody>
					<Formik initialValues={initialValues} onSubmit={handleSubmit}>
						{() => {
							return (
								<Form>
									<div className='form-field select-option-tags'>
										<Typography variant='h6'>Tags</Typography>
										<div>
											<Autocomplete
												freeSolo
												multiple
												id='tag-select'
												className='tags-wrapper'
												onChange={handleTagChange}
												inputValue={inputValue}
												options={tagList.map((option) => option)}
												value={tagValue}
												onInputChange={(event, value) => {
													if (value.trim() !== '') {
														setInputValue(value);
													} else if (value === '') {
														setInputValue('');
													}
												}}
												renderInput={(params) => {
													return (
														<TextField
															{...params}
															placeholder='tags'
															inputRef={textFieldRef}
															onPaste={handlePaste}
															onBlur={handleInputBlur}
														/>
													);
												}}
											/>
											{emptyTagError && (
												<ErrorMsgComponent message='At least one tag is required.' />
											)}
										</div>
									</div>
									<Box sx={{ textAlign: 'right' }} className='form-buttons'>
										<ButtonComponent
											variant='outlined'
											btnName='Cancel'
											onClick={() => {
												setIsTagAssign(false);
											}}
										/>
										<ButtonComponent
											type='submit'
											variant='contained'
											btnName='Submit'
											disabled={isLoading}
											loading={isLoading}
										/>
									</Box>
								</Form>
							);
						}}
					</Formik>
				</SongModalBody>
			</SongModal>
		</>
	);
};

export default AssignTagForm;
