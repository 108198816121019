import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { type SnackbarState } from '../../modals';

const initialState: SnackbarState = {
    isOpen: false,
    responseMsg: '',
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        setSnackbarState: (state, action: PayloadAction<any>) => {
            state.isOpen = action.payload;
        },
        setSnackbarMessage: (state, action: PayloadAction<any>) => {
            state.responseMsg = action.payload;
        },
    },
});

export const { setSnackbarState, setSnackbarMessage } = snackbarSlice.actions;

export const isOpen = (state: RootState): boolean => state.snackbar.isOpen;
export const responseMsg = (state: RootState): string => state.snackbar.responseMsg;

export default snackbarSlice.reducer;
