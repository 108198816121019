import React, { useEffect, useState, type FC } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
	Box,
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Menu,
	MenuItem
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import personIcon from '../assets/images/icons/profile.svg';
import exitIcon from '../assets/images/icons/exit.svg';
import logo from '../assets/images/logo.svg';
import '../style/main.scss';
import Divider from '@mui/material/Divider';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { handleGetCurrentUser } from '../redux/actions/loginAction';

const Header: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		const currentUserId = localStorage.getItem('currentUser');
		void dispatch(handleGetCurrentUser(Number(currentUserId)));
	}, []);

	const { currentUser } = useAppSelector((state) => state.auth);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const logout = (): void => {
		setAnchorEl(null);
		navigate('/login');
		localStorage.clear();
	};

	const location = useLocation();
	const isActive = (path: string): string => {
		return location.pathname === path ? 'active' : '';
	};

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar position='static' className='header'>
					<Toolbar>
						<Typography variant='h6' component='div'>
							<img
								src={logo}
								alt=''
								onClick={() => {
									navigate('/client');
								}}
								style={{ cursor: 'pointer' }}
							/>
						</Typography>
						<Link to='/client' className={isActive('/client')}>
							Client
						</Link>
						<Link to='/import' className={isActive('/import')}>
							Import
						</Link>
						<Link to='/songs' className={isActive('/songs')}>
							Library
						</Link>
						<Link to='/play-list' className={isActive('/play-list')}>
							Playlist
						</Link>
						<Link to='/calendar' className={isActive('/calendar')}>
							Calendar
						</Link>
						<IconButton size='large' edge='end' color='primary' onClick={handleClick}>
							<AccountCircle />
						</IconButton>
						<Menu
							id='basic-menu'
							anchorEl={anchorEl}
							open={open}
							className='profile-menu'
							onClose={handleClose}
						>
							<MenuItem onClick={handleClose}>
								<Link to='/profile'>
									<img src={personIcon} alt='' /> {currentUser.name}
								</Link>
							</MenuItem>
							<MenuItem onClick={handleClose}>
								<Link to='/crossFade'>Cross Fade</Link>
							</MenuItem>
							<Divider className='profile-devider' />
							<MenuItem onClick={logout}>
								<img src={exitIcon} alt='' /> Logout
							</MenuItem>
						</Menu>
					</Toolbar>
				</AppBar>
			</Box>
		</>
	);
};

export default Header;
