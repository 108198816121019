import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AppDispatch } from '../store';
import { setLoadingState, setLoadingStateV2 } from '../slice/loadingSlice';
import { handleLogout } from '../../utils/handleLogout';
import { setSnackbarState, setSnackbarMessage } from '../slice/snackbarSlice';
import { streamingService } from '../../services/streamingService';
import { setCurrentStreamUrl, setStreaming, setStreamingInfo, setStreamingStatus } from '../slice/streamingSlice';

export const handleGetStreaming = createAsyncThunk<
  object,
  any,
  {
    dispatch: AppDispatch
  }
>
  (
    'client/addClient',
    async (params, { dispatch }) => {
      try {
        const response: any = await streamingService.getStreaming(params);
        dispatch(setStreaming(response.data.data));
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        void dispatch(handleGetStreamInfo({url: `${response.data.data?.url?.stream}/info`}));
        dispatch(setLoadingStateV2(false));
        // dispatch(setSnackbarState(true));
        // dispatch(setSnackbarMessage(response.data.message));
        dispatch(setLoadingState(false));
      } catch (error: any) {
        dispatch(setLoadingStateV2(false));
        dispatch(setLoadingState(false));
        dispatch(setSnackbarState(true));
        dispatch(setSnackbarMessage(error.response.data.message));
        if (error.response.status === 401) {
          handleLogout();
        }
      }
    }
  );

export const handleRunStreaming = createAsyncThunk<
  object,
  any,
  {
    dispatch: AppDispatch
  }
>
  (
    'client/addClient',
    async (params, { dispatch }) => {
      try {
        const response: any = await streamingService.startStreaming(params);
        dispatch(setStreamingStatus(response.data.data));
        void dispatch(handleGetStreaming({ clientId: params?.clientId }));
        dispatch(setSnackbarState(true));
        dispatch(setSnackbarMessage(response.data.message));
      } catch (error: any) {
        dispatch(setSnackbarState(true));
        dispatch(setSnackbarMessage(error.response.data.message));
        if (error.response.status === 401) {
          handleLogout();
        }
      }
    }
  );

  export const handleGetStreamInfo = createAsyncThunk<
    object,
    any,
    {
      dispatch: AppDispatch
    }>
    (
      'getStream/info',
      async (params, { dispatch }) => {
        try {
          dispatch(setCurrentStreamUrl(params.url));
          const response: any = await streamingService.getStreamingInfo(params.url);
          dispatch(setStreamingInfo(response.data.data));
        } catch (error: any) {
          console.error('error', error);
          dispatch(setStreamingInfo(null));
          // dispatch(setSnackbarState(true));
          // dispatch(setSnackbarMessage(error.response.data.message));
        }
      }
    );
    