/* eslint-disable @typescript-eslint/explicit-function-return-type */
interface Progress {
  progressBarRef?: any,
  audioRef?: any,
  timeProgress?: any,
  duration?: any,
  currentTrack?: any,
}
const ProgressBar = ({
    progressBarRef,
    audioRef,
    timeProgress,
    duration,
    currentTrack
  }: Progress) => {
    const handleProgressChange = () => {
      audioRef.current.currentTime = progressBarRef.current?.value;
    };
  
    const formatTime = (time: any) => {
      if ((Boolean(time)) && !isNaN(time)) {
        const minutes = Math.floor(time / 60);
        const formatMinutes =
          minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(time % 60);
        const formatSeconds =
          seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${formatMinutes}:${formatSeconds}`;
      }
      return '00:00';
    };
  
    return (
      <div className='main_progress'>
        <div className="audio_text">
          <p>{currentTrack?.author }</p>
        </div>
        <div className="progress">
        <span className="time current timeprogress">{formatTime(timeProgress)}</span>
          <input
            type="range"
            ref={progressBarRef}
            defaultValue="0"
            onChange={handleProgressChange}
          />            
          <span className="time duration">{formatTime(duration)}</span>
          
        </div>
      </div>
    );
  };
  
  export default ProgressBar;
