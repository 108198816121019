import { createAsyncThunk } from '@reduxjs/toolkit';
import { clientService } from '../../services/clientService';
import type { AppDispatch } from '../store';
import { setLoadingState } from '../slice/loadingSlice';
import { handleLogout } from '../../utils/handleLogout';
import { setSnackbarState, setSnackbarMessage } from '../slice/snackbarSlice';
import { setClientList, setTotalCount, setActiveClientList } from '../slice/clientSlice';
import { type SerchParams } from '../../modals';
import { isEmpty } from '../../utils/validators';

export const handleAddClient = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'client/addClient',
        async (params, { dispatch }) => {
            try {
                const response: any = await clientService.addClient(params.data);
                void dispatch(handleGetClientList({}));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                void dispatch(handleGetClientList({ type: 'all', search: params?.bodyParams?.searchInput }));
                dispatch(setLoadingState(false));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleGetClientList = createAsyncThunk<
    object,
    SerchParams,
    {
        dispatch: AppDispatch
    }
>
    (
        'client/getClient',
        async (params, { dispatch }) => {
            try {
                const response: any = await clientService.getClientList(params);
                if (!isEmpty(params.type)) {
                    dispatch(setClientList(response.data.data.results));
                } else {
                    dispatch(setActiveClientList(response.data.data.results));
                }
                dispatch(setTotalCount(response.data.data.totalCount));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleUpdateClient = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'client/updateClient',
        async (params, { dispatch }) => {
            try {
                const response: any = await clientService.updateClient(params);
                void dispatch(handleGetClientList({ type: 'all', search: params?.bodyParams?.searchInput }));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleDeleteClient = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'client/deleteClient',
        async (params, { dispatch }) => {
            try {
                const response: any = await clientService.deleteClient(params);
                void dispatch(handleGetClientList({ type: 'all', search: params?.searchInput }));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );