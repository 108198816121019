import React, { type FC } from 'react';
// import { useAppSelector } from '../redux/hooks';
import { Navigate, useLocation } from 'react-router-dom';

interface PropType {
    component: React.FC;
}

export const PublicRoute: FC<PropType> = ({ component: Component }) => {
	const location = useLocation();
    const isLoggedIn: any = localStorage.getItem('isLoggedIn');

	if (JSON.parse(isLoggedIn) !== true) {
		return <Component/>;
	}
	return <Navigate to="/songs" state={{ from: location }} />;
};