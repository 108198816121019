import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { type ClientState } from '../../modals';

const initialState: ClientState = {
    clientList: [],
    totalCount: 0,
    activeClient: []
};

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setClientList: (state, action: PayloadAction<any>) => {
            state.clientList = action.payload;
        },
        setTotalCount: (state, action: PayloadAction<any>) => {
            state.totalCount = action.payload;
        },
        setActiveClientList: (state, action: PayloadAction<any>) => {
            state.activeClient = action.payload;
        },
    },
});

export const { setClientList, setTotalCount, setActiveClientList } = clientSlice.actions;

export const clientList = (state: RootState): any => state.client.clientList;
export const totalCount = (state: RootState): number => state.client.totalCount;

export default clientSlice.reducer;