/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useEffect, useState, type FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropboxChooser } from 'use-dropbox-chooser';
import { DropboxAppKey, clientId } from '../../utils/config';
import { TextField, Typography, Autocomplete, IconButton } from '@mui/material';
import ButtonComponent from '../../components/ButtonComponent';
import ErrorMsgComponent from '../../components/ErrorMsgComponent';
import dropBox from '../../assets/images/icons/drop-box.svg';
import gDrive from '../../assets/images/icons/g-drive.svg';
// import iCloud from '../../assets/images/icons/i-cloud.svg';
import {
	ImportBoxWrapper,
	ImportBoxBody,
	TagsWrapper,
	ImportBoxHeader
} from './style';
import {
	handleAddSongFromDrive,
	handleAddSongFromDropBox,
	handleAddSongV2
} from '../../redux/actions/songsAction';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import LinearProgressWithLabel from '../../components/LinearProgressComponentV2';
import DroppedFilesTable from '../../components/DroppedFilesTable/index';
import useDrivePicker from 'react-google-drive-picker';
import { songsService } from '../../services/songsService';
import { isEmpty } from '../../utils/validators';
import {
	setCount,
	setTotalCount,
	setProgress,
	setIndividualProgress
} from '../../redux/slice/progressBarSlice';
import { socket } from '../../socket';
import {
	setSnackbarMessage,
	setSnackbarState
} from '../../redux/slice/snackbarSlice';
import { setLoadingState } from '../../redux/slice/loadingSlice';

const ImportSong: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const tagList = ['New Year', 'Night Event', 'Birthday', 'Christmas'];
	const [tags, setTags] = useState<string[]>([]);
	const [file, setFile] = useState<any[]>([]);
	const [dropFile, setDropFile] = useState<any>([]);
	const [driveFile, setDriveFile] = useState<any[]>([]);
	const [isImportFileError, setImportFileError] = useState(false);
	const [tagError, setTagError] = useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const [isFromDropBox, setIsFromDropBox] = useState(false);
	const [isFromDrive, setIsFromDrive] = useState(false);
	const isLoader = useAppSelector((state) => state.loading.isLoading);
	// const [count, setCount] = useState(0);
	// const [progress, setProgress] = useState(0);
	// const socket = io(socketUrlForProgress);
	const [openPicker] = useDrivePicker();
	const authCode = useRef<any>();
	const authResponseUrl = useRef<any>();
	const textFieldRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		socket.connect();
	}, []);

	useEffect(() => {
		const unloadCallback = (event: any): any => {
			event.preventDefault();
			event.returnValue = '';
			return '';
		};

		window.addEventListener('beforeunload', unloadCallback);
		return () => {
			window.removeEventListener('beforeunload', unloadCallback);
		};
	}, []);

	useEffect(() => {
		const urlString = window.location.href;
		const codeRegex = /code=([^&]+)/;
		const match = urlString.match(codeRegex);
		const code = match != null ? decodeURIComponent(match[1]) : null;

		authResponseUrl.current = urlString;
		if (!isEmpty(code)) {
			authCode.current = code;
			setTimeout(() => {
				handleAuthResponse();
			}, 500);
		}
	}, []);

	const handleDropbox = async (): Promise<any> => {
		try {
			await open();
		} catch (e) {
			console.log('Cancel');
		}
	};
	const { open, isOpen } = useDropboxChooser({
		appKey: DropboxAppKey,
		chooserOptions: {
			multiselect: true,
			linkType: 'direct',
			extensions: ['.mp3']
		},
		onSelected: (files) => {
			setFile([]);
			setDropFile(files);
			setIsFromDropBox(true);
			setImportFileError(false);
		}
	});

	const handleFileChange = (event: any): void => {
		event.preventDefault();
		setIsFromDropBox(false);
		setIsFromDrive(false);
		setDropFile([]);
		setDriveFile([]);
		if (event.target.files.length > 0) {
			const songFile: any[] = [];
			Array.from(event.currentTarget.files).forEach((file: any) => {
				songFile.push(file);
			});
			setTimeout(() => {
				setFile(songFile);
				setImportFileError(false);
			}, 1000);
		}
		event.target.value = '';
		// else {
		// 	setFile([]);
		// }
	};

	const handleClear = (): void => {
		setTags([]);
		setFile([]);
		setDropFile([]);
		setDriveFile([]);
	};
	const beforeImportSong = (): void => {
		const songFile = dropFile.concat(file, driveFile);
		songFile.length === 0 ? setImportFileError(true) : setImportFileError(false);
		songFile.length > 0 && handleImportSong(songFile);
	};

	const handleProgressStateClear = () => {
		dispatch(setCount(0));
		dispatch(setTotalCount(0));
		dispatch(setProgress(0));
		dispatch(setIndividualProgress(0));
	};

	const handleImportSong = (songFile: any[]): void => {
		if (isFromDropBox) {
			const songs = [];
			for (const item of songFile) {
				songs.push(item);
			}
			const data = {
				songs,
				tags: tags.toString()
			};
			void dispatch(
				handleAddSongFromDropBox({
					body: data,
					importSuccess: songImportSuccess,
					handleClear: handleProgressStateClear
				})
			);
			return;
		}

		if (isFromDrive) {
			const songs = [];
			for (const item of songFile) {
				songs.push(item);
			}
			const data = {
				songs,
				tags: tags.toString(),
				authorizationCode: authCode.current
			};
			void dispatch(
				handleAddSongFromDrive({
					body: data,
					importSuccess: songImportSuccess,
					handleClear: handleProgressStateClear
				})
			);
			return;
		}

		// const data = new FormData();
		// for (const item of songFile) {
		// 	data.append('song', item);
		// }
		// data.append('tags', tags.toString());
		const data = {
			files: songFile,
			tags: tags.toString(),
			isPlaylistAvailable: false
		};

		dispatch(setTotalCount(songFile?.length));
		dispatch(setLoadingState(true));
		void dispatch(
			handleAddSongV2({
				body: data,
				params: {
					body: data,
					importSuccess: songImportSuccess,
					handleClear: handleProgressStateClear
				}
			})
		);
		// void dispatch(
		// 	handleAddSong({
		// 		body: data,
		// 		importSuccess: songImportSuccess,
		// 		handleClear: handleProgressStateClear
		// 	})
		// );
	};
	const songImportSuccess = (): void => {
		navigate('/songs');
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.currentTarget.parentElement?.parentElement?.classList.add('open');
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
		event.preventDefault();
		// setIsFromDropBox(true);
		setIsFromDrive(false);
		setDriveFile([]);
		const files = event.dataTransfer.files;
		const songFiles: any[] = [];
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (file?.type === 'audio/mpeg') {
				songFiles.push(file);
			}
		}
		if (songFiles?.length === 0) {
			dispatch(setSnackbarState(true));
			dispatch(setSnackbarMessage('Only mp3 files are allowed.'));
		}
		setFile((prevFiles: any[]) => [...prevFiles, ...songFiles]);
	};
	// eslint-disable-next-line @typescript-eslint/ban-types
	// const handleTagInputChange = (event: React.ChangeEvent<{}>, value: string) => {
	//     // Check if the input value ends with a comma or space to detect a paste action
	//     if (/[\s,]$/.test(value)) {
	//       // Remove the comma or space and add the tag to the list
	//       const newTag = value.trim().replace(/[\s,]+/g, '');
	//       if (newTag.length > 0) {
	//         setTags([...tags, newTag]);
	//       }
	//     }
	//   };

	const handleAuthResponse = () => {
		openPicker({
			clientId,
			developerKey: '',
			viewId: 'DOCS',
			showUploadView: true,
			showUploadFolders: true,
			supportDrives: true,
			multiselect: true,

			callbackFunction: (data: any) => {
				if (data.action === 'cancel') {
					console.log('User clicked cancel/close button');
				}
				if (!isEmpty(data.docs)) {
					setIsFromDrive(true);
					setDriveFile(data.docs);
				}
			}
		});
	};

	const handleOpenPicker = async () => {
		const authUrl = await songsService.getAuthUrl();

		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (authUrl?.data?.message) {
			window.open(authUrl.data.message, '_blank');
			// window.location.href = authUrl?.data?.message;
		}
	};

	const handleTagChange = (
		event: React.SyntheticEvent,
		values: string[]
	): void => {
		setTags(values);
		setTagError(false);
		setInputValue('');
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const newTags = pastedText.split(',').map((tag) => tag.trim());
		setTags([...tags, ...newTags]);

		setTimeout(() => {
			if (textFieldRef.current != null) {
				textFieldRef.current.value = '';
			}
		}, 100);
	};

	const handleInputBlur = (): void => {
		setInputValue('');
	};

	useEffect(() => {
		if (textFieldRef.current != null) {
			textFieldRef.current.value = inputValue;
		}
	}, [inputValue]);

	return (
		<>
			<ImportBoxWrapper>
				<ImportBoxHeader>
					<Typography variant='h4'>Import songs</Typography>
				</ImportBoxHeader>
				<ImportBoxBody>
					<div>
						<Typography variant='h5'>
							Drop files here, <label htmlFor='dropbox'>browse files</label> or import
							from:
						</Typography>
						<div onDrop={handleDrop} onDragOver={handleDragOver}>
							<input
								id='dropbox'
								name='dropbox'
								placeholder='Drop files here'
								type='file'
								onChange={handleFileChange}
								accept='.mp3'
								required
								multiple
							/>
						</div>
					</div>
					<div className='icon-wrapper'>
						<div className='icon'>
							<IconButton
								onClick={() => {
									void handleDropbox();
								}}
								disabled={isOpen}
							>
								<img src={dropBox} />
							</IconButton>
							<p>Dropbox</p>
						</div>
						<div className='icon'>
							<IconButton
								onClick={() => {
									void handleOpenPicker();
								}}
								disabled={isOpen}
							>
								<img src={gDrive} />
							</IconButton>
							<p>Google Drive</p>
						</div>
						{/* <div className='icon'>
                            <IconButton
                                onClick={() => { void handleDropbox();}}
                                disabled={isOpen}
                            >
                                <img src={iCloud} />
                            </IconButton>
                            <p>iCloud</p>
                        </div> */}
					</div>
				</ImportBoxBody>
				{isImportFileError && <ErrorMsgComponent message='File is required' />}
				<TagsWrapper>
					<Typography variant='h6'>Tags</Typography>
					<Autocomplete
						freeSolo
						multiple
						id='tag-select'
						className='tags-wrapper'
						onChange={handleTagChange}
						inputValue={inputValue}
						options={tagList.map((option) => option)}
						value={tags}
						onInputChange={(event, value) => {
							if (value.trim() !== '') {
								setInputValue(value);
							} else if (value === '') {
								setInputValue('');
							}
						}}
						renderInput={(params) => {
							return (
								<TextField
									{...params}
									inputRef={textFieldRef}
									onPaste={handlePaste}
									onBlur={handleInputBlur}
								/>
							);
						}}
					/>
				</TagsWrapper>
				{tagError && <ErrorMsgComponent message='Tag is required' />}
				<div className='modal-buttons'>
					{isLoader && <LinearProgressWithLabel />}
					{(dropFile.length > 0 || file.length > 0 || driveFile.length > 0) && (
						<ButtonComponent
							variant='outlined'
							btnName='Clear'
							onClick={handleClear}
							disabled={isLoader}
						/>
					)}
					<ButtonComponent
						variant='contained'
						btnName='Import'
						onClick={beforeImportSong}
						loading={isLoader}
						disabled={isLoader}
					/>
				</div>
				{(file.length > 0 || dropFile.length > 0 || driveFile.length > 0) && (
					<DroppedFilesTable
						files={file}
						dropFiles={dropFile}
						driveFiles={driveFile}
						heading='Dropped files'
					/>
				)}
			</ImportBoxWrapper>
		</>
	);
};

export default ImportSong;
