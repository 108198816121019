import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginService } from '../../services/loginService';
import { setLoadingState } from '../slice/loadingSlice';
import { setSnackbarState, setSnackbarMessage } from '../slice/snackbarSlice';
import type { AppDispatch } from '../store';
import { type EditFormInputInterface, type ParamsType } from '../../modals';
import { setAuthState, setCurrentUser } from '../slice/authSlice';
import { handleLogout } from '../../utils/handleLogout';

export const handleLoginUser = createAsyncThunk<
    object,
    ParamsType,
    {
        dispatch: AppDispatch
    }
>
(
    'user/login',
    async(params, { dispatch }) => {
        try {
            const response: any = await loginService.loginUser(params.values);
            const userToken: any = response.headers.token;
            localStorage.setItem('userToken', userToken);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('currentUser', response.data.data.id);
            void dispatch(handleGetCurrentUser(response.data.data.id));
            dispatch(setAuthState(true));
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(response.data.message));
            dispatch(setLoadingState(false));
            params.login();
        } catch (error: any) {
            dispatch(setAuthState(false));
            dispatch(setLoadingState(false));
            dispatch(setSnackbarState(true));
            if(error.message === 'Network Error')
            {
              return  dispatch(setSnackbarMessage(error.message));
            }
            dispatch(setSnackbarMessage(error.response.data.message));
        }  
    }
);

export const handleEditUsersProfile = createAsyncThunk<
    object,
    EditFormInputInterface,
    {
        dispatch: AppDispatch
    }
>
(
    'user/editProfile',
    async(params, { dispatch }) => {    
        const currentUserId = localStorage.getItem('currentUser');    
        try {
            const response: any = await loginService.editUsersProfile(params);
            void dispatch(handleGetCurrentUser(Number(currentUserId)));
            dispatch(setAuthState(true));
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(response.data.message));
            dispatch(setLoadingState(false));
        } catch (error: any) {
            dispatch(setAuthState(false));
            dispatch(setLoadingState(false));
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(error.response.data.message));
        }  
    }
);

export const handleGetCurrentUser = createAsyncThunk<
    object,
    number,
    {
        dispatch: AppDispatch
    }
>
(
    'user/currentUser',
    async(params, { dispatch }) => {        
        try {
            const response: any = await loginService.getCurrentUser(params);
            dispatch(setCurrentUser(response.data.data));
        } catch (error: any) {
            dispatch(setSnackbarState(true));
            dispatch(setSnackbarMessage(error.response.data.message));
            if(error.response.status === 401) {
                handleLogout();
            }
        }  
    }
);