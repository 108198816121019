import React, { type FC } from 'react';
import { type ConfirmationProps } from '../../modals';
import { Box, Typography , IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ButtonComponent from '../../components/ButtonComponent';

const AddClientModal: FC<ConfirmationProps>  = ({handleConfirmation, closeModal}) => {
    return(
        <div className='confirmation-modal full-content'>
            <div className='confirmation-title'>
                <Typography variant="h5" >Add Client</Typography>
                <IconButton
                    aria-label="close"
                    className="popup-close-btn"
                    onClick={closeModal}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className='confirmation-body'>
                <Typography>There is no client exist.</Typography>
                <Box className="modal-buttons">
                    <ButtonComponent
                        variant="outlined"
                        btnName="Cancel"
                        onClick={closeModal}
                    />
                    <ButtonComponent
                        type="button"
                        variant="contained"
                        btnName="Add Client"
                        onClick={handleConfirmation}
                    />
                </Box>
            </div>
        </div>
    );
};

export default AddClientModal;