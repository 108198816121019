/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, type FC } from 'react';
import { Formik, Form } from 'formik';
import InputComponent from '../../components/InputComponent';
import {
	type ClientFormProps,
	type ClientFormInputInterface,
	type isDisableType
} from '../../modals';
import ButtonComponent from '../../components/ButtonComponent';
import ErrorMsgComponent from '../../components/ErrorMsgComponent';
import ToggleButton from '../../components/ToggleButton';
import { PrefixUrl } from '../../utils/config';
import {
	handleAddClient,
	handleUpdateClient
} from '../../redux/actions/clientAction';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { setLoadingState } from '../../redux/slice/loadingSlice';
import { ClientModal, ClientModalHeader, ClientModalForm } from './style';
import { AddClientFormSchema, EditClientFormSchema } from './validation';
import uploadLogoIcon from '../../assets/images/icons/upload-logo-icon.svg';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { isEmpty } from '../../utils/validators';
import Select from 'react-select';
import {
	getAllCountries,
	getTimezone,
	getTimezonesForCountry
} from 'countries-and-timezones';
import { setSnackbarMessage, setSnackbarState } from '../../redux/slice/snackbarSlice';

const ClientForm: FC<ClientFormProps> = ({
	setOpenMode,
	preFillData,
	isEdit,
	currentStatus,
	bodyParams
}) => {
	const dispatch = useAppDispatch();
	const { isLoading } = useAppSelector((state) => state.loading);
	// Formik
	const initialValues: ClientFormInputInterface = {
		name: isEdit ? preFillData.name : '',
		email: isEdit ? preFillData.email : '',
		password: '',
		country: isEdit && !isEmpty(preFillData?.country) ? preFillData.country : '',
		timezone:
			isEdit && !isEmpty(preFillData?.timezone) ? preFillData.timezone : ''
	};
	// const initialValues: ClientFormInputInterface = {
	// 	name: isEdit ? preFillData.name : '',
	// 	email: isEdit ? preFillData.email : '',
	// 	password: '',
	// 	country: isEdit
	// 		? { value: preFillData.country, label: preFillData.country }
	// 		: null,
	// 	timezone: isEdit
	// 		? { value: preFillData.timezone, label: preFillData.timezone }
	// 		: null
	// };

	const validationSchema = AddClientFormSchema;
	const validationSchemaForEdit = EditClientFormSchema;

	// Logo image
	const [file, setfile] = useState<any | null>(null);
	const [url, setUrl] = useState<any>(
		isEdit ? `${PrefixUrl}${String(preFillData.logo)}` : null
	);
	const [fileName, setFileName] = useState<string>('');
	const [isUploadImageError, setIsUploadImageError] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState<any>(
		isEdit && !isEmpty(preFillData?.country)
			? {
					value: preFillData.country,
					label: preFillData.country
			  }
			: null
	);
	const [timezones, setTimezones] = useState<string[]>([]);
	const [selectedTimezone, setSelectedTimezone] = useState<any>(
		isEdit && !isEmpty(preFillData?.timezone)
			? { value: preFillData.timezone, label: preFillData.timezone }
			: null
	);

	const onImageChange = (event: any): void => {
		if (event.currentTarget.files.length === 0) return;

		setfile(event.currentTarget.files[0]);
		const reader = new FileReader();
		reader.readAsDataURL(event.currentTarget.files[0]);
		reader.onload = () => {
			setUrl(reader.result);
		};
		setIsUploadImageError(false);
		setFileName(event.currentTarget.files[0].name);
	};

	const handleSubmit = (values: ClientFormInputInterface): void => {
		if (!isEdit && file === null) {
			setIsUploadImageError(true);
			return;
		}
		if (isEdit && url === null) {
			setIsUploadImageError(true);
			return;
		}
		dispatch(setLoadingState(true));
		if (!isEdit) {
			const data = new FormData();
			data.append('name', String(values.name));
			data.append('email', String(values.email));
			data.append('password', String(values.password));
			data.append('country', String(selectedCountry.value));
			data.append('timezone', String(selectedTimezone.value));
			data.append('logo', file);
			void dispatch(handleAddClient({ data, bodyParams }));
			setOpenMode(false);
			return;
		}

		if (currentStatus === 'ACTIVE' && isDisable === true) {
			dispatch(setSnackbarState(true));
			dispatch(setSnackbarMessage('Streaming is currently ongoing. Please stop the stream first'));
			dispatch(setLoadingState(false));
			setOpenMode(false);
			return;
		}
		const data = new FormData();
		data.append('name', String(values.name));
		data.append('password', String(values.password));
		data.append('isDisable', String(isDisable));
		file !== null && data.append('logo', file);
		data.append('country', selectedCountry.value);
		data.append('timezone', selectedTimezone.value);
		void dispatch(
			handleUpdateClient({ id: preFillData.id, body: { data }, bodyParams })
		);
		setOpenMode(false);
	};

	const removeImage = (): void => {
		setfile(null);
		setUrl(null);
	};

	const beforUploadImg = (): void => {
		if (isEdit) {
			return;
		}
		if (isEmpty(file)) {
			setIsUploadImageError(true);
		} else {
			setIsUploadImageError(false);
		}
	};
	//  Toggle Button
	const [isDisable, setDisable] = useState<isDisableType>(preFillData.isDisable);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = (): void => {
		setShowPassword((show) => !show);
	};

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	): void => {
		event.preventDefault();
	};

	const handleCountryChange = (
		selectedOption: any,
		setFieldValue: any
	): void => {
		setSelectedCountry(selectedOption);
		setFieldValue('country', selectedOption?.value);
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (selectedOption) {
			const countryTimezones = getTimezonesForCountry(selectedOption.id);
			setTimezones(countryTimezones.map((timezone) => timezone.name));
			setSelectedTimezone(null); // Reset timezone selection when country changes
			setFieldValue('timezone', '');
		} else {
			setTimezones([]);
			setSelectedTimezone(null);
			setFieldValue('timezone', '');
		}
	};

	const handleTimezoneChange = (
		selectedOption: any,
		setFieldValue: any
	): void => {
		setSelectedTimezone(selectedOption);
		setFieldValue('timezone', selectedOption?.value);
	};

	return (
		<>
			<ClientModal>
				<ClientModalHeader>
					<Typography variant='h5'>
						{isEdit ? 'Edit client' : 'Add client'}
					</Typography>
					<IconButton
						aria-label='close'
						onClick={() => {
							setOpenMode(false);
						}}
						className='popup-close-btn'
					>
						<CloseIcon />
					</IconButton>
				</ClientModalHeader>
				<ClientModalForm>
					<Formik
						initialValues={initialValues}
						validationSchema={isEdit ? validationSchemaForEdit : validationSchema}
						onSubmit={handleSubmit}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							setFieldValue
						}) => {
							return (
								<Form>
									<div className='form-field'>
										<Typography variant='h6'>Name</Typography>
										<InputComponent
											id='name'
											name='name'
											value={values.name}
											placeholder='Name'
											type='text'
											variant='outlined'
											onBlur={handleBlur}
											onChange={handleChange}
											error={!!((touched.name ?? false) && errors.name != null)}
											helperText={(touched.name ?? false) && errors.name}
											required
										/>
									</div>
									<div className='form-field'>
										<Typography variant='h6'>Email</Typography>
										<InputComponent
											id='email'
											name='email'
											value={values.email}
											placeholder='Email'
											type='email'
											variant='outlined'
											onBlur={handleBlur}
											onChange={handleChange}
											error={!!((touched.email ?? false) && errors.email != null)}
											helperText={(touched.email ?? false) && errors.email}
											disabled={isEdit}
											required
										/>
									</div>
									<div className='form-field'>
										<Typography variant='h6'>Password</Typography>
										<InputComponent
											id='password'
											name='password'
											value={values.password}
											placeholder='Password'
											type={showPassword ? 'text' : 'password'}
											variant='outlined'
											onBlur={handleBlur}
											onChange={handleChange}
											error={!!((touched.password ?? false) && errors.password != null)}
											helperText={(touched.password ?? false) && errors.password}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end' className='eye-icon'>
														<IconButton
															aria-label='toggle password visibility'
															onClick={handleClickShowPassword}
															onMouseDown={handleMouseDownPassword}
															edge='end'
														>
															{showPassword ? <VisibilityOff /> : <Visibility />}
														</IconButton>
													</InputAdornment>
												)
											}}
											required
										/>
									</div>
									<div className='form-field'>
										<Typography variant='h6'>Country</Typography>
										<div className='country_select_wrapper'>
											<Select
												name='country'
												classNamePrefix='select_common_style'
												value={selectedCountry}
												onChange={(selectedOption: any) => {
													handleCountryChange(selectedOption, setFieldValue);
												}}
												options={Object.values(getAllCountries()).map((country) => ({
													id: country.id,
													value: country.name,
													label: country.name,
												}))}
												onBlur={handleBlur}
												placeholder='Select a country'
												className='country_select'
											/>
											{!!(Boolean(touched.country ?? false) && errors.country != null) && (
												<ErrorMsgComponent message={String(errors.country)} />
											)}
										</div>
									</div>
									{selectedCountry !== null && (
										<div className='form-field'>
											<Typography variant='h6'>Timezone</Typography>
											<div className='country_select_wrapper'>
												<Select
													classNamePrefix='select_common_style'
													name='timezone'
													value={selectedTimezone}
													onChange={(selectedOption: any) => {
														handleTimezoneChange(selectedOption, setFieldValue);
													}}
													options={timezones.map((timezone: any) => ({
														value: `${timezone} (GMT${getTimezone(timezone).dstOffsetStr})`,
														label: `${timezone} (GMT${getTimezone(timezone).dstOffsetStr})`
													}))}
													onBlur={handleBlur}
													placeholder='Select a timezone'
													className='timezone_select'
												/>
												{!!(
													Boolean(touched.timezone ?? false) && errors.timezone != null
												) && <ErrorMsgComponent message={String(errors.timezone)} />}
											</div>
										</div>
									)}
									<div className='form-field upload-field'>
										<Typography variant='h6'>Logo</Typography>
										{Boolean(url !== null) && (
											<>
												<div className='image-wrapper'>
													<div className='logo-image-item'>
														<IconButton aria-label='close' onClick={removeImage}>
															<CloseIcon />
														</IconButton>
														<div className="image">
															<img src={url} alt='uploadedImage' height={200} width={200} />
														</div>
														<p>{fileName}</p>
													</div>
												</div>
											</>
										)}
										<InputLabel htmlFor='uploadLogo' className='upload-file-icon'>
											<img src={uploadLogoIcon} alt='' />
											<p>
												Drop your logo here, or <u>browse</u>
											</p>
										</InputLabel>
										<InputComponent
											name='logo'
											placeholder='Drop your logo here, or'
											className='input-s'
											type='file'
											variant='outlined'
											onChange={onImageChange}
											key={file !== null ? file.name : 'file'}
											required
											id='uploadLogo'
										/>
									</div>
									{isUploadImageError && (
										<ErrorMsgComponent message='Logo is required' />
									)}
									{isEdit && (
										<>
											<div className='form-field'>
												<Typography variant='h6'>Disable</Typography>
												<ToggleButton
													checked={isDisable}
													onChange={(e) => {
														setDisable(e.target.checked);
													}}
													value={isDisable}
												/>
											</div>
										</>
									)}
									<Box sx={{ textAlign: 'right' }} className='modal-buttons'>
										<ButtonComponent
											variant='outlined'
											btnName='Cancel'
											onClick={() => {
												setOpenMode(false);
											}}
										/>
										<ButtonComponent
											type='submit'
											variant='contained'
											btnName={isEdit ? 'Update' : 'Add'}
											loading={isLoading}
											onClick={beforUploadImg}
										/>
									</Box>
								</Form>
							);
						}}
					</Formik>
				</ClientModalForm>
			</ClientModal>
		</>
	);
};

export default ClientForm;
