import React, { useEffect, useState, type FC } from 'react';
import ButtonComponent from '../../components/ButtonComponent';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
	Typography,
	IconButton,
	Box,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Divider,
	ToggleButton,
	ToggleButtonGroup,
	CircularProgress
} from '@mui/material';
import { type AddPlayListProps } from '../../modals';
import CloseIcon from '@mui/icons-material/Close';
import { PlaylistModal, PlaylistModalHeader, PlaylistModalBody } from './style';
import { handleGetSongFromFolderPlayList } from '../../redux/actions/songsAction';
import { handleAddPlaylistSongs } from '../../redux/actions/playlistSongAction';
import ErrorMsgComponent from '../../components/ErrorMsgComponent';
import {
	setLoadingState,
	setLoadingStateV2
} from '../../redux/slice/loadingSlice';
import { totalTime } from '../../utils/getTotalTime';

const AddPlayListSongs: FC<AddPlayListProps> = ({
	setOpenMode,
	handleClear
}) => {
	const dispatch = useAppDispatch();
	const { songs } = useAppSelector((state) => state.songs);
	const { selectedPlaylist } = useAppSelector((state) => state.playlist);
	const { isLoading, isLoadingV2 } = useAppSelector((state) => state.loading);

	const [isError, setIsError] = useState<boolean>(false);
	const [alignment, setAlignment] = useState('recent');
	const [isSelectedAll, setIsSelectedAll] = useState(false);

	useEffect(() => {
		dispatch(setLoadingStateV2(true));
		if (alignment === 'recent') {
			void dispatch(
				handleGetSongFromFolderPlayList({
					folderId: selectedPlaylist.playlistId,
					recent: true
				})
			);
		} else {
			void dispatch(
				handleGetSongFromFolderPlayList({ folderId: selectedPlaylist.playlistId })
			);
		}
	}, []);

	const [selectedItem, setSelectedItem] = useState<any>([]);
	const [selectedSongsId, setSelectedSongsId] = useState<any>([]);
	const [totalDuration, setTotalDuration] = useState<any>([]);

	useEffect(() => {
		const total: any = [];
		selectedItem.map((val: any) => total.push(val.duration));
		setTotalDuration(total);
	}, [selectedItem.length]);

	const handleSetSelectedItem = (e: boolean, item: any): void => {
		const items = [...selectedItem];
		const songIds = [...selectedSongsId];
		if (e) {
			setSelectedItem([...items, item]);
			setSelectedSongsId([...songIds, item.id]);
		} else {
			const index: number = items.findIndex((x) => x.id === item.id);
			if (index > -1) {
				items.splice(index, 1);
				songIds.splice(index, 1);
			}

			setSelectedItem([...items]);
			setSelectedSongsId([...songIds]);
		}
	};

	const handleSelectAllSongs = (value: boolean): void => {
		if (value) {
			const list: Array<number | undefined> = [];
			const itemList: Array<number | undefined> = [];
			// eslint-disable-next-line array-callback-return
			songs.map((item: any) => {
				// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
				if (!selectedSongsId.includes(item.id)) {
					list.push(item.id);
					itemList.push(item);
				}
			});
			if (selectedSongsId.length === 0) {
				setSelectedSongsId(list);
				setSelectedItem(itemList);
				return;
			}
			setSelectedSongsId([...selectedSongsId, ...list]);
			setSelectedItem([...selectedItem, ...itemList]);
			return;
		}
		setSelectedSongsId([]);
		setSelectedItem([]);
	};

	useEffect(() => {
		handleSelectAllSongs(isSelectedAll);
	}, [isSelectedAll]);

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string
	): void => {
		setIsSelectedAll(false);
		if (newAlignment === null) {
			return;
		}
		setAlignment(newAlignment);
		dispatch(setLoadingStateV2(true));
		if (newAlignment === 'recent') {
			void dispatch(
				handleGetSongFromFolderPlayList({
					folderId: selectedPlaylist.playlistId,
					recent: true
				})
			);
			return;
		}
		void dispatch(
			handleGetSongFromFolderPlayList({ folderId: selectedPlaylist.playlistId })
		);
	};

	const addSongs = (): void => {
		handleClear();
		if (selectedSongsId.length > 0) {
			dispatch(setLoadingState(true));
			void dispatch(
				handleAddPlaylistSongs({
					playlistId: selectedPlaylist.playlistId,
					songIds: selectedSongsId
				})
			);
			setOpenMode(false);
		} else {
			setIsError(true);
		}
	};

	const handleSongs = (): any => {
		if (isLoadingV2) {
			return (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<CircularProgress sx={{ color: 'var(--primary)' }} size={50} />
				</Box>
			);
		}
		if (songs.length > 0) {
			return songs.map((item) => {
				return (
					<FormGroup key={item.id}>
						<FormControlLabel
							key={item.id}
							className='theme-checkbox'
							control={
								<Checkbox
									checked={selectedSongsId.includes(item.id)}
									onChange={(e) => {
										handleSetSelectedItem(e.target.checked, item);
									}}
								/>
							}
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							label={`${item?.artist} - ${item?.title}`}
						/>
						<Divider />
					</FormGroup>
				);
			});
		}
		return (
			<Typography className='text-center-null-val'>Data not found</Typography>
		);
	};

	return (
		<>
			<PlaylistModal>
				<PlaylistModalHeader>
					<Typography variant='h5'>Add Playlist</Typography>
					<IconButton
						aria-label='close'
						className='popup-close-btn'
						onClick={() => {
							setOpenMode(false);
						}}
					>
						<CloseIcon />
					</IconButton>
				</PlaylistModalHeader>
				<div className='top-modal-wrapper'>
					<Box>
						<Checkbox
							checked={isSelectedAll}
							onChange={() => {
								setIsSelectedAll(!isSelectedAll);
							}}
						/>
						<Typography>Select All</Typography>
					</Box>
					<ToggleButtonGroup
						color='primary'
						value={alignment}
						exclusive
						onChange={handleChange}
						aria-label='Platform'
					>
						<ToggleButton className='songbtn' value='all'>
							All
						</ToggleButton>
						<ToggleButton className='songbtn' value='recent'>
							Recently Added
						</ToggleButton>
					</ToggleButtonGroup>
				</div>

				{/* <TextField
                    type='text'
                    placeholder='Search'
                    variant='outlined'
                    size= 'small'
                    className='search-field'
                    onChange={(e) => { void dispatch(handleGetSongFromFolderPlayList({folderId: selectedPlaylist.parentFolderId, search: e.target.value})); }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><img src={SearchIcon} alt="" /></InputAdornment>,
                    }}
                />  */}
				<PlaylistModalBody>
					{handleSongs()}
					{isError && <ErrorMsgComponent message='Song not selected' />}
				</PlaylistModalBody>
				<Box className='modal-buttons'>
					<Typography sx={{ fontSize: '14px' }}>
						Count: {selectedItem?.length}, Duration:{totalTime(totalDuration)}
					</Typography>
					<ButtonComponent
						variant='outlined'
						btnName='Cancel'
						onClick={() => {
							setOpenMode(false);
						}}
					/>
					<ButtonComponent
						type='button'
						variant='contained'
						btnName='Add'
						loading={isLoading}
						onClick={() => {
							addSongs();
						}}
						disabled={songs.length <= 0}
					/>
				</Box>
			</PlaylistModal>
		</>
	);
};

export default AddPlayListSongs;
