/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, type FC, useEffect } from 'react';
import { type CustomTimePickerProps } from '../../modals';
// import * as dayjs from 'dayjs';

const CustomTimePicker: FC<CustomTimePickerProps> = ({
    value, 
    setEndTimeCustomPicker,
    setStartTimeCustomPicker,
    setTimeValue, date
}) => {
    const [selectedHour, setSelectedHour] = useState<string | null>(null);
    const [selectedMinute, setSelectedMinute] = useState<string | null>(null);

    const HoursList = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    const MinutesList = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55','59'];


    useEffect(() => {
        if (value != null) {
            const splitStr: any = value?.split(':');
            setSelectedHour(splitStr[0]);
            setSelectedMinute(splitStr[1]);
        } else {
            setSelectedHour('00');
            setSelectedMinute('00');
        }
        
        
    }, []);

    const handleSubmit = () => {
        const timeString = `${selectedHour ?? '00' }:${selectedMinute ?? '00' }`;
        // const dateString = dayjs.default(date).format('YYYY-MM-DD');
        setTimeValue(timeString);
        setEndTimeCustomPicker(false);
        setStartTimeCustomPicker(false);
    };

    useEffect(() => {
        const timeString = `${selectedHour ?? '00' }:${selectedMinute ?? '00' }`;
        // const dateString = dayjs.default(date).format('YYYY-MM-DD');
        setTimeValue(timeString);
    }, [selectedHour, selectedMinute]);

    return (
        <>
            <div className="custom-timepicker">
                <div className='inner-wrapper'>
                    <div>
                        <h4>{selectedHour} { selectedHour === '01' ? 'Hr' : 'Hrs'}</h4>
                        <ul>
                            {
                            HoursList.map((item, index) => (
                                <li key={index} className={selectedHour === item ? 'active' : ''} onClick={() => {
                                    setSelectedHour(item);
                                }}> 
                                    {item} 
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                    <div>
                        <h4>{selectedMinute} Min</h4>
                        <ul>
                            {
                            MinutesList.map((item, index) => (
                                <li key={index} className={selectedMinute === item ? 'active' : ''} onClick={() => {
                                    setSelectedMinute(item);
                                    }}> 
                                    {item} 
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="button-wrapper">
                    {/* <span className='cancel' onClick={() => { setEndTimeCustomPicker(false); setStartTimeCustomPicker(false); }}>Cancel</span> */}
                    <span onClick={handleSubmit}>OK</span>
                </div>
            </div>
            <div className='background-timepicker' onClick={() => { setEndTimeCustomPicker(false); setStartTimeCustomPicker(false); }}></div>
        </>
    );
};

export default CustomTimePicker;