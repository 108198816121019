import styled from 'styled-components';
import '../../style/main.scss';
import CrossIcon from '../../assets/images/icons/cross-icon.svg';

export const ImportBoxWrapper = styled.div`
    width: 100%;
    max-width:960px;
    margin:20px auto;

    .modal-buttons{
        margin-top: 30px;
        width:100%;
        display:flex;
        justify-content:flex-end;

        .linear-progess-bar {
            width:inherit
        }
        
        button{
            margin-left:20px;
            text-transform: capitalize;
            width:105px;
            padding: 9px;
            max-height: 42.5px;
        }
    }
`;

export const ImportBoxHeader = styled.div`
    margin:20px 0px 10px;

    h4{
        font-weight:700;
        font-size: 30px;
        font-family: "title-fonts";
    }
`;

export const ImportBoxBody = styled.div`
    margin-top:10px;
    margin-bottom:20px;
    padding: 20px;
    border-radius:10px;
    position: relative;
    background-color:var(--darkerBgColor);

    &.open {
        border:dashed 1px var(--primary);
    }

    + p {
        margin-left: 0px !important;
        margin-top: -15px;
    }

    .icon-wrapper {
        width: max-content;
        margin: auto;
        display: flex;
    }
    .icon-wrapper .icon button {
        background-color: var(--swichSuccess);
        width: 80px;
        height: 80px;
        margin-bottom: 15px;

        img {
            width: 40px;
            height: 40px;
        }
    }
    .icon-wrapper .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 25px;
    }
     h5 {
        margin: 0px auto 30px;
        text-align:center;
        font-size: 25px;
        font-family: "regular-fonts";
        width: 100%;
        max-width: 320px;
    }
     h5 label {
        position: relative;
        cursor: pointer;
    }
    h5 label:after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--primary);
    }
    input{
        font-size:0px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        height: 100%;
    }
`;

export const TagsWrapper = styled.div`
    margin-top: 0;
    margin-bottom: 20px;

    .MuiInputBase-root.MuiOutlinedInput-root {
        padding: 2.5px 15px;
    }

    h6 {
        font-size: 18px;
        font-family: "medium-fonts";
        line-height: normal;
        margin: 0 0 10px 0;
    }

    + p {
        margin-left: 0px !important;
        margin-top: -25px;
    }

    .MuiInputBase-fullWidth{
        background-color: var(--darkerBgColor);
    }
    .MuiAutocomplete-popupIndicator, MuiAutocomplete-endAdornment{
        display: none;
    }
    .MuiChip-deletable{
        background-color:var(--bgColor);
        border-radius:10px;
    }
    .MuiAutocomplete-tag{
        margin-right:10px !important;

        span {
            font-size: 14px;
        }
    }
    .MuiChip-deleteIcon{
        position:relative;
        display:inline-block;
        background-image:url(${CrossIcon});
        background-position:center;
        background-size: 11px;
        position: relative;
        background-repeat: no-repeat;

        &:hover{
            background-size: 13px;
        }

        path{
            display:none !important;
            opacity:0;
        }
    }
`;


export const TableWrapper = styled.div` 
    margin-top: -10px;
    margin-bottom: 20px;

    h6 {
        font-size: 18px;
        font-family: "title-fonts" !important;
    }

table {
    border: solid 1px var(--primary);
    border-radius:10px;
    overflow: hidden;
  }
  table tr td, table tr th{
    padding:8px 15px;
  }
  table tr td ul{
    padding-left: 15px;
  }
  table tr td:last-child, table tr th:last-child{
    min-width: 120px;
    border-left:solid 1px var(--primary);
  }
  table tr th:last-child{
    border-left:none;
  }
  table tr th{
    background-color:var(--darkerBgColor);
    border-bottom:none;
  }
  table tr th:first-child ,table tr td:first-child {
    width: 100%;
  }
  table tr td{
    padding: 10px;
    border-bottom: solid 1px var(--primary) !important;
  }
  table tr td p {
    font-size: 15px;
  }
  table tr:last-child td{
    border-bottom:none !important;
  }
`;