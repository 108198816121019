import React, { type FC } from 'react';
import { Box, Typography } from '@mui/material';
import musicIcon from '../../assets/images/icons/music-item.svg';

interface PlayListTitleProps {
	playlist: any;
	onClick: () => void;
	onContextMenu: (e: any) => void;
	active: boolean;
}

const PlayListTitle: FC<PlayListTitleProps> = ({
	playlist,
	onClick,
	onContextMenu,
	active
}) => {
	return (
		<Box onClick={onClick} onContextMenu={onContextMenu}>
			<Typography
				style={{
					// backgroundColor: active === playlist.id ? 'black' : 'white',
					paddingTop: 10,
					paddingBottom: 10,
					paddingLeft: 16,
					paddingRight: 16,
					cursor: 'pointer'
				}}
				key={playlist.id}
				className={`playlist-title ${active === playlist.id ? 'selected' : ''}`}
			>
				<img
					src={musicIcon}
					alt=''
				/>
				{playlist.name}
			</Typography>
		</Box>
	);
};

export default PlayListTitle;
