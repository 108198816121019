import { createAsyncThunk } from '@reduxjs/toolkit';
import { playlistService } from '../../services/playlistService';
import type { AppDispatch } from '../store';
import { setLoadingState } from '../slice/loadingSlice';
import { handleLogout } from '../../utils/handleLogout';
import { setSnackbarState, setSnackbarMessage } from '../slice/snackbarSlice';
import { setPlaylistFolder, setPlaylistChildFolder, setPlayList } from '../slice/playlistSlice';
import { type RenameParamsType, type CreateParamsType, type dragPlayListType, type dragFolderType, type copyPlaylistTypeCall, type CreateParamsTypeV2, type CreateParamsTypeV3, type getPlaylistProps } from '../../modals';
import { handleAddSongWithProgressBarV2 } from './songsAction';

export const handleGetPlaylistFolder = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/getFolder',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.getPlaylistFolderList();
                dispatch(setPlaylistFolder(response.data.data.results));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );
export const handleGetPlaylistFolderV2 = createAsyncThunk<
    object,
    any,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/getFolder',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.getPlaylistFolderListV2();
                dispatch(setPlaylistFolder(response.data.data.results));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleGetPlaylist = createAsyncThunk<
    object,
    getPlaylistProps,
    {
        dispatch: AppDispatch
    }
>
    (
        'paylist/getPlaylist',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.getPlayList({ field: 'name', sort: 'ASC'});
                dispatch(setPlayList(response.data.data.results));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleRenamePlaylistFolder = createAsyncThunk<
    object,
    RenameParamsType,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/folderRename',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.renamePlaylistFolder(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));

            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleCreatePlaylistFolder = createAsyncThunk<
    object,
    CreateParamsType,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/folderCreate',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.createPlaylistFolder(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleCreatePlaylistFolderV2 = createAsyncThunk<
    object,
    CreateParamsType,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/folderCreate',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.createPlaylistFolder(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolderV2({}));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleCreatePlaylistFolderV3 = createAsyncThunk<
    object,
    CreateParamsTypeV3,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/folderCreate',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.createPlaylistFolder(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                const data = { ...params?.params?.body, parentFolderId: response.data?.data?.id };
                const newParams = { ...params?.params, body: data };
                void dispatch(handleAddSongWithProgressBarV2(newParams));
                // void dispatch(handleAddSongWithProgressBar(params.params));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );


export const handleDeletePlaylistFolder = createAsyncThunk<
    object,
    number,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/deleteFolder',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.deletePlaylistFolder(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleGetPlaylistChildFolder = createAsyncThunk<
    object,
    number,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/getChildFolder',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.getPlaylistChildFolderList(params);
                dispatch(setPlaylistChildFolder(response.data.data.childFolder));
            } catch (error: any) {
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleCreatePlaylist = createAsyncThunk<
    object,
    CreateParamsType,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/playlistCreate',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.createPlaylist(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleCreatePlaylistV2 = createAsyncThunk<
    object,
    CreateParamsTypeV2,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/playlistCreate',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.createPlaylistV2(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolderV2({}));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleDeletePlaylist = createAsyncThunk<
    object,
    number,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/deletePlaylist',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.deletePlaylist(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleRenamePlaylist = createAsyncThunk<
    object,
    RenameParamsType,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/playlistRename',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.renamePlaylist(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));

            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleDragPlayList = createAsyncThunk<
    object,
    dragPlayListType,
    {
        dispatch: AppDispatch
    }
>
    (
        'playlist/parent-folder',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.dragPlayList(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));

            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const handleDragFolder = createAsyncThunk<
    object,
    dragFolderType,
    {
        dispatch: AppDispatch
    }
>
    (
        'folder/parent-folder',
        async (params, { dispatch }) => {
            try {
                const response: any = await playlistService.dragFolder(params);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));

            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );

export const copyPlaylist = createAsyncThunk<
    object,
    copyPlaylistTypeCall,
    {
        dispatch: AppDispatch
    }
>
    (
        'folder/parent-folder',
        async ({ data, handleClose }, { dispatch }) => {
            try {
                const response: any = await playlistService.duplicatePlaylist(data);
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(response.data.message));
                dispatch(setLoadingState(false));
                void dispatch(handleGetPlaylistFolder({}));
                handleClose({ success: true });
            } catch (error: any) {
                dispatch(setLoadingState(false));
                dispatch(setSnackbarState(true));
                dispatch(setSnackbarMessage(error.response.data.message));
                handleClose();
                if (error.response.status === 401) {
                    handleLogout();
                }
            }
        }
    );