import styled from 'styled-components';
import '../../style/main.scss';
import checkIcon from '../../assets/images/icons/checkbox-checked.svg';
import checkRing from '../../assets/images/icons/checkbox-none.svg';

export const PlaylistWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0px;

  .music-folder-list{
    padding-top:10px;
    height: calc(100vh - 83px);
    flex: row;
    overflow : auto;
    background-color: var(--darkerBgColor);
    ul {
      width: 100%;
      li {
        padding: 0 !important;
        margin-bottom: 5px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        >div {
          width: 100%;
        }
        &:hover {
          background-color: transparent !important;
          color: inherit !important;
        }
        .folder-name {
          display: flex;
          align-items: center;
          font-size: 14px;
          text-transform: capitalize;
          padding: 5px 30px;
          justify-content: space-between;
          &.selected {
            svg {
              transform: rotate(90deg);
            }
          }
          img {
            margin-right: 10px;
            width: 18px;
          }
          svg {
            width: 16px;
            height: 16px;
            transition: 0.3s all;
          }
        }
        .music-folder {
          display: flex;
          align-items: center;
          font-size: 14px;
          text-transform: capitalize;
          padding: 5px 30px;
          position: relative;
          z-index: 1;
          &.selected {
            &:before {
                content: "";
                position: absolute;
                left: -100%;
                right: 0;
                top: 0;
                bottom: 0;
                background: #FFC5B2;
                z-index: -1;
            }
          }
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .playlist-table{
    padding: 15px 20px;
    height: calc(100vh - 83px);
    overflow: auto;
    .delete-button img{
      filter: brightness(0) invert(1);
    }
    .playlist-buttons-time {
      display: flex;
      align-items: center;
      margin: 0 0 0 0;
      .playlist-buttons {
        button {
          padding: 5px 15px;
          margin-right: 10px;
          margin-left: 0;
          font-size: 14px;
          border-radius: 10px;
          color: #fff;
          background: #000;
          height: 40px;
          span.MuiButton-startIcon {
            margin-right: 4px;
          }
          svg {
            fill: #fff;
            width: 19px;
            height: 19px;
          }
        }
      }
      .playlist-time {
        .time-duration {
          font-size: 14px;
          display: inline-block;
          padding: 11px 15px;
          border-radius: 10px;
          color: #fff;
          box-shadow: none;
          background: var(--swichSuccess);
          margin-left: 0;
        }
      }
    }
  }

  .playlist-inner{
    width:100%;

    .focused{
      .delete-btn {
        background-color:var(--primary);

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
`;

export const MusicFolderList = styled.div`
  width:100%;
  background-color: var(--darkerBgColor);
  .MuiPopover-paper {
    border-radius: 10px !important;
  }
  .MuiAccordionDetails-root{
    background-color: var(--darkerBgColor);
  }
  .MuiAccordionSummary-content{
    margin:0px;
  }
  .MuiAccordionSummary-content.Mui-expanded{
    margin:0px;
  }
  .MuiAccordion-rounded.Mui-expanded{
    margin:0px;
  }
  .MuiAccordionSummary-expandIconWrapper{
    transform:rotate(90deg);
  }
  .Mui-expanded > .MuiAccordionSummary-expandIconWrapper{
    transform:rotate(0deg);
  }
  .css-brfcbh-MuiButtonBase-root-MuiAccordionSummary-root{
    min-height:35px;
    margin-top:0px;
  }
  .css-brfcbh-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    min-height:35px;
  }
  .MuiAccordionSummary-root{
    min-height:35px;

    img {
      margin-right: 10px;
      transform:translate(0,3px);
    }
  }
  .MuiAccordionSummary-root.Mui-expanded{
    min-height:35px;
  }
  .MuiPaper-root:before{
    display: none;
  }
 .MuiAccordionDetails-root{
    background-color: var(--darkerBgColor);
    box-shadow:none;
    padding: 0px 0px 0px 5px;    
    margin: 0;

    > .MuiBox-root{
      padding: 3px 23px;
      cursor: pointer;

      p.open {
        position: relative;
        z-index: 1;
        pointer-events: none;
        
        &:after{
          content: '';
          display: inline-block;
          width: 355px;
          height: 130%;
          background-color: var(--bgColor);
          position: absolute;
          left: -38px;
          top: -15%;
          z-index: -1;
        }
      }
      .inner:after{
        left: -58px !important;
      }
    }
  }
  .song-list-item{
    position:relative;
    padding:8px 10px 6px 60px;
    width:350px;
    margin-left:-25px;
    cursor:pointer;
    z-index:1;
    &:hover{
      background-color:var(--bgColor);
    }
    &:focus{
      background-color:var(--bgColor);
    }
  }
  .MuiSvgIcon-fontSizeMedium{
    color:var(--primary);
  }
  .MuiAccordion-root{
    background-color: var(--darkerBgColor);
    box-shadow:none;
    padding: 0px 0px 0px 5px;    
    margin: 0;
      img{
        margin-right:10px;
        transform:translate(0,2px);
      }
    }
  }
`;

export const PlaylistSearchHeader = styled.div`
  display:flex;
  margin-bottom: 20px;

  .playlist-time {
    .time-duration {
      padding: 11px 15px;
      border-radius: 10px;
      color: #fff;
      box-shadow: none;
      background: var(--swichSuccess);
      height: 40px;
      font-size: 14px;
      margin-left: 10px;
      min-width: max-content;
      display: flex;
      align-items: center;
    }
  }

  .MuiFormControl-root{
    width:100%;
  } 
  .search-field{
    background-color: var(--primary);
    border-radius:10px;
  }
  .main-playlist-time {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0 auto;
    button {
      margin-left: 10px;
    }
  }
  input.MuiOutlinedInput-input{
    color:var(--white) !important;
  }
  button{
    margin-left:15px;
    padding:5px 15px;
    min-width:100px;
    text-transform: capitalize;
  }
  // button.in-active {
  //   pointer-events: none;
  //   opacity: .8;
  // }
`;


export const PlaylistTable = styled.div`
  .MuiTableContainer-root{
    height: calc(100vh - 200px);
  }
  table {
    border: solid 2px var(--primary);
    border-radius:10px;
    overflow: hidden;
  }
  table tr:last-child td{
    border-bottom:none !important;
  }
  table tr td, table tr th{
    padding:8px 15px;
    font-size: 16px;
  }
  table tr th.index-col ,
  table tr td.index-col {
    width: 50px;
    text-align:center;
    padding: 0px;
  }
  table tr td.index-col p {
    text-align: center;
    display: block;
  }
  table tr td p{
    font-size: 14px;
    display:flex;
    align-items:center;
  }
  table tr td .delete-btn{
    background-color: var(--darkerBgColor);
    padding: 8px;
    border-radius: 10px;
    width: 30px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    transition-duration: .2s;
  }
  table tr td .delete-btn:hover,{
    background-color: var(--primary);
  }
  table tr td .delete-btn:hover img,{
    filter: grayscale(1) invert(1);
  }
  table tr th{
    background-color: var(--darkerBgColor);
    border-bottom:none;
    padding:8px 1px 1px 15px;
  }
  table tr td .MuiChip-filled{
    background-color : var(--darkerBgColor);
    border-radius: 10px;
    height: 25px;
  }
  table tr td .MuiRating-sizeMedium span{
    font-size:15px;
  }
  table tr th:first-child ,table tr td:first-child {
    width: 50px;
    text-align:center;
    padding: 0px;
  }
  table tr th.check-row,
  table tr td.check-row {
    width: 45px;
    padding : 0px;
  }
  table tr td:first-child p{
    align-items:center;
    justify-content:center;
  }
  table tr td:not(:first-child){
    border-left:solid 1px var(--primary);
  }
  table tr[style*="position"]{
    background-color:var(--bgColor);
    border:solid 1px var(--primary);
    box-shadow: 0px 0px 2px #c0c0c0;
    display: table;

    td , th {
      border-bottom: none !important;
    }

  }
  table tr td + th {
    height: 100% !important;
    display: table-cell;
    text-align: center;
    border-left:solid 1px var(--primary) !important;
    border-bottom:solid 1px var(--primary);
  }
  table tr:not(:last-child) td{
    border-bottom:solid 1px var(--primary);
  }
  table tr td.action-col{
    width:55px;
  }
  table tr td.rating-col, table tr td.duration-col{
    width:0;
  }
  table tr td.date-col{
    min-width:115px;
    width:115px;
  }
  table tr th.tag-col,
  table tr td.tag-col {
    width: min-content;
    padding: 8px 15px;
    max-width: 120px;
  }
  table tr td.tag-col > div{
    display: block;
    text-align: left !important;
  }
  table tr td.tag-col > div > .MuiChip-filled {
    margin-left: 0px;
  }
  table tr td.tag-col > div > .MuiChip-filled:not(:last-child){
    margin-bottom: 5px;
  }
  table tr td.tag-col > div > .MuiChip-filled {
    margin-right: 5px;
  }
  table tr td .play-pause{
    padding: 8px 10px 6px; 
    background-color:var(--darkerBgColor);
    box-shadow:none;
  }
  table tr td .play-pause img {
    width: 15px;
    height: 15px;
  }
`;


export const PlaylistModal = styled.div`
  width: 100%;
  height : auto;
  padding:0px;
  height: calc(100vh - 400px);
  min-height: 400px;
  overflow: hidden;
  position: relative;
  background-color: var(--bgColor);

  .MuiDialogContent-root{
    padding:0px;
  }
  .MuiSwitch-sizeMedium{
    transform: scale(1.3) !important;
    transform-origin: top left !important;
  }

  .search-field{
    background-color: var(--primary);
    border-radius: 10px;
    width: calc(100% - 40px);
    position: sticky;
    margin: 10px 20px 0px;

    input {
      color: var(--white);
    }
  }

  .modal-buttons{
    width:100%;
    margin-top: 0px;
    padding: 20px;
    display: flex;
    justify-content:flex-end;
    border-top: 1px solid rgb(0 0 0 / 9%);
    position: absolute;
    bottom:0;
    z-index: 11;
    background-color: var(--bgColor);
    align-items: center;

    button{
      width:105px;
      margin-left: 20px;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  .top-modal-wrapper {
    display: flex;
    width: 100%;
    padding: 12px 20px 12px 20px;
    border-bottom: solid 1px #00000029;
    align-items: flex-center;
    justify-content: space-between;

    .MuiBox-root {
      display :flex;
      align-items: center;
      margin-left:-8px;
      transform:translateY(5px);
      span.MuiCheckbox-root {
        padding-top: 0;
        padding-bottom: 0;
      }

      p {
        margin-left: 0;
        font-size: 14px;
      }

      input + svg {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: contain;

        &[data-testid='CheckBoxOutlineBlankIcon']{
          background-image: url(${checkRing});          
        }
        &[data-testid='CheckBoxIcon']{
          background-image: url(${checkIcon});
        }

        path {
          display : none;
        }
      }
    }
  }
  .songbtn[aria-pressed="true"] {
    background: #000;
    color: #fff;
  }
  .songbtn[aria-pressed="true"]:hover {
    background: #000;
  }
  .songbtn {
    padding: 5px;
    padding: 4px 20px;
    font-size: 13px;
    margin-top: 0px;
    text-transform: none;
  }
  .songbtn:first-child{
    margin-left: 20px;
  }
`;

export const PlaylistFolderWrapper = styled.div` 
    flex-direction: column;
    display: flex;
    width: 100%;
    .playlist-input {
      margin: 0 16px 16px;
      .MuiFormControl-root {
        width: 100%;
        input {
          background: var(--bgColor);
          padding: 9px 16px;
          border-radius: 10px;
          font-size: 14px;
        }
      }
      + ul {
        padding-bottom: 100px;
      }
    }
`;

export const PlaylistModalHeader = styled.div`
  padding:10px 20px;
  background-color : var(--darkerBgColor);
  display:flex;
  justify-content:space-between;
  align-items:center;

  h5{
    margin-bottom: 0px;
    font-size:18px;
    font-family: "medium-fonts" !important;
  }
  .popup-close-btn{
    position: absolute;
    right: 8px;
    top: 3px;
    color: var(--primary);
    
  }
`;

export const PlaylistModalBody = styled.div`
  background-color : var(--bgColor);
  padding: 0px 20px 20px;
  height : calc(100% - 180px);
  overflow:auto;
  position: relative;
  
  input.MuiOutlinedInput-input{
    color:var(--white) !important;
    font-size:14px;
  }
  p {
    margin-left: 0px;
    margin-top: 5px;
  }
  .MuiDivider-fullWidth{
    border-color:var(--primary);
  }
`;

export const ConfirmationModal = styled.div`
  height : auto;
  padding:0px;
  width: 500px;

  .popup-close-btn {
    svg path {
      fill: var(--primary) !important;
    }
  }
`;

export const ConfirmationModalHeader = styled.div`
  padding: 2px 10px 2px 20px;
  background-color : var(--darkerBgColor);
  display:flex;
  justify-content:space-between;
  align-items:center;

  h5{
    margin-bottom: 0px;
    font-size:18px !important;
    font-family: "medium-fonts" !important;
  }
  
  p{
    font-family: "title-fonts" !important;
    font-size: 18px !important;
  }

`;

export const ConfirmationModalBody = styled.div`
  background-color : var(--bgColor);
  padding: 20px;

  .modal-buttons{
    width:100%;
    margin-top: 30px;
    display: flex;
    justify-content:flex-end;
  }
  .MuiTextField-root{
    width:100%;
    margin-bottom:30px;
    border-radius:10px;
    overflow:hidden;
    input{ 
      background-color:var(--darkerBgColor);
      padding: 10px 20px;
      border-radius: 10px;
    }
  }
  button{
    width:105px;
    margin-left: 20px;
    text-transform: capitalize !important;
    font-size: 16px;
  }
`;

export const PlaylistBottom = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(--darkerBgColor);
  width: calc(100% - 350px);
  text-align: right;
  padding: 10px 30px;
  
  p{
    font-size: 14px;
  }
`;
