import styled from 'styled-components';
import '../../style/main.scss';
import CrossIcon from '../../assets/images/icons/cross-icon.svg';

export const SongWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction:column;
  padding: 0px;
  padding: 20px;
  .playlist-buttons-time {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    width: 671px;
    + .input-with-icon {
      width: calc(100% - 896px);
      justify-content: flex-end;
      margin: 0 0 0 auto;
    }
    .playlist-buttons {
      button {
        padding: 7px 15px;
        height: 40px;
        margin-right: 20px;
        font-size: 14px;
        border-radius: 10px;
        color: #fff;
        background: #000;
        margin-left: 0;
        span.MuiButton-startIcon {
          margin-right: 4px;
        }
        svg {
          fill: #fff;
          width: 19px;
          height: 19px;
        }
      }
    }
    .playlist-time {
      .time-duration {
        font-size: 14px;
        display: inline-block;
        padding: 11px 15px;
        border-radius: 10px;
        color: #fff;
        box-shadow: none;
        background: var(--swichSuccess);
        height: 40px;
        margin-left: 0;
      }
    }
  }
`;

export const SongListCalender = styled.div`
  width: 100%;
`;

export const ToggleButtons = styled.div`
  padding-right: 20px;
  width: 220px;
  .songbtn {
    min-width: unset;
  }
`;

export const PlayButton = styled.button`
  border: none;
  color: #fff;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 12px;
  background-color: rgb(28, 28, 30);
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  cursor: pointer;
`;

export const SongHeader = styled.div`
  display:flex;
  margin-bottom: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  
  .MuiFormControl-root{
    width:100%;
  } 
  .search-field{
    background-color: var(--primary);
    border-radius:10px;
  }
  input.MuiOutlinedInput-input{
    color:var(--white) !important;
  }
  button{
    margin-left: 5px;
    svg {
      fill: #000;
      width: 20px;
      height: 20px;
    }
  }
  .songbtn[aria-pressed="true"] {
    background: #000;
    color: #fff;
  }
  .songbtn[aria-pressed="true"]:hover {
    background: #000;
  }
  .songbtn {
    padding: 6.75px 20px;
    font-size: 14px;
    text-transform: capitalize;
    height: 40px;
  }
  .songbtn:first-child{
    margin-left: 0;
  }
  .delete-button {
    margin-left:0px;
    padding: 8px 20px;
    min-width: 120px;
    margin-right: 15px;
    margin-bottom: 0px;
  }
  .delete-button img {
    filter: grayscale(1) invert(1);
  }
  [role="group"] {
    width: 100%;
  }
  .input-with-icon {
    display: flex;
    width: calc(100% - 220px)
  }
`;


export const SongTable = styled.div`
  table {
    border: solid 2px var(--primary);
    border-radius:10px;
    overflow: hidden;
  }
  table tr:last-child td{
    border-bottom:none !important;
  }
  table tr td, table tr th{
    padding: 5px 15px;
  }
  table tr td, table tr .index-col {
    text-align:center;
  }
  table tr:not(.completed) td{
    pointer-events: none;
    opacity: .5;
  }
  table tr:not(.completed) td[colspan="8"]{
    opacity: 1;
    font-family: "medium-fonts" !important;
  }
  table tr:not(.completed) td button {
    opacity: 0;
  }
  table tr td p{
    font-size: 14px;
    display:flex;
    align-items:center;
    text-align: left;
  }
  table tr td .delete-btn{
    background-color: var(--darkerBgColor);
    padding: 8px;
    border-radius: 10px;
    width: 30px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    transition-duration: .2s;
  }
  table tr td .delete-btn:hover{
    background-color: var(--primary);
  }
  table tr td .delete-btn:hover img{
    filter: grayscale(1) invert(1);
  }
  table tr th{
    background-color: var(--darkerBgColor);
    border-bottom:none;
    padding:8px 15px
  }
  table tr td .MuiChip-filled{
    background-color : var(--darkerBgColor);
    border-radius: 10px;
    height: 25px;
  }
  table tr td .MuiRating-sizeMedium span{
    font-size:15px;
  }
  table tr th:first-child ,table tr td:first-child {
    width: 25px;
    padding: 2px;
    text-align:center;
  }
  table tr td:first-child p{
    align-items:center;
    justify-content:center;
  }
  table tr td:not(:first-child){
    border-left:solid 1px var(--primary);
  }
  table tr:not(:last-child) td{
    border-bottom:solid 1px var(--primary);
  }
  table tr:not(:last-child) td.status-col {
    width: 80px;
  }
  table tr:not(:last-child) td.status-col p {
    text-align: center;
    display: block;
    width: 100%;
  } 
  table tr td.index-col {
    width: 50px;
    padding: 0px;
  }
  table tr td.index-col > p {
    justify-content:center;
    transition-duration:.1s;
  }
  table tr:not(:last-child) td.index-col > div {
    width: 50%;
    height: 100%;
    position: relative;
  }
  table tr:not(:last-child) td.index-col > div > button.play-pause {
    position: absolute;
    top: -15px;
    left: 7px;
    transition-duration:.1s;
  }
  table tr td.action-col{
    width:55px;
  }
  table tr td.tag-col , table tr td.rating-col, table tr td.duration-col{
    width:0;
  }
  table tr td.date-col{
    min-width:115px;
    width:115px;
  }
  table tr td .btn-wrapper{
    display:flex;
    flex-direction:row;
  }
  table tr td .btn-wrapper button {
    background: #F2A892;
    border-radius: 10px;
    margin-right: 10px;
}
  table tr td .btn-wrapper button {
    border-radius: 10px;
    margin-right: 10px;
    transition:.3s all ease;
    background-color :var(--darkerBgColor);
  }
  table tr td .btn-wrapper button:hover{
    background-color :var(--primary);
  }
  table tr td .btn-wrapper button:hover img {
    filter: grayscale(1) invert(1);
  }
  table tr td.play-btn {
    text-align:center;
  }
  table tr td .play-pause{
    padding: 8px 10px 6px; 
    background-color:var(--darkerBgColor);
    box-shadow:none;
  }
  table tr td .play-pause img {
    width: 15px;
    height: 15px;
  }
  table tr th.tag-col,
  table tr td.tag-col {
    width: min-content;
    max-width: 130px;
    padding: 8px 15px;
  }
  table tr td.tag-col > div{
    display: block;
    text-align: left !important;
  }
  table tr td.tag-col > div > .MuiChip-filled {
    margin-left: 0px;
  }
  table tr td.tag-col > div > .MuiChip-filled:not(:last-child){
    margin-bottom: 5px;
  }
  table tr td.tag-col > div > .MuiChip-filled {
    margin-right: 5px;
  }
`;



export const SongModal = styled.div`
  height : auto;
  padding:0px;
  display: flex;
  flex-direction: column;
  table tr th:first-child {
      border-radius: 10px 0 0 0;
  }
  table tr th:last-child {
      border-radius: 0 10px 0 0;
  }
  // table tr td.MuiTableCell-root {
  //   border-top: 1px solid #000;
  // }
`;

export const SongModalHeader = styled.div`
  padding:10px 20px;
  background-color : var(--darkerBgColor);
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  position:relative;
  min-width: 500px;

  h5{
    margin-bottom: 0px;
    font-size:18px;
    font-family: "medium-fonts" !important;
  }

  button{
    padding: 0;
    svg {
      color: #000;
    }
  }
`;

export const SongModalBody = styled.div`
  background-color: var(--bgColor);
  padding: 20px;
  width: 100%;
  overflow: auto;
  max-height: calc(90vh - 64px);

  .bottom-buttons {
      box-shadow: 0px -20px 20px 0px var(--bgColor);
      z-index: 111;
      position: relative;
  }

  .Modal-buttons{
    width:100%;
    margin-top: 30px;
    display: flex;
    justify-content:flex-end;
  }
  .import_button_wrapper{
    width:100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content:space-between;
  }

  .form-field{
    display: grid;
    grid-template-columns:1fr 5fr;
    grid-gap: 20px;
    margin-bottom:20px;
    
    &.select-option-tags .tags-wrapper {
      border-radius: 10px;
      padding:0px;
      background-color: var(--darkerBgColor);

      .MuiChip-filled {
        border-radius: 10px;
        color: var(--primary);
        background-color: var(--bgColor);
      }

      .MuiInputBase-colorPrimary{
        padding:8px;
      }
      button {
        width: auto;
      }
      .MuiChip-deleteIcon path{
        opacity : 0;
      }
      .MuiChip-deleteIcon {
        display: inline-block;
        background-image: url(${CrossIcon});
        background-position: center center;
        background-size: 11px;
        position: relative;
        background-repeat: no-repeat;
      }
    }

    .MuiInputBase-colorPrimary{
      border-radius:10px;
      border:none !important;
      box-shadow:none !important;
      outline: none !important;
    }
    .css-113qkos-MuiSwitch-root .MuiSwitch-switchBase+.MuiSwitch-track{
      background-color: var(--darkerBgColor);
    }
    .css-113qkos-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
      background-color: var(--swichSuccess);
    }
    h6{
      font-size:14px;
      margin-bottom:0px;
      margin-top:8px;
    }
    input{
      padding: 8px 20px;
      font-size: 14px;
      border:none;
      border-radius:10px;
      background-color: var(--darkerBgColor);
    }
    #demo-simple-select {
        padding: 0 !important;
    }
  }

  .form-buttons{
    padding: 0px !important;
    margin-top:30px;
    padding:0px !important;

    button{
      margin-left:20px !important; 
      text-transform: capitalize !important;
    }
  }

  .MuiTextField-root{
    width:100%;
    margin-bottom:0px;
    border-radius:10px;
    input{ 
      background-color:var(--darkerBgColor);
      padding: 10px 20px;
      border-radius: 10px;
      font-size: 14px;
    }
  }
  .playlistinput {
    display: flex;
    width: 100%;
    margin: 0 0 20px 0;
    .success-message {
      text-align: center;
      p {
          font-size: 20px;
          line-height: 30px;
          font-family: 'regular-fonts';
          margin-top: 10px;
      }
    }
    #demo-simple-select {
        padding: 0;
    }
    .foldernamefeild {
      margin-left: 20px;
      width: auto;
      p {
        margin: 0;
      }
    }
  }
  button{
    width:auto;
    margin-left: 10px;
    font-size: 16px;
  }

`;
