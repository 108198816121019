import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { type AuthStatus } from '../../modals';

const initialState: AuthStatus = {
    isLoggedIn: false,
    currentUser: {}
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthState: (state, action: PayloadAction<any>) => {
            state.isLoggedIn = action.payload;
        },
        setCurrentUser: (state, action: PayloadAction<any>) => {
            state.currentUser = action.payload;
        },
    },
});

export const { setAuthState, setCurrentUser } = authSlice.actions;

export const isLoggedIn = (state: RootState): boolean => state.auth.isLoggedIn;
export const currentUser = (state: RootState): any => state.auth.currentUser;

export default authSlice.reducer;
